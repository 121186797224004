<template>
  <div class="form-wrapper">
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      :rules="rules"
      v-loading="loading"
    >
      <div class="flex flex-row">
        <el-form-item
          label="商城名称"
          prop="productmallBusinessId"
          class="flex-1"
        >
          {{ businessInfo.productmallBusinessName }}
        </el-form-item>
        <el-form-item
          label="产品名称"
          prop="productmallWaresName"
          class="flex-1"
        >
          <el-input
            v-model="form.productmallWaresName"
            placeholder="请输入产品名称"
            clearable
            size="small"
          />
        </el-form-item>
      </div>
      <div class="flex flex-row">
        <el-form-item label="产品分类" prop="serviceCategory" class="flex-1">
          <el-cascader
            v-model="form.serviceCategory"
            :options="cateOptions"
            :props="{
              label: 'serviceCategoryName',
              value: 'serviceCategoryId',
            }"
            placeholder="请输入产品分类"
            @change="handleCateChange"
            ref="cateCascaderRef"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="产品品牌"
          prop="productmallWaresBrand"
          class="flex-1"
        >
          <el-input
            v-model="form.productmallWaresBrand"
            placeholder="请输入产品品牌"
          />
        </el-form-item>
      </div>
      <el-form-item label="服务简述" prop="productmallWaresSubTitle">
        <el-input
          v-model="form.productmallWaresSubTitle"
          placeholder="产品服务说明"
        />
      </el-form-item>
      <el-form-item label="商品图片">
        <ImageUpload
          :limit="3"
          :imgs="form.productmallWaresImage"
          @onSuccess="productmallWaresImageSuccess"
        />
      </el-form-item>
      <el-form-item label="产品属性">
        <AttrTable :attrbutes="attrbutes" />
      </el-form-item>
      <el-form-item label="产品规格">
        <SpecsTable ref="specsTable" />
      </el-form-item>
      <div class="flex flex-row">
        <el-form-item
          label="产品库存"
          prop="productmallWaresStock"
          class="flex-1"
        >
          <el-input
            v-model="form.productmallWaresStock"
            placeholder="请输入产品库存"
          />
        </el-form-item>
        <el-form-item label="成交量：" class="flex-1">
          {{ form.checkmallWaresSaleCount || 0 }}
        </el-form-item>
      </div>
      <el-form-item label="详细介绍">
        <Editor v-model="form.productmallWaresAbstracts" />
      </el-form-item>
      <div class="flex flex-row">
        <el-form-item label="是否包邮" class="flex-1">
          <el-radio-group v-model="form.isFreeMail">
            <el-radio
              v-for="dict in isFreeMailOptions"
              :key="dict.dictValue"
              :label="dict.dictValue"
              >{{ dict.dictLabel }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="商品推荐权重"
          prop="platformWeightSequence"
          class="form-weight flex-1"
        >
          <el-select
            v-model="form.platformWeightSequence"
            placeholder="列表商品推荐权重"
          >
            <el-option
              v-for="dict in platformWeightSequenceOptions"
              :key="dict.dictValue"
              :label="dict.dictLabel"
              :value="parseInt(dict.dictValue)"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="">
        <el-button
          type="primary"
          plain
          size="small"
          @click="saveBtnClick"
          v-if="!isView"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import ImageUpload from "@/components/ImgUpload/index";
import Editor from "@/components/Editor/index";
import { GOODS_WEIGTH_STATUS, FREE_MAIL } from "@/constant/dict";
import {
  getGoodCate,
  getAttribute,
  addGoodInfo,
  updateGoodInfo,
  goodsDetail,
} from "@/api/userCenter";
import AttrTable from "./AttrTable.vue";
import SpecsTable from "./SpecsTable.vue";
export default {
  components: {
    AttrTable,
    SpecsTable,
    ImageUpload,
    Editor,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    curId: {
      type: Number,
      default: null,
    },
    businessInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      form: {
        productmallBusinessName: "",
        productmallWaresName: "",
        serviceCategory: [],
        productmallWaresBrand: "",
        productmallWaresSubTitle: "",
        productmallWaresImage: "",
        productmallWaresStock: "",
        checkmallWaresSaleCount: "",
        productmallWaresAbstracts: "",
        platformWeightSequence: null,
        isFreeMail: null,
      },
      platformWeightSequenceOptions: [],
      isFreeMailOptions: [],
      cateOptions: [],
      attrbutes: [], // 产品属性
      isView: false,
      rules: {
        productmallWaresName: [
          {
            required: true,
            message: "请输入产品名称",
            trigger: "blur",
          },
        ],
        serviceCategory: [
          {
            required: true,
            message: "请选择产品分类",
            trigger: ["blur", "change"],
          },
        ],
        productmallWaresSubTitle: [
          {
            required: true,
            message: "请输入服务简述",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.getDicts();
    this.getCate();
    this.isView = this.curId && !this.isEdit; // 判断是否是查看，需隐藏保存按钮
    if (this.curId) {
      this.getDetail();
    }
  },
  methods: {
    // 商品详情
    async getDetail() {
      this.loading = true;
      const data = await this.$axios
        .get(goodsDetail, {
          productmallWaresId: this.curId,
        })
        .finally(() => {
          this.loading = false;
        });
      const {
        serviceCategoryFirstId,
        serviceCategorySecondId,
        serviceCategoryThridId,
        waresAttributeValues,
        waresRanks,
        ...rest
      } = data;
      Object.assign(this.form, {
        ...rest,
        serviceCategory: [
          serviceCategoryFirstId,
          serviceCategorySecondId,
          serviceCategoryThridId,
        ],
      });
      // 设置属性和规格
      this.attrbutes = waresAttributeValues;
      this.$refs.specsTable.waresRanks = waresRanks;
    },
    async getCate() {
      const cate = await this.$axios.post(getGoodCate, {});
      this.cateOptions = cate;
    },
    async handleCateChange(val) {
      if (val && val.length) {
        const res = await this.$axios.get(getAttribute, {
          serviceCategoryId: val[val.length - 1],
        });
        this.attrbutes = res || [];
      }
    },
    productmallWaresImageSuccess(fileUrls) {
      Object.assign(this.form, { productmallWaresImage: fileUrls });
    },
    async getDicts() {
      this.$store.dispatch("getDictData", GOODS_WEIGTH_STATUS).then((res) => {
        this.platformWeightSequenceOptions = res;
      });
      this.$store.dispatch("getDictData", FREE_MAIL).then((res) => {
        this.isFreeMailOptions = res;
      });
    },
    // 保存按钮
    async saveBtnClick() {
      await this.$refs.form.validate();
      const { serviceCategory, ...rest } = this.form;
      const params = {
        ...rest,
      };
      // 产品分类级联数据
      const [
        serviceCategoryFirstName,
        serviceCategorySecondName,
        serviceCategoryThridName,
      ] = this.$refs.cateCascaderRef.getCheckedNodes()[0].pathLabels;
      // 规格数据
      const waresRanks = this.$refs.specsTable.waresRanks;
      Object.assign(params, {
        serviceCategoryFirstId: serviceCategory[0],
        serviceCategorySecondId: serviceCategory[1],
        serviceCategoryThridId: serviceCategory[2],
        serviceCategoryFirstName,
        serviceCategorySecondName,
        serviceCategoryThridName,
        waresAttributeValues: this.attrbutes,
        waresRanks,
      });
      await this.$axios.post(
        this.isEdit && this.curId ? updateGoodInfo : addGoodInfo,
        params
      );
      this.$message.success("保存成功");
      this.$emit("input", false);
      this.$emit("saveSuccess");
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  &-wrapper {
    padding: 10px;
  }
  &-weight {
    width: 300px;
    margin-left: 20px;
  }
}
</style>
