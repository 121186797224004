<template>
  <div class="mall">
    <QueryForm :orderStatus="orderStatus" @queryBtnClick="queryBtnClick" />
    <div v-loading="tableLoading" class="mall-itemWrapper">
      <div v-for="(item, index) in lists" :key="index" class="mall-item">
        <div class="flex">
          <div class="mall-left flex-1">
            <div class="mall-title">
              <span class="mall-time">{{ item.createTime }}</span>
              <label>订单编号：</label>
              <span>{{ item.productmallOrderCode }}</span>
            </div>
            <div class="mall-good">
              <Goods
                :goods="item.orderRanks"
                :labName="item.productmallBusinessName"
                :orderStatus="item.productmallOrderStatus"
                :totalMoney="item.orderPrice"
                @evalute="evaluteBtnClick"
              />
            </div>
          </div>
          <div class="mall-center">
            <div class="mall-title">订单状态</div>
            <div class="mall-step">
              <OrderFlow
                :active="+item.productmallOrderStatus"
                :orderStatusMap="orderStatusMap"
              />
            </div>
          </div>
          <div class="mall-right">
            <div class="mall-title">操作</div>
            <div class="mall-btns">
              <div>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  @click="orderView(item)"
                  >查看订单</el-button
                >
              </div>
              <template v-if="+item.productmallOrderStatus == 1">
                <div>
                  <el-button size="mini" type="danger" plain @click="toPay"
                    >去付款</el-button
                  >
                </div>
                <div>
                  <el-button
                    size="mini"
                    type="danger"
                    plain
                    @click="cancelOrder(item)"
                    >取消订单</el-button
                  >
                </div>
              </template>

              <template v-else-if="+item.productmallOrderStatus >= 4">
                <el-button
                  size="mini"
                  type="success"
                  plain
                  @click="logisticsViewBtnClick(item)"
                  >查看物流</el-button
                >
              </template>
            </div>
          </div>
        </div>
        <GoodOrderLeaveMessage :data="item.orderLeavingMessage" />
      </div>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="订单详情"
      :visible.sync="showDrawer"
      size="800px"
      class="drawer"
    >
      <OrderDetail
        v-if="showDrawer"
        :orderId="currentData.productmallOrderId"
      />
    </el-drawer>
    <!--去评价弹窗-->
    <EvaluateDialog
      v-model="evaluateVisible"
      v-if="evaluateVisible"
      :data="currentRowData"
      mallOrderType="1"
    />
    <!-- 物流弹窗-->
    <ViewLogisticsDialog
      v-model="viewLogisticsVisible"
      v-if="viewLogisticsVisible"
      :order="currentRowData"
    />
  </div>
</template>
<script>
import { GOODS_ORDER_STATUS } from "@/constant/dict";
import OrderDetail from "@/components/OrderDetail/index";
import GoodOrderLeaveMessage from "@/components/OrderDetail/GoodOrderLeaveMessage";
import Goods from "@/components/OrderDetail/Goods";
import OrderFlow from "@/components/OrderFlow/Order";
import EvaluateDialog from "@/components/EvaluateDialog/index";
import QueryForm from "./components/QueryForm";
import { userGoodOrderList, userCancelOrder } from "@/api/userCenter";
import Pagination from "@/components/Pagination/index";
import ViewLogisticsDialog from "@/components/Logistics/ViewLogistics";

export default {
  components: {
    QueryForm,
    OrderDetail,
    OrderFlow,
    Goods,
    Pagination,
    GoodOrderLeaveMessage,
    EvaluateDialog,
    ViewLogisticsDialog,
  },
  data() {
    return {
      showDrawer: false,
      lists: [],
      orderStatus: [],
      orderStatusMap: {},
      tableLoading: false,
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 4,
      },
      queryForm: {},
      currentRowData: {},
      evaluateVisible: false, // 评价弹窗的显示和隐藏
      viewLogisticsVisible: false, //  物流弹窗的显示和隐藏
    };
  },
  async created() {
    await this.getDcits();
    this.getOrderList();
  },
  methods: {
    // 去评价
    evaluteBtnClick(item) {
      this.currentRowData = item;
      this.evaluateVisible = true;
    },
    // 点击查看物流信息接口
    logisticsViewBtnClick(item) {
      this.currentRowData = item;
      this.viewLogisticsVisible = true;
    },
    // 分页查询
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getOrderList();
    },
    // 订单列表
    async getOrderList() {
      this.tableLoading = true;
      const { time, ...rest } = this.queryForm;
      const params = {
        ...this.page,
        ...rest,
      };
      if (time && time.length) {
        Object.assign(params, {
          queryStartTime: time[0],
          queryEndTime: time[1],
        });
      }
      const { list, totalItem } = await this.$axios
        .post(userGoodOrderList, params)
        .finally(() => {
          this.tableLoading = false;
        });
      this.lists = list;
      this.totalItem = totalItem;
    },
    // 获取数据字典数据
    async getDcits() {
      this.tableLoading = true;
      const data = await this.$store.dispatch(
        "getDictData",
        GOODS_ORDER_STATUS
      );
      const statusMap = {};
      data.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });
      this.orderStatusMap = statusMap;
      this.orderStatus = data;
      // console.log(statusMap);
    },
    orderView(item) {
      this.currentData = item;
      this.showDrawer = true;
    },
    // 查询按钮的点击
    queryBtnClick(params) {
      this.queryForm = params;
      this.page.pageIndex = 1;
      this.getOrderList();
    },
    // 取消订单
    async cancelOrder(item) {
      await this.$confirm("确定取消订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      this.$axios.post(userCancelOrder, {
        productmallOrderId: item.productmallOrderId,
      });
      this.$message.success("取消成功");
      // 刷新数据
      this.page.pageIndex = 1;
      this.getOrderList();
    },
    toPay() {
      this.$router.push({ name: "orderPay" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.drawer {
  /deep/ .el-drawer__header {
    margin-bottom: 20px;
  }
  /deep/ .el-drawer__header span {
    font-weight: bold;
    font-size: 1.07rem;
  }
}
.mall {
  padding: 10px;
  &-itemWrapper {
    min-height: 450px;
  }
  &-item {
    border: 1px solid $border-color;
    margin-bottom: 10px;
  }
  &-title {
    height: 40px;
    line-height: 40px;
    background: $head-bar-bg;
    padding-left: 10px;
    border-bottom: 1px solid $border-color;
  }
  &-time {
    font-weight: bold;
    margin-right: 15px;
  }
  &-left {
    border-right: 1px solid $border-color;
  }
  &-center {
    border-right: 1px solid $border-color;
    width: 320px;
  }
  &-step {
    padding: 15px;
  }
  &-right {
    width: 120px;
  }
  &-btns {
    padding-top: 10px;
    text-align: center;
    /deep/ .el-button {
      width: 100px;
    }
    > div {
      margin-bottom: 10px;
    }
  }
  &-totalPrice {
    border-left: 1px solid $border-color;
    width: 100px;
    color: #f56c6c;
  }
}
.good {
  border-bottom: 1px solid $border-color;
  padding: 10px;
  &-cover {
    width: 100px;
    height: 100px;
    // border: 1px solid $border-color;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-detail {
    > div {
      padding-left: 10px;
      margin-bottom: 10px;
      > label {
        color: #909399;
      }
    }
  }
  &-spec {
    margin-right: 15px;
  }
  &-price {
    color: #f56c6c;
  }
}
</style>
