<template>
  <div class="msg">
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="@我" name="my"></el-tab-pane>
      <el-tab-pane label="系统通知" name="sys"></el-tab-pane>
    </el-tabs>
    <el-table :data="tableData" border class="msg-data" height="550">
      <el-table-column prop="noticeContent" label="消息内容"> </el-table-column>
      <el-table-column label="阅读状态" width="80px">
        <template slot-scope="{ row }">
          <el-tag
            :type="row.noticeReceiverInfo.isRead === '0' ? 'danger' : 'info'"
            size="small"
          >
            {{ readStatusMap[+row.noticeReceiverInfo.isRead] }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="90px">
        <template slot-scope="{ row, $index }">
          <el-button
            type="text"
            size="small"
            @click="readClick(row, $index)"
            v-if="row.noticeReceiverInfo.isRead === '0'"
            >标记为已读</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
  </div>
</template>
<script>
import { myMessages, setMsgRead } from "@/api/userCenter";
import { NOTICE_STATUS } from "@/constant/dict";
import Pagination from "@/components/Pagination/index";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      activeName: "my",
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      totalItem: 0,
      readStatusMap: {},
    };
  },
  created() {
    this.getDict();
    this.getList();
  },
  methods: {
    // 获取数据字典数据
    async getDict() {
      const data = await this.$store.dispatch("getDictData", NOTICE_STATUS);
      const map = {};
      data.forEach((item) => {
        map[item.dictValue] = item.dictLabel;
      });
      this.readStatusMap = map;
    },
    // tab点击
    handleTabClick() {
      this.page.pageIndex = 1;
      this.tableData.length = 0;
      this.totalItem = 0;
      this.getList();
    },
    // 获取列表数据
    async getList() {
      // 通知类型(数据字典rare_notice_type)(0-用户通知 1-系统通知),必填
      const typeMap = {
        my: 0,
        sys: 1,
      };
      const params = {
        ...this.page,
        noticeType: typeMap[this.activeName],
      };

      const { list, totalItem } = await this.$axios.post(myMessages, params);
      this.totalItem = totalItem;
      this.tableData = list;
    },
    // 标记为已读
    async readClick(data, index) {
      await this.$axios.get(setMsgRead, { noticeId: data.noticeId });
      this.tableData[index].noticeReceiverInfo.isRead = "1";
      // 更新顶部显示的未读消息条数
      this.$store.dispatch("GetUserMsgUnreadCount");
    },
    // 分页处理
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.msg {
  padding: 0 15px;
  &-unRead {
    color: #f56c6c;
  }
  &-data {
    margin-bottom: 20px;
  }
}
</style>
