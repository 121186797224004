<template>
  <div class="search flex flex-right">
    <el-input
      :placeholder="placeholder"
      v-model.trim="searchValue"
      @keypress.native.enter="search"
      class="search-input"
      clearable
    >
      <el-button
        slot="append"
        icon="el-icon-search"
        class="search-btn"
        @click="search"
      ></el-button>
    </el-input>
    <div class="search-result" v-if="showNum">
      共
      <span>{{ num }}</span>
      个结果
    </div>
  </div>
</template>
<script>
export default {
  name: "searchBar",
  props: {
    num: {
      type: Number,
      default: 0,
    },
    value: {
      type: String,
      default: "",
    },
    showNum: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "在结果中搜索...",
    },
  },
  computed: {
    // 读取和设置
    searchValue: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    search() {
      this.$emit("btnClick");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.search {
  &-input {
    width: 400px;
    margin-right: 15px;
  }
  &-result {
    line-height: 36px;
    > span {
      color: $price-color;
      font-weight: bold;
    }
  }
  &-btn:hover {
    /deep/ i {
      color: $theme-color;
      font-weight: bold;
    }
  }
}
</style>
