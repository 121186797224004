<template>
  <div class="print">
    <div class="print-con">
      <div class="head flex flex-row flex-between">
        <div>RKLH/JLC-16-003</div>
        <div>
          <span>委托协议号</span>
          <span></span>
        </div>
      </div>
      <div class="title flex flex-row">
        <div class="title-img">
          <img src="@/assets/mall/deedLogo.png" />
        </div>
        <div class="title-text">
          包 头 稀 土 研 究 院 理 化 检 测 中 心<br />
          分 析 委 托 协 议
        </div>
      </div>
      <Customer :data="userAddress" />
      <Sample
        :data="deedPrint.rareCheckmallOrderSamplesEx || []"
        :status="sampleStatus"
      />
      <Service :hasBasis="hasBasis" :price="deedPrint.orderPrice" />
      <Protocol />
      <div class="tip">
        <span>第一联：中心留存</span>
        <span>第二联：财务留存</span>
        <span>第三联：客户留存</span>
      </div>
    </div>
  </div>
</template>
<script>
import Customer from "./components/Customer";
import Sample from "./components/Sample";
import Service from "./components/Service";
import Protocol from "./components/Protocol";
import { addressDetail } from "@/api/userCenter";

export default {
  components: {
    Customer,
    Sample,
    Service,
    Protocol,
  },
  data() {
    return {
      deedPrint: {},
      userAddress: {},
      sampleStatus: "", // 组装的样品状态信息
      hasBasis: false,
    };
  },
  async created() {
    const { deedPrint } = this.$store.getters;
    if (deedPrint) {
      this.deedPrint = JSON.parse(deedPrint);
      this.getSampleStatus(); // 组装样品状态信息
      this.computedBasis(); // 计算出检测依据
      await this.getAddress(); // 获取客户信息
      window.print();
    }
  },
  methods: {
    // 获取客户信息
    async getAddress() {
      const { userAddressId } = this.deedPrint;
      const data = await this.$axios.get(addressDetail, {
        userAddressId,
      });
      this.userAddress = data;
    },
    // 组装样品状态信息
    getSampleStatus() {
      const { rareCheckmallOrderSamplesEx } = this.deedPrint;
      const chengfeng = rareCheckmallOrderSamplesEx.map(
        (item) => item.sampleBasis
      );
      this.sampleStatus = (chengfeng || []).join(",");
    },
    computedBasis() {
      const { rareCheckmallOrderSamplesEx } = this.deedPrint;
      console.log(rareCheckmallOrderSamplesEx);
      const hasBasis = rareCheckmallOrderSamplesEx.find(
        (item) => item.checkBasicTechnology
      );
      this.hasBasis = !!hasBasis;
    },
  },
};
</script>
<style lang="scss" scoped>
.print {
  padding: 30px 0;
  &-con {
    margin: 0 auto;
    width: 694px;
  }
}
.head {
  margin-bottom: 15px;
  padding-left: 8px;
}
.title {
  padding-left: 2px;
  &-img {
    padding-top: 11px;
    img {
      width: 158px;
      height: 58px;
    }
  }
  &-text {
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 4px;
    padding-left: 10px;
    text-align: center;
    line-height: 35px;
  }
}
.tip {
  padding-left: 30%;
  span {
    margin-left: 20px;
  }
}
</style>
