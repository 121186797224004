<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="regis flex flex-center">
      <div class="regis-inner">
        <el-card shadow="hover" class="regis-card">
          <div class="regis-title">
            <label>
              <span>{{ demandId ? "修改" : "发布" }}检测需求</span>
              <em></em>
            </label>
          </div>
          <DetailForm />
        </el-card>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import DetailForm from "@/components/RequirementDetail/index";

export default {
  components: { DetailForm },
  data() {
    return {
      demandId: null,
    };
  },

  created() {
    this.demandId = this.$route.query.id;
  },
  methods: {
    handlePreview() {},
    handleRemove() {},
    handleExceed() {},
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$width: 800px;
.regis {
  &-inner {
    width: $width;
    max-width: $width;
    padding-top: 30px;
    min-height: 500px;
  }
  &-title {
    margin-bottom: 20px;
    text-align: center;
    > label {
      position: relative;
      span {
        position: relative;
        font-size: 1.57rem;
        font-weight: 500;
        z-index: 10;
      }
      em {
        width: 100%;
        height: 12px;
        background: #b7d6ff;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
      }
    }
  }
}
</style>
