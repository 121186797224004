<template>
  <div class="map">
    <div class="map-inner">
      <BlockHeader title="数据展示" />
      <div class="flex flex-row">
        <div class="map-ability">
          <CheckAbility />
        </div>
        <div class="flex-1 flex flex-column flex-between">
          <div class="tip flex flex-row">
            <div class="flex flex-right tip-left">
              <div>
                <div class="tip-title">已经发布设备</div>
                <div class="tip-nums flex flex-row">
                  <div
                    class="tip-num"
                    v-for="(item, index) in (publishedDevices + '').split('')"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                  <div class="tip-unit flex flex-column flex-right">个</div>
                </div>
              </div>
            </div>
            <div class="tip-right">
              <div class="tip-title">已上线能力</div>
              <div class="tip-nums flex flex-row">
                <div
                  class="tip-num"
                  v-for="(item, index) in (onlineAbility + '').split('')"
                  :key="index"
                >
                  {{ item }}
                </div>
                <div class="tip-unit flex flex-column flex-right">个</div>
              </div>
            </div>
          </div>
          <div class="map-chart" id="mapChart"></div>
        </div>
        <div><DeviceRank /></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import "echarts/map/js/china";
import Regions from "./regions";
import CheckAbility from "./components/CheckAbility";
import DeviceRank from "./components/DeviceRank.vue";
import { PROVICES, PROVICESTEXT } from "./config";
import { mapData, deviceAndAbilityCount } from "@/api/home";
import BlockHeader from "../BlockHeader.vue";
export default {
  name: "modular_data_show",
  components: {
    CheckAbility,
    DeviceRank,
    BlockHeader,
  },
  data() {
    return {
      chart: null,
      regions: Regions,
      publishedDevices: 0, // 已发布设备
      onlineAbility: 0, // 已上线能力
      provinces: PROVICES,
      provincesText: PROVICESTEXT,
    };
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  mounted() {
    this.initChart();
    this.getDeviceAbilityCount();
  },
  methods: {
    async getDeviceAbilityCount() {
      const res = await this.$axios.get(deviceAndAbilityCount);
      const { labAbilityCount, labDeviceCount } = res;
      this.onlineAbility = labAbilityCount;
      this.publishedDevices = labDeviceCount;
    },
    async initChart() {
      this.chart = echarts.init(document.getElementById("mapChart"));
      const car_data = await this.$axios.get(mapData);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            const { data, name } = params;
            return `${name}<br/>设备数：${data.value[2]}`;
          },
        },
        geo: {
          map: "china",
          show: true,
          roam: true, //拖拽和伸缩
          zoom: 1.24,
          label: {
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            emphasis: {
              //鼠标放上去区域背景色
              areaColor: "#034DE4",
              opacity: 0.7,
            },
            normal: {
              borderWidth: 1, //边际线大小
              borderColor: "#99ccff", //边界线颜色
              areaColor: "#369EF8", //默认区域颜色
            },
          },
          // regions: this.regions, // 给每个省份的区域加上背景色
        },
        series: {
          type: "effectScatter",
          coordinateSystem: "geo",
          data: this.convertData(car_data),
          symbolSize: function (val) {
            //设置散点大小
            return val[2] / (val[2] / 10);
          },
          showEffectOn: "render", //配置何时显示特效。可选：'render' 绘制完成后显示特效
          rippleEffect: {
            //涟漪特效相关配置
            brushType: "stroke", //波纹的绘制方式，可选 'stroke' 和 'fill'
          },
          hoverAnimation: true, //是否开启鼠标 hover 的提示动画效果
          label: {
            //图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等，
            normal: {
              formatter: "{b}",
              position: "right",
              show: false,
            },
          },
          itemStyle: {
            //图形样式，normal 是图形在默认状态下的样式；emphasis 是图形在高亮状态下的样式，比如在鼠标悬浮或者图例联动高亮时
            normal: {
              color: "#FEFA05", //散点的颜色
              shadowBlur: 10,
              shadowColor: 20,
              fontSize: "12px",
            },
          },
          zlevel: 1,
        },
      };
      this.chart.setOption(option);
      this.chart.on("click", function (param) {
        //遍历取到provincesText 中的下标  去拿到对应的省js
        const provincesText = this.provincesText;
        const provinces = this.provinces;
        for (var i = 0; i < provincesText.length; i++) {
          if (param.name == provincesText[i]) {
            //显示对应省份的方法
            this.showProvince(provinces[i], provincesText[i]);

            break;
          }
        }
      });
    },
    // 数据处理
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        const { labLatitude, labLongitude, regionName, deviceCount } = data[i];
        res.push({
          name: regionName,
          value: [labLongitude, labLatitude, deviceCount],
        });
      }
      return res;
    },
    showProvince() {},
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "../../common.scss";
.map {
  &-inner {
    width: $max-width;
    margin: 0 auto;
    padding-top: 32px;
    padding-bottom: 20px;
  }
  &-ability {
    width: 350px;
  }
  &-chart {
    height: 400px;
  }
}
.tip {
  margin-bottom: 10px;
  &-title {
    color: #4483ff;
    font-size: 1.14rem;
    margin-bottom: 20px;
  }
  &-left {
    margin-right: 80px;
    padding-left: 110px;
  }
  &-num {
    width: 27px;
    height: 38px;
    line-height: 38px;
    background: white;
    font-size: 1.42rem;
    text-align: center;
    font-weight: bold;
    color: #525d9e;
    margin-right: 5px;
  }
  &-unit {
    font-size: 0.85rem;
    color: #040404;
    font-weight: bold;
  }
}
</style>
