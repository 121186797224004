// 专家列表
export const expertList = `/api/unoauth/expert/selectPlatformExpertList`; // 专家列表
export const expertDetail = `/api/unoauth/expert/getExpertDetailForPlatform`; // 专家详情
export const expertCate = `/api/unoauth/expert/selectExpertFieldCategoryList`; // 专家领域分类
export const expertDict = `/api/unoauth/expert/getExpertAboutDictDataList`; // 数据字典

// 专家入驻
export const expertApplyInfo = `/api/oauth/expert/getExpertDetailByPlatformCenter`; // 专家申请信息
export const applyExpert = `/api/oauth/expert/addOrEditExpertInstallApply`; // 申请入驻为专家

// 专家评估
export const submitEstimateInfo = `/api/oauth/expert/review/addApplyExpertReviewByUser`; // 提交申请评估信息
export const myEstList = `/api/oauth/expert/review/getExpertReviewList`; // 用户中心-我的评审列表
export const getEstimateDetail = `/api/oauth/expert/review/getExpertReviewDetialByAppyId`; // 评审详情
export const insertEstimateRecord = `/api/oauth/expert/review/insertExpertReviewRecord`; // 输入评审记录内容
export const closeEstimate = `/api/oauth/expert/review/operaExpertReviewApply`; // 关闭专家评审
