<template>
  <div class="dis">
    <div class="dis-inner">
      <div class="dis-ad"></div>
      <div class="goods flex flex-row flex-wrap" v-loading="listLoading">
        <div
          class="goods-item pointer"
          v-for="(item, index) in discountGoods"
          :key="index"
          @click="toGoodDetail(item)"
        >
          <div class="goods-cover">
            <img
              :src="item.productmallWaresImage"
              v-if="item.productmallWaresImage"
            />
            <img v-else :src="$oss('common/default_device.png')" />
          </div>
          <div class="goods-name">{{ item.productmallWaresName }}</div>
          <div class="goods-priceWrapper">
            <!-- <span class="goods-disPrice">￥{{ item.preferentialPrice }}</span> -->
            <span class="goods-disPrice" v-if="!item.discountedPrice"
              >面议</span
            >
            <span class="goods-disPrice" v-else
              >￥{{ item.discountedPrice }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDiscountGoods } from "@/api/mall";
export default {
  data() {
    return {
      discountGoods: [],
      page: {
        pageIndex: 1,
        pageSize: 20,
      },
      listLoading: false,
    };
  },
  created() {
    this.getGoods();
  },
  methods: {
    toGoodDetail(data) {
      this.$jump.push({
        name: "goodsDetail",
        query: { id: data.productmallWaresId },
      });
    },
    // 列表查询
    async getGoods() {
      const params = {
        ...this.page,
      };
      this.listLoading = true;
      const { list } = await this.$axios
        .post(getDiscountGoods, params)
        .finally(() => {
          this.listLoading = false;
        });
      this.discountGoods = list || [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.dis {
  width: 100%;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    margin-bottom: 61px;
  }
  &-ad {
    height: 173px;
    width: 100%;
    background: url("../../../assets/mall/mallHome/discountBg.png");
    background-size: 100% 100%;
  }
}
.goods {
  background: white;
  min-height: 300px;
  &-item {
    width: 305px;
    text-align: center;
    padding-top: 31px;
    border-right: 1px solid #e3e9f2;
    border-bottom: 1px solid #e3e9f2;
  }
  &-cover {
    margin-bottom: 20px;
    text-align: center;
    img {
      width: 225px;
      height: 153px;
      object-fit: cover;
    }
  }
  &-name {
    font-size: 16px;
    color: #202020;
    margin-bottom: 14px;
  }
  &-priceWrapper {
    margin-bottom: 30px;
  }
  &-disPrice {
    font-size: 16px;
    font-weight: bold;
    color: #e67009;
    margin-right: 10px;
  }
  &-originPrice {
    text-decoration: line-through;
    font-size: 15px;
    color: #9e9e9e;
  }
}
</style>
