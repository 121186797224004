<template>
  <div class="demand">
    <QueryForm
      @query="queryBtnClick"
      :statusOption="statusOption"
      :load="loading"
    />
    <div class="demand-block" v-loading="loading">
      <DemandItem
        v-for="(item, index) in list"
        :key="index"
        :data="item"
        @showDetail="showDetail"
        :statusMap="statusMap"
      />
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="需求详情"
      :visible.sync="showDrawer"
      :wrapperClosable="false"
      size="800px"
      class="drawer"
    >
      <div class="drawer-con">
        <DemandDetail
          :demandId="selectDemandId"
          v-if="showDrawer"
          @updateSuccess="updateSuccess"
        />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { DEMAND } from "@/constant/dict";
import { userDemand } from "@/api/userCenter";
import QueryForm from "./components/QueryForm";
import DemandItem from "./components/DemandItem";
import DemandDetail from "@/components/RequirementDetail/index";
import Pagination from "@/components/Pagination/index";

export default {
  components: {
    QueryForm,
    DemandItem,
    Pagination,
    DemandDetail,
  },
  data() {
    return {
      totalItem: 0,
      loading: true,
      showDrawer: false,
      page: {
        pageIndex: 1,
        pageSize: 4,
      },
      list: [],
      selectDemandId: null,
      formParams: {},
      // 0-已关闭；1-已发布；2-审核中；3-审核不通过
      statusOption: [], // 查询条件-需求状态下拉框数据
      statusMap: {}, // 列表需求状态映射名称
    };
  },
  created() {
    this.getStatus();
    this.getList();
  },
  methods: {
    async getStatus() {
      const data = await this.$store.dispatch("getDictData", DEMAND);
      this.statusOption = data;
      const statusMap = {};
      data.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });

      this.statusMap = statusMap;
    },
    // 分页请求
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    // 查询按钮点击
    queryBtnClick(params) {
      this.formParams = params;
      this.getList();
    },
    async getList() {
      this.loading = true;
      const params = {
        ...this.page,
        ...this.formParams,
      };
      const { list, totalItem } = await this.$axios
        .post(userDemand, params)
        .finally(() => {
          this.loading = false;
        });
      this.list = list;
      this.totalItem = totalItem;
    },
    showDetail(id) {
      this.selectDemandId = id;
      this.showDrawer = true;
    },
    // 修改需求完毕
    updateSuccess() {
      // 更新列表数据
      this.showDrawer = false;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.demand {
  padding: 10px;
  &-block {
    min-height: 450px;
  }
}
.drawer {
  &-con {
    padding: 0 20px 20px 15px;
  }
}
</style>
