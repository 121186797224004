<template>
  <div class="base">
    <div class="base-title">课程详情</div>
    <EditorView :data="data.introduce" />
  </div>
</template>
<script>
export default {
  name: "detailBase",
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({
        introduce: "",
      }),
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.base {
  &-title {
    font-size: 22px;
    margin-bottom: 28px;
  }
}
</style>
