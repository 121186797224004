<template>
  <div class="abi flex flex-row">
    <div class="abi-cover">
      <img :src="data.abilityImage" />
    </div>
    <div class="flex-1 flex flex-column flex-between">
      <div>
        <div class="abi-name">{{ data.abilityName }}</div>
        <div class="abi-list flex flex-row">
          <div class="abi-label">检测分类</div>
          <div class="flex-1">
            <span>{{ data.laboratoryAbilityTypeNameParents }}</span>
            <span>/</span>
            <span>{{ data.laboratoryAbilityTypeName }}</span>
          </div>
        </div>
      </div>
      <div class="abi-btns">
        <div class="abi-pubBtn" @click="publishDemand">发布需求</div>
        <div class="abi-telBtn">联系客服</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    publishDemand() {
      if (this.token) {
        this.$jump.push({ name: "releaseReq" });
      } else {
        this.$store.dispatch("login");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.abi {
  border-bottom: 1px solid #efefef;
  padding-bottom: 35px;
  margin-bottom: 26px;
  &-cover {
    width: 385px;
    height: 235px;
    margin-right: 25px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-name {
    font-size: 18px;
    font-weight: bold;
    color: #202224;
    margin-bottom: 10px;
  }
  &-list {
    // font-size: 12px;
    margin-bottom: 5px;
    color: #202224;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-label {
    color: #919191;
    margin-right: 15px;
    width: 60px;
  }
  &-btns {
    padding-top: 20px;
    > div {
      display: inline-block;
      padding: 10px 30px;
      border-radius: 2px;
      color: white;
    }
  }
  &-pubBtn {
    background: #f79b18;
    margin-right: 15px;
  }
  &-telBtn {
    background: #4482ff;
  }
}
</style>
