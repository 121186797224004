<template>
  <el-tabs
    v-model="tabsActiveName"
    @tab-click="firstClick"
    class="tabs"
    v-loading="menuLoading"
  >
    <el-tab-pane
      :label="item.serviceCategoryName"
      :name="`${item.serviceCategoryId}`"
      v-for="(item, index) in menus"
      :key="index"
    >
    </el-tab-pane>
    <div class="cate flex flex-row" v-if="menus.length">
      <div class="cate-label">类别</div>
      <div class="cate-con flex-1">
        <div class="cate-pCon">
          <span
            v-for="(cate, fIndex) in menus[activeFirstMenuIndex].children"
            :key="cate.serviceCategoryId"
            class="cate-pType pointer"
            :class="{
              active: cate.serviceCategoryId === activeSecondMenuId,
            }"
            @click="secondClick(cate, fIndex)"
            >{{ cate.serviceCategoryName }}</span
          >
        </div>
        <div class="cate-cCon">
          <span
            v-for="(cate, sIndex) in menus[activeFirstMenuIndex].children[
              activeSecondMenuIndex
            ].children"
            :key="cate.serviceCategoryId"
            class="cate-pType pointer"
            :class="{
              active: cate.serviceCategoryId === activeThridMenuId,
            }"
            @click="thirdClick(cate, sIndex)"
            >{{ cate.serviceCategoryName }}</span
          >
        </div>
      </div>
    </div>
  </el-tabs>
</template>
<script>
import { getGoodCate } from "@/api/userCenter";
export default {
  data() {
    return {
      menuLoading: false,
      menus: [],
      activeFirstMenuId: null,
      activeSecondMenuId: null,
      activeThridMenuId: null,
      tabsActiveName: null,
      activeFirstMenuIndex: 0,
      activeSecondMenuIndex: 0,
      activeThirdMenuIndex: 0,
    };
  },
  async created() {
    await this.getCate();
    this.setDefaultData();
    this.toSearch();
  },
  methods: {
    async getCate() {
      this.menuLoading = true;
      const cate = await this.$axios.post(getGoodCate, {}).finally(() => {
        this.menuLoading = false;
      });
      this.menus = cate;
    },
    setDefaultData() {
      const id = this.menus[0].serviceCategoryId;
      this.tabsActiveName = `${id}`;
      this.activeFirstMenuId = id;
      this.activeFirstMenuIndex = 0;
      const { firstId, secondId, thirdId } = this.$route.query;
      if (firstId) {
        this.tabsActiveName = `${firstId}`;
        this.activeFirstMenuId = +firstId;
        const index = this.menus.findIndex(
          (item) => item.serviceCategoryId === this.activeFirstMenuId
        );
        this.activeFirstMenuIndex = index;
      }
      if (secondId) {
        this.activeSecondMenuId = +secondId;
        const index = this.menus[this.activeFirstMenuIndex].children.findIndex(
          (item) => item.serviceCategoryId === this.activeSecondMenuId
        );
        this.activeSecondMenuIndex = index;
      }
      if (thirdId) {
        this.activeThridMenuId = +thirdId;
        const secondMenu = this.menus[this.activeFirstMenuIndex].children;
        const index = secondMenu[this.activeSecondMenuIndex].children.findIndex(
          (item) => item.serviceCategoryId === this.activeThridMenuId
        );
        this.activeThirdMenuIndex = index;
      }
    },
    secondClick({ serviceCategoryId }, index) {
      this.activeSecondMenuIndex = index;
      this.activeSecondMenuId = serviceCategoryId;
      //清空3级类目
      this.activeThirdMenuIndex = 0;
      this.activeThridMenuId = null;
      this.toSearch();
    },
    firstClick({ index }) {
      this.activeFirstMenuIndex = +index;
      this.activeFirstMenuId = +this.tabsActiveName;
      // 清空2.3级
      this.activeSecondMenuIndex = 0;
      this.activeSecondMenuId = null;
      this.activeThirdMenuIndex = 0;
      this.activeThridMenuId = null;
      this.toSearch();
    },
    thirdClick({ serviceCategoryId }, index) {
      this.activeThirdMenuIndex = index;
      this.activeThridMenuId = serviceCategoryId;
      this.toSearch();
    },
    toSearch() {
      this.$emit("toSearch", {
        activeThridMenuId: this.activeThridMenuId,
        activeSecondMenuId: this.activeSecondMenuId,
        activeFirstMenuId: this.activeFirstMenuId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.tabs {
  padding: 0 20px;
  background: white;
}
.cate {
  padding: 10px 0 20px 0;
  &-label {
    width: 60px;
    text-align: center;
    color: #888888;
  }
  &-pType {
    padding: 2px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    &:hover,
    &.active {
      background: #e3ecff;
      color: #4482ff;
    }
  }
  &-pCon {
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
</style>
