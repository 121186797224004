<template>
  <el-card shadow="hover" class="user">
    <div class="user-cover">
      <img :src="data.trainerImage" v-if="data.trainerImage" />
      <img v-else :src="$oss('expertsList/avatar_default.png')" />
    </div>
    <div class="user-nameUnit">
      <div class="user-name">
        <span>{{ data.trainerName }}</span>
        <span>{{ data.trainerPositionTitle }}</span>
      </div>
      <div class="user-unit" v-if="data.trainerWorkUnit">
        {{ data.trainerWorkUnit }}
      </div>
    </div>
    <div class="user-edu">
      <span v-if="data.trainerUniversity">{{ data.trainerUniversity }}</span>
      <span>{{ eduMap[+data.trainerEducation] }}</span>
    </div>
    <div class="user-btn">
      <span class="pointer" @click="toDetail(data)">查看详情</span>
    </div>
  </el-card>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    eduMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    toDetail(data) {
      this.$router.push({
        name: "teacherDetail",
        query: {
          id: data.trainerId,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user {
  width: 274px;
  text-align: center;
  margin-right: 41px;
  margin-bottom: 30px;
  /deep/ .el-card__body {
    padding: 0;
  }
  &-cover {
    padding: 15px 0;
    img {
      width: 91px;
      height: 91px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &-name {
    font-weight: bold;
    margin-bottom: 8px;
    span {
      &:first-child {
        margin-right: 14px;
      }
    }
  }
  &-unit {
    font-size: 12px;
    color: #666666;
  }
  &-nameUnit {
    margin-bottom: 30px;
  }
  &-edu {
    font-size: 14px;
    color: #7e7e7e;
    margin-bottom: 15px;
    span {
      &:first-child {
        margin-right: 18px;
      }
    }
  }
  &-field {
    padding: 0 16px;
    color: #999999;
    margin-bottom: 20px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    span {
      color: #666666;
    }
  }
  &-btn {
    margin-bottom: 20px;
    span {
      color: white;
      background: #4482ff;
      border-radius: 3px;
      padding: 8px 28px;
      display: inline-block;
    }
  }
}
</style>
