<template>
  <div class="alliance">
    <div class="alliance-inner">
      <BlockHeader title="行业协会" />
      <div class="con" v-loading="loading">
        <div v-for="(item, index) in lists" :key="index" class="con-item">
          <div class="con-leagueName">
            <span @click="allianceDetail(item)" class="pointer">{{
              item.leagueName
            }}</span>
          </div>
          <div class="flex flex-row">
            <div class="con-left">
              <el-carousel trigger="click" height="315px">
                <el-carousel-item
                  v-for="(pic, index) in item.leagueCarousels"
                  :key="index"
                >
                  <div class="con-pic">
                    <img :src="pic.carouselUrl" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="flex-1 con-center dynamic">
              <div
                class="dynamic-label flex flex-row flex-between pointer"
                @click="allianceDetail(item)"
              >
                <div>动态</div>
                <div><i class="el-icon-arrow-right"></i></div>
              </div>
              <div>
                <div
                  v-for="(dynamic, dIndex) in item.leagueActivities"
                  :key="`d${dIndex}`"
                  class="dynamic-list pointer ellipsis"
                  @click="allianceActDetail(dynamic, item)"
                >
                  <span class="dynamic-index">{{ dIndex + 1 }}.</span>
                  <span>{{ dynamic.activityTitle }}</span>
                </div>
              </div>
            </div>
            <div class="con-right">
              <img :src="$oss(`home/zone${(index % 2) + 1}.png`)" />
              <div class="member">
                <div class="member-label">成员</div>
                <div>
                  <div
                    v-for="(member, index) in item.leagueMembers"
                    :key="index"
                    class="member-list flex flex-row pointer ellipsis"
                    @click="toLabDetail(member)"
                  >
                    <span
                      class="member-role"
                      :class="{ master: member.applyDuties === '1' }"
                      >{{ roleMap[member.applyDuties] }}</span
                    >
                    <span> {{ member.memberName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { allianceHomeList } from "@/api/allianceZone";
import { ALLIANCE_ROLE } from "@/constant/dict";
import BlockHeader from "../BlockHeader.vue";
export default {
  components: {
    BlockHeader,
  },
  data() {
    return {
      lists: [],
      roleMap: {},
      loading: false,
    };
  },
  async created() {
    this.getDict();
    this.getList();
  },
  methods: {
    async getDict() {
      const res = await this.$store.dispatch("getDictData", ALLIANCE_ROLE);
      const dMap = {};
      res.forEach((item) => {
        dMap[item.dictValue] = item.dictLabel;
      });
      this.roleMap = dMap;
    },
    async getList() {
      this.loading = true;
      const data = await this.$axios.get(allianceHomeList).finally(() => {
        this.loading = false;
      });
      this.lists = data;
    },
    toList() {
      this.$jump.push({ name: "allianceList" });
    },
    allianceDetail(data) {
      this.$jump.push({
        name: "allianceDetail",
        query: { id: data.leagueId },
      });
    },
    allianceActDetail({ leagueActivityId }, { leagueId }) {
      this.$jump.push({
        name: "allianceActDetail",
        query: { id: leagueActivityId, detailId: leagueId },
      });
    },
    toLabDetail({ laborarotyId }) {
      this.$jump.push({
        name: "labDetail",
        query: { id: laborarotyId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "../../common.scss";
@mixin commonWH() {
  width: 315px;
  height: 272px;
}
.con {
  min-height: 300px;
  &-left {
    @include commonWH;
  }
  &-pic {
    @include commonWH;
    > img {
      @include commonWH;
      object-fit: cover;
    }
  }
  &-right {
    height: 314px;
    height: 272px;
    position: relative;
  }
  &-leagueName {
    font-size: 20px;
    margin-bottom: 19px;
  }
  &-item {
    margin-bottom: 43px;
  }
  &-center {
    background: white;
    padding: 17px 25px;
  }
}
.member {
  position: absolute;
  width: 87%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  padding: 15px 19px;
  &-label {
    padding-bottom: 8px;
    border-bottom: 1px solid #d2d2e7;
    font-size: 16px;
    margin-bottom: 18px;
  }
  &-list {
    margin-bottom: 10px;
    max-width: 280px;
  }
  &-role {
    background: #4482ff;
    border-radius: 4px;
    padding: 0px 5px;
    margin-right: 5px;
    &.master {
      background: #f27609;
    }
  }
}
.dynamic {
  &-label {
    padding-bottom: 8px;
    border-bottom: 1px solid #d2d2e7;
    font-size: 16px;
    color: #747474;
    margin-bottom: 15px;
    &:hover {
      color: #000;
    }
  }
  &-list {
    font-size: 16px;
    color: #666666;
    margin-bottom: 5px;
    max-width: 528px;
    &:hover {
      color: #000;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-index {
    margin-right: 10px;
  }
}
.alliance {
  padding-top: 32px;

  &-inner {
    width: $max-width;
    margin: 0 auto;
    padding-top: 20px;
  }
}
</style>
