<template>
  <div class="fine">
    <div class="fine-inner">
      <BlockHeader title="优选商城" />
      <div class="flex flex-row">
        <div class="types"><Types /></div>
        <div class="flex-1">
          <Ads />
          <div class="flex flex-row flex-between goods" v-loading="goodLoading">
            <div
              v-for="(good, index) in goods"
              :key="index"
              class="good pointer"
              @click="goodClick(good)"
            >
              <div class="good-cover">
                <el-image
                  :src="good.productmallWaresImage"
                  v-if="good.productmallWaresImage"
                  fit="cover"
                  lazy
                ></el-image>
                <img v-else :src="$oss('common/default_device.png')" />
              </div>
              <div class="good-desc">
                <div class="good-name ellipsis">
                  {{ good.productmallWaresName }}
                </div>
                <div class="good-price">
                  <span v-if="!good.productmallWareMinprice">面议</span>
                  <span v-else>￥{{ good.productmallWareMinprice }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Types from "./Types.vue";
import Ads from "./Ads.vue";
import { fineMallGoodList } from "@/api/mall";
import BlockHeader from "../BlockHeader.vue";
export default {
  name: "modular_product_mall",
  components: {
    Ads,
    Types,
    BlockHeader,
  },
  data() {
    return {
      page: {
        pageIndex: 1,
        pageSize: 4,
      },
      goods: [],
      goodLoading: false,
      unitMap: {},
    };
  },
  created() {
    this.getGoods();
  },
  methods: {
    // / 列表查询
    async getGoods() {
      this.goodLoading = true;
      const params = {
        ...this.page,
      };
      const { list } = await this.$axios
        .post(fineMallGoodList, params)
        .finally(() => {
          this.goodLoading = false;
        });
      this.goods = list || [];
    },
    goodClick(good) {
      this.$jump.push({
        name: "goodsDetail",
        query: { id: good.productmallWaresId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "@/style/common";
.fine {
  min-height: 400px;
  padding-top: 22px;
  padding-bottom: 25px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
}
.types {
  width: 240px;
  margin-right: 20px;
}
.goods {
  min-height: 221px;
}
.good {
  width: 225px;
  &-cover {
    width: 100%;
    height: 153px;
    overflow: hidden;
    .el-image {
      width: 100%;
      height: 100%;
    }
    /deep/ img {
      width: 100%;
      height: 100%;
      transition: all 0.5s ease-in-out 0s;
    }
  }
  &-desc {
    text-align: center;
    background: white;
    padding: 10px 0;
  }
  &-name {
    font-size: 16px;
    padding: 0 10px;
    max-width: 80%;
    margin-bottom: 4px;
  }
  &-price {
    font-weight: bold;
    color: #ff4911;
  }
}
.good:hover .good-cover /deep/ img {
  transform: scale(1.05);
}
</style>
