<template>
  <div class="abli">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="检测能力名称" prop="abilityName">
        <el-input
          v-model="form.abilityName"
          placeholder="请输入检测能力名称"
          :maxlength="100"
        />
      </el-form-item>
      <div class="flex flex-row">
        <el-form-item label="检测能力展示图" class="flex-1" prop="abilityImage">
          <ImageUpload
            :imgs="form.abilityImage"
            @onSuccess="abilityImageSuccess"
          />
        </el-form-item>
        <div class="flex-1">
          <el-form-item label="检测能力分类" prop="laboratoryAbilityTypeId">
            <el-cascader
              v-model="form.laboratoryAbilityTypeId"
              :options="allAbilityTypes"
              :props="{
                value: 'laboratoryAbilityTypeId',
                label: 'laboratoryAbilityTypeName',
                children: 'laboratoryAbilityTypeSon',
              }"
              placeholder="请选择入检测能力分类"
              clearable
              class="abli-kind"
              ref="cascader"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="检测依据" prop="checkAccord">
            <el-input
              v-model="form.checkAccord"
              placeholder="请输入检测依据"
              :maxlength="150"
            />
          </el-form-item>
        </div>
      </div>
      <el-form-item label="资质要求" prop="qualificationId">
        <el-checkbox-group v-model="form.qualificationIds" class="qua">
          <el-checkbox
            v-for="(qua, qIndex) in qualificationOptions"
            :label="qua.qualificationId"
            :key="qIndex"
          >
            <div class="qua-icon" :class="{ isIco: !!qua.qualificationIco }">
              <img :src="qua.qualificationIco" v-if="qua.qualificationIco" />
              <span v-else>{{ qua.qualificationName }}</span>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="检测方法" prop="checkMethod">
        <el-input
          v-model="form.checkMethod"
          placeholder="请输入检测方法"
          :maxlength="150"
        />
      </el-form-item>
      <el-form-item label="检测描述" prop="checkDesc">
        <el-input
          v-model="form.checkDesc"
          type="textarea"
          placeholder="请输入内容"
          :rows="3"
          :maxlength="300"
        />
      </el-form-item>
      <el-form-item label="具体检测指标" prop="checkSpeQuota">
        <el-input
          v-model="form.checkSpeQuota"
          placeholder="请输入具体检测指标"
          :maxlength="150"
        />
      </el-form-item>
      <el-form-item label="参考标准" prop="checkReferenceStandard">
        <el-input
          v-model="form.checkReferenceStandard"
          placeholder="请输入参考标准"
          :maxlength="150"
        />
      </el-form-item>
      <el-form-item label="案例描述" prop="checkCaseDescribe">
        <el-input
          v-model="form.checkCaseDescribe"
          type="textarea"
          placeholder="请输入内容"
          :rows="3"
          :maxlength="300"
        />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请输入内容"
          :rows="2"
          :maxlength="300"
        />
      </el-form-item>
      <el-form-item
        label="状态"
        prop="abilityStatus"
        v-if="['0', '1'].includes(form.abilityStatus)"
      >
        <el-select v-model="form.abilityStatus" placeholder="请选择">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button
          type="primary"
          plain
          size="small"
          @click="saveBtn"
          :loading="loading"
          v-if="isEdit || !curId"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { abilityDetail, qualificationList } from "@/api/hall";
import { addOrEditAblity } from "@/api/userCenter";
import ImageUpload from "@/components/ImgUpload";
import RULE from "./rule";
export default {
  components: {
    ImageUpload,
  },
  props: {
    curId: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    allAbilityTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        abilityImage: "",
        abilityName: "",
        checkAccord: "",
        qualificationIds: [],
        checkCaseDescribe: "",
        laboratoryAbilityTypeId: [],
        checkMethod: "",
        checkDesc: "",
        checkSpeQuota: "",
        checkReferenceStandard: "",
        remark: "",
      },
      // 表单校验
      rules: RULE,
      loading: false,
      statusOptions: [
        { value: "0", label: "已下架" },
        { value: "1", label: "已上架" },
      ],
      qualificationOptions: [],
    };
  },
  created() {
    if (this.curId) {
      this.getDetail();
    }
    this.getQualificationList();
  },
  methods: {
    // 资质分类
    async getQualificationList() {
      const data = await this.$axios.get(qualificationList);
      this.qualificationOptions = data;
    },
    async getDetail() {
      const res = await this.$axios.get(abilityDetail, {
        abilityId: this.curId,
      });
      const {
        laboratoryAbilityTypeIdParents,
        laboratoryAbilityTypeId,
        qualificationIds,
        ...rest
      } = res;
      const typeIds = [laboratoryAbilityTypeIdParents];
      if (laboratoryAbilityTypeId) typeIds.push(laboratoryAbilityTypeId);
      this.form = {
        ...rest,
        qualificationIds: (qualificationIds || "")
          .split(",")
          .map((item) => +item),
        laboratoryAbilityTypeId: typeIds,
      };
    },
    abilityImageSuccess(fileUrls) {
      Object.assign(this.form, { abilityImage: fileUrls });
    },
    async saveBtn() {
      await this.$refs.form.validate();
      const { laboratoryAbilityTypeId, qualificationIds } = this.form;
      const pathLabels = this.$refs.cascader.getCheckedNodes()[0].pathLabels;
      const params = {
        ...this.form,
        laboratoryAbilityTypeIdParents: laboratoryAbilityTypeId[0],
        laboratoryAbilityTypeNameParents: pathLabels[0],
        qualificationIds: qualificationIds.join(","),
      };
      if (laboratoryAbilityTypeId.length > 1) {
        params.laboratoryAbilityTypeId = laboratoryAbilityTypeId[1];
      }
      if (pathLabels.length > 1) {
        params.laboratoryAbilityTypeName = pathLabels[1];
      }
      this.loading = true;
      await this.$axios.post(addOrEditAblity, params).finally(() => {
        this.loading = false;
      });
      this.$message.success("保存成功");
      this.$emit("saveSuccess");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.abli {
  padding: 0 15px;
  /deep/ textarea {
    resize: none;
  }
  &-kind {
    width: 100%;
  }
}
.qua {
  &-icon {
    width: 60px;
    height: 30px;
    &.isIco {
      border: 1px solid $border-color;
    }
    > img {
      width: 60px;
      height: 30px;
      object-fit: contain;
    }
  }
}
</style>
