<template>
  <div class="mall">
    <div class="mall-head flex flex-row flex-between">
      <div class="mall-headTitle">设置商城信息</div>
      <div
        v-if="form.productmallBusinessStatus !== mallStatusChecking"
        class="mall-headBtn"
      >
        <el-button
          type="primary"
          plain
          size="mini"
          @click="saveSubmit"
          :loading="saveLoading"
          >提交</el-button
        >
      </div>
    </div>
    <el-form
      class="mall-form"
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      v-loading="formLoading"
    >
      <el-form-item label="检测商城商家名称" prop="productmallBusinessName">
        <el-input v-model="form.productmallBusinessName"></el-input>
      </el-form-item>
      <div class="flex">
        <div>
          <el-form-item label="检测商城商家logo" prop="productmallBusinessLogo">
            <ImgUpload
              @onSuccess="imgUploadSuccess"
              :imgs="form.productmallBusinessLogo"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item
            label="商家联系人姓名"
            prop="productmallBusinessContactsName"
          >
            <el-input v-model="form.productmallBusinessContactsName"></el-input>
          </el-form-item>
          <el-form-item
            label="商家联系电话"
            prop="productmallBusinessContactsPhone"
          >
            <el-input
              v-model="form.productmallBusinessContactsPhone"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="检测商城商家介绍" prop="productmallBusinessBrief">
        <el-input
          v-model="form.productmallBusinessBrief"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="审核状态"
        v-if="form.productmallBusinessStatus"
        class="mall-formItemLast"
      >
        {{ mallStatus[+form.productmallBusinessStatus] }}
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import ImgUpload from "@/components/ImgUpload";
import { joinOrUpdateSuperMall, getMallBusinessInfo } from "@/api/userCenter";
import { MALL_STATUS, MALL_STATUS_CHECKING } from "@/constant/dict";
import { mapGetters } from "vuex";
import RULE from "./rules";
export default {
  components: {
    ImgUpload,
  },
  props: {
    labId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      // （0-关闭；1-启用；2-审核中（默认审核中）；3-审核不通过）
      mallStatus: MALL_STATUS,
      mallStatusChecking: MALL_STATUS_CHECKING,
      form: {
        productmallBusinessName: "",
        productmallBusinessLogo: "",
        productmallBusinessContactsName: "",
        productmallBusinessContactsPhone: "",
        productmallBusinessBrief: "",
      },
      formLoading: false,
      saveLoading: false,
      rules: RULE.superFormRule,
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  created() {
    this.getSuperMallInfo(); // 获取是否有商城信息
  },
  methods: {
    async getSuperMallInfo() {
      this.formLoading = true;
      const res = await this.$axios
        .get(getMallBusinessInfo, {
          applyOrgId: this.labId,
        })
        .finally(() => {
          this.formLoading = false;
        });
      if (res) {
        this.form = res;
        const { productmallBusinessId, productmallBusinessStatus } = res;
        this.$emit("setMallInfo", {
          businessId: productmallBusinessId,
          status: productmallBusinessStatus,
        });
      }
    },
    async saveSubmit() {
      await this.$refs.ruleForm.validate();
      const orgType = this.judgeOrgTypeByAuth(); // 通过权限来判断机构类别
      if (orgType) {
        this.saveLoading = true;
        await this.$axios
          .post(joinOrUpdateSuperMall, {
            ...this.form,
            applyOrgType: orgType,
          })
          .finally(() => {
            this.saveLoading = false;
          });
        this.$message.success("提交成功，请等待审核");
        this.getSuperMallInfo(); // 获取是否有商城信息
      } else {
        this.$message.error("无法判断机构类型");
      }
    },
    // 通过权限来判断机构类别
    judgeOrgTypeByAuth() {
      // （LAB-实验室；ENT-企业）
      const { userJurisdictionList } = this.userBaseInfo;
      const isLab = userJurisdictionList.find((item) => {
        return item.secondaryJurisdiction === "labAccountManage";
      });
      if (isLab) return "LAB";
      const isEnt = userJurisdictionList.find((item) => {
        return item.secondaryJurisdiction === "entAccountManage";
      });
      if (isEnt) return "ENT";
      return "";
    },
    imgUploadSuccess(file) {
      this.form.productmallBusinessLogo = file;
    },
  },
};
</script>
<style lang="scss" scoped>
.mall {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  margin-bottom: 10px;
  &-head {
    border-bottom: 1px solid #dcdfe6;
    padding: 8px 10px;
    font-weight: bold;
  }
  &-headTitle {
    padding-top: 5px;
  }
  &-form {
    padding: 8px 10px 0 10px;
  }
  &-formItemLast {
    margin-bottom: 0;
  }
}
</style>
