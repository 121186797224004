<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <OrderProgress :step="1" />
    <div class="cart flex flex-center">
      <div class="cart-inner">
        <div class="top flex flex-between">
          <div class="top-all">全部商品</div>
          <!-- ({{ totalItem }})-->
          <div class="top-right flex-1 flex flex-right">
            <div>
              <span>已选商品</span>
              <span class="top-total">
                <label v-if="totalMoney">￥</label>{{ totalMoney }}
              </span>
              <el-button
                :type="selectGoodNum ? 'primary' : 'info'"
                size="mini"
                :disabled="!selectGoodNum"
                >结算</el-button
              >
            </div>
          </div>
        </div>
        <CartHead @checkChange="barCheckAllChange" v-model="barCheckAll" />
        <div class="cart-body" v-loading="listLoading">
          <CartList
            :data="cartList"
            @allCheckChange="changeAllCheckBtnValue"
            @selectNumChange="selectNumChange"
            @inputNumberChange="inputNumberChange"
            @refresh="refresh"
            ref="cartList"
          />
        </div>
        <div class="cartBar flex flex-between">
          <div>
            <el-checkbox v-model="barCheckAll" @change="barCheckAllChange"
              >全选</el-checkbox
            >
            <span class="cartBar-del pointer" @click="delMultipleGood"
              >删除</span
            >
          </div>
          <div class="flex">
            <div>
              已选商品<label class="cartBar-hight"
                >&nbsp;{{ selectGoodNum }}&nbsp;</label
              >件
            </div>
            <div class="cartBar-total">
              合计：<label class="cartBar-hight cartBar-money">{{
                totalMoney
              }}</label>
            </div>
            <div
              class="cartBar-pay pointer"
              :class="{ active: !!selectGoodNum }"
              @click="toPay"
            >
              结算
            </div>
          </div>
        </div>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CartHead from "./components/CartHead.vue";
import CartList from "./components/CarList.vue";
import { goodCartList, delteCart } from "@/api/mall";
import { SET_SHOOSEGOODS } from "@/store/types";
import { sleep } from "@/utils/index";

export default {
  components: {
    CartHead,
    CartList,
  },
  data() {
    return {
      totalMoney: 0,
      cartHeadNullData: [],
      cartList: [],
      totalItem: null,
      barCheckAll: false,
      checkGoods: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      checked: false,
      selectGoodNum: 0,
      listLoading: false,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    this.getCartList();
  },
  methods: {
    refresh() {
      this.getCartList();
    },
    // 底部/顶部bar的全选按钮值改变时
    barCheckAllChange(val) {
      this.barCheckAll = val;
      this.$refs.cartList.allCartCheckChange(val);
    },
    // 修改全选的值，子组件emit的事件
    changeAllCheckBtnValue(val) {
      this.barCheckAll = val;
    },
    // 删除商品
    async delMultipleGood() {
      const goods = this.$refs.cartList.selectGoodsMap;
      const delCartIds = [];
      Object.keys(goods).forEach((key) => {
        goods[key].forEach(({ productmallShoppingCartId }) => {
          delCartIds.push(productmallShoppingCartId);
        });
      });
      await this.$confirm("确定删除该商品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$axios.post(delteCart, {
        cartIds: delCartIds,
      });
      this.getCartList();
    },
    async getCartList() {
      this.listLoading = true;
      const params = {
        ...this.page,
      };
      const { list, totalItem } = await this.$axios
        .post(goodCartList, params)
        .finally(() => {
          this.listLoading = false;
        });
      this.cartList = list;
      this.totalItem = totalItem;
    },
    // 选中商品数量改变
    selectNumChange(val) {
      this.selectGoodNum = val;
      this.getTotalMoney();
    },
    // 表格商品数量改变时
    inputNumberChange() {
      this.getTotalMoney();
    },
    // 计算总价格
    getTotalMoney() {
      const goods = this.$refs.cartList.selectGoodsMap;
      let totalMoney = 0;
      Object.keys(goods).forEach((key) => {
        goods[key].forEach(({ quantity, discountPrice }) => {
          const price = +discountPrice;
          const money = (quantity * price).toFixed(2);
          totalMoney += Number(money);
        });
      });
      this.totalMoney = totalMoney.toFixed(2);
    },
    // 点击结算按钮
    async toPay() {
      if (!this.token) {
        this.$store.dispatch("login");
        return;
      }
      const goods = this.$refs.cartList.selectGoodsMap;
      const waresRanks = [];
      Object.keys(goods).forEach((key) => {
        goods[key].forEach(({ quantity, productmallWaresRankId }) => {
          waresRanks.push({ quantity, productmallWaresRankId });
        });
      });
      const store = {
        purchaseType: "1", // 购买方式（0-直接购买；1-购物车购买）
        waresRanks: waresRanks,
      };
      sessionStorage.setItem(SET_SHOOSEGOODS, JSON.stringify(store));
      await sleep(200);
      this.$jump.push({
        name: "goodsOrder",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.cart {
  &-inner {
    min-height: 500px;
    width: $max-width;
  }
  &-body {
    min-height: 300px;
  }
}
.cartBar {
  height: 50px;
  line-height: 50px;
  background: $head-bar-bg;
  padding-left: 20px;
  &-total {
    margin-left: 15px;
    margin-right: 5px;
  }
  &-pay {
    background: #b0b0b0;
    color: white;
    padding: 0 40px;
    &.active {
      background: $theme-color;
    }
  }
  &-hight {
    color: $price-color;
    font-weight: 700;
    font-size: 1.28rem;
  }
  &-money {
    &::before {
      content: "￥";
      font-size: 0.85rem;
    }
  }
  &-del {
    margin-left: 20px;
  }
}
.top {
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;
  &-all {
    color: $theme-color;
    font-size: 1.14rem;
    font-weight: bold;
    border-bottom: 2px solid $theme-color;
  }
  &-right {
    border-bottom: 2px solid $border-color;
  }
  &-total {
    margin-right: 10px;
    margin-left: 10px;
    color: $price-color;
  }
}
.myTable {
  margin-bottom: 30px;
  /deep/ .el-table__body tr:hover > td {
    background-color: white;
  }
}
.good {
  &-cover {
    width: 80px;
    height: 60px;
    object-fit: contain;
    border: 1px solid $border-color;
  }
  &-name {
    margin-left: 20px;
  }
  &-del {
    &:hover {
      color: $theme-color;
    }
  }
  &-price {
    color: $price-color;
    &::before {
      content: "￥";
      font-size: 0.85rem;
    }
  }
}
.list {
  &-cover {
    width: 100px;
    height: 80px;
    object-fit: cover;
  }
}
</style>
