<template>
  <div class="chart flex flex-column">
    <Head @onlineChange="onlineChange" v-model="onlineStatus" />
    <div class="con flex">
      <div class="con-list">
        <ConsultList
          :data="consultList"
          @currentFriend="currentFriendChange"
          @unReadClear="unReadClear"
          @getHistory="getHistory"
        />
      </div>
      <div class="con-conversation flex-1 flex flex-column">
        <div class="flex-1 con-msgs">
          <!-- <div class="con-more" v-show="currentFriend.sendId">
            <span class="pointer">加载更多</span>
          </div> -->
          <Conversation :msgs="conversation" />
        </div>
        <SendMsg @send="sendBtnClick" />
      </div>
      <div class="con-consult">
        <ConsultTabs :consult="consultMapInfo[currentFriend.sendId]" />
      </div>
    </div>
  </div>
</template>
<script>
import io from "socket.io-client"; //引入socket.io-client
import Head from "./components/Head.vue";
import ConsultList from "./components/ConsultList.vue";
import SendMsg from "@/components/ConsultDialog/SendMsg.vue";
import ConsultTabs from "./components/ConsultTabs.vue";
import Conversation from "@/components/ConsultDialog/Conversation.vue";
import { mapGetters } from "vuex";
import msgMixin from "@/components/ConsultDialog/msgMixin.js";
import { IDENTITY_SERVICE, MSG_SYS } from "@/constant/chart.js";
import { APISOCKET } from "@/api/constant";
import { getUserById } from "@/api/common";

export default {
  components: {
    Head,
    ConsultList,
    SendMsg,
    ConsultTabs,
    Conversation,
  },
  mixins: [msgMixin],
  data() {
    return {
      socket: null,
      onlineStatus: false,
      userRoomInfo: {
        identity: IDENTITY_SERVICE,
        online: false,
      },
      consultList: [], // 好友列表
      conversation: [], // 对话
      currentFriend: {}, // 当前聊天的好友
      consultMapInfo: {}, // 好友(客户)详细的信息
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  mounted() {
    this.socket = io(APISOCKET);
    this.initSocketEvent();
    this.initSocketEventService();
    this.conversation = [];
  },
  beforeDestroy() {
    this.closeSeesion();
  },
  methods: {
    // 清空未读
    unReadClear(index) {
      this.toChangeUnreadNum(this.consultList[index], 0);
    },

    initSocketEventService() {
      this.socket.on("friendsList", (data) => {
        this.consultList = data;
      });
      // 返回历史记录
      this.socket.on("historyMessage", async (data) => {
        this.conversation = data;
      });
    },
    // 收到消息
    reviceSocketMessage(data) {
      const { sendId: friendSendId } = this.currentFriend;
      const { userId } = this.userBaseInfo;
      const isCantact = [friendSendId, userId].includes(data.sendId);
      const isSysMsg = data.messageType === MSG_SYS;
      if (isCantact || isSysMsg) {
        this.conversation.push(data);
      } else {
        this.toChangeUnreadNum(data, "add");
      }
    },
    toChangeUnreadNum({ sendId, reviceId }, falg) {
      this.socket.emit("changeUnRead", { sendId, reviceId }, falg);
      const index = this.consultList.findIndex(
        (item) => item.sendId === sendId
      );
      if (falg === "add") this.consultList[index].unRead++;
      else this.consultList[index].unRead = falg;
    },
    // 上下线切换
    onlineChange(val) {
      if (val) {
        this.joinChat(); // 上线
      } else {
        this.closeSeesion();
      }
    },
    // 切换聊天列表
    async currentFriendChange(consultIndex) {
      this.currentFriend = this.consultList[consultIndex];
      const { sendId: consultId } = this.currentFriend;
      if (!this.consultMapInfo[consultId]) {
        const res = await this.$axios.get(getUserById, { userId: consultId });
        this.$set(this.consultMapInfo, consultId, res);
      }
    },
    // 加入房间成功
    joinSuccessCallback() {
      const { sendId, room } = this.userRoomInfo;
      /* 好友列表是以咨询者的视角加入的数据，所以客服查询好友列表时，reviceId为自己的数据就是
      room 是告诉socket查到数据后，向哪个房间发送数据
       */
      this.getFriendsList({ reviceId: sendId, room });
    },
    // 发送信息
    sendBtnClick(val) {
      const { online } = this.userRoomInfo;
      if (!online) {
        this.$message.warning("请先上线");
        return;
      }
      const { sendId, sendName } = this.currentFriend;
      if (!sendId) {
        this.$message.warning("请先选择要聊天的客户");
        return;
      }
      this.sendMessage({
        reviceId: sendId,
        reviceName: sendName,
        message: val,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.chart {
  background: #f7f7f7;
  height: 100%;
}
.con {
  min-height: 600px;
  &-list {
    width: 200px;
    background: white;
    margin-right: 4px;
  }
  &-conversation {
    background: white;
    margin-right: 4px;
  }
  &-consult {
    background: white;
    width: 250px;
    padding: 0 3px;
  }
  &-msgs {
    overflow: auto;
    max-height: 450px;
    padding-top: 10px;
  }
  &-more {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
    span {
      background: $home-bg;
      padding: 5px 15px;
      display: inline-block;
      border-radius: 15px;
      color: #747474;
    }
  }
}
</style>
