<template>
  <div class="flow">
    <el-steps :active="row.orderCheckStatus | filterStep" align-center>
      <!--200-->
      <el-step title="下单完成" icon="el-icon-s-claim"></el-step>
      <!--300-->
      <el-step title="等待样品" icon="el-icon-s-cooperation"></el-step>
      <!--400-->
      <el-step title="正在检测" icon="el-icon-time"></el-step>
      <!--500-->
      <el-step title="报告编制" icon="el-icon-document"></el-step>
      <!--600-->
      <el-step title="完成订单" icon="el-icon-circle-check"></el-step>
    </el-steps>
  </div>
</template>
<script>
// import { DEED_ORDER_CHECKING } from "@/constant/index";
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  filters: {
    /**
     *BEFOREHANDORDER(100,"预下单"),
        USERORDER(200, "下单完成"),
        WAITSAMPLE(300, "等待样品"),
        TESTING(400, "正在检测"),
        CREATREPORT(500, "报告编制"),
        COMPLETEORDER(600, "完成订单");
     */
    filterStep(val) {
      if (val) return +val / 100 - 1;
      return 1;
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.flow {
  padding: 10px;
  border-bottom: 1px solid $border-color;
  /deep/ .el-step__title {
    font-size: 1rem !important;
  }
}
</style>
