<template>
  <div class="flex flex-row flex-wrap">
    <Expert
      :data="item"
      :eduMap="eduMap"
      v-for="(item, index) in data"
      :key="index"
      :class="{ isRight: (index + 1) % 4 === 0 }"
    />
  </div>
</template>
<script>
import { EDU_GRADE } from "@/constant/dict";
import Expert from "@/components/Expert/index";
export default {
  components: {
    Expert,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      eduMap: {},
    };
  },
  created() {
    this.getDict();
  },
  methods: {
    async getDict() {
      const res = await this.$store.dispatch("getDictData", EDU_GRADE);
      const eduMap = {};
      res.forEach((item) => {
        eduMap[item.dictValue] = item.dictLabel;
      });
      this.eduMap = eduMap;
    },
  },
};
</script>
<style lang="scss" scoped>
.isRight {
  margin-right: 0;
}
</style>
