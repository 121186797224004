<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="regis flex flex-center">
      <div class="regis-inner">
        <el-card shadow="hover" class="regis-card">
          <div class="regis-title">
            <label>
              <span>实验室入驻</span>
              <em></em>
            </label>
          </div>
          <el-form
            :model="form"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
          >
            <div class="regis-label">单位信息</div>
            <el-form-item label="单位名称 " prop="laboratoryName">
              <el-input
                v-model="form.laboratoryName"
                placeholder="请输入单位名称"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="固定电话" prop="enterprisePhone">
              <el-input
                v-model="form.enterprisePhone"
                placeholder="请输入固定电话"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="企业邮箱" prop="laboratoryEmail">
              <el-input
                v-model="form.laboratoryEmail"
                placeholder="请输入企业邮箱"
              ></el-input>
            </el-form-item>
            <div class="flex">
              <el-form-item
                label="详细地址"
                prop="laboratoryLocation"
                class="address"
              >
                <el-cascader
                  v-model="form.laboratoryLocation"
                  :options="cityOptions"
                  @change="handleChange"
                  filterable
                  clearable
                  placeholder="请选择省、市、区"
                  ref="cityCascader"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label=""
                prop="laboratoryAddress"
                label-width="0"
                class="flex-1"
              >
                <el-input
                  v-model="form.laboratoryAddress"
                  placeholder="请输入详细地址"
                ></el-input>
              </el-form-item>
            </div>
            <div class="regis-label">业务联系人信息</div>
            <el-form-item label="姓名" prop="laboratoryContactsName">
              <el-input
                v-model="form.laboratoryContactsName"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="laboratoryContactsPhone">
              <el-input
                v-model="form.laboratoryContactsPhone"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="实验室简介" prop="laboratoryBrief">
              <el-input
                v-model="form.laboratoryBrief"
                type="textarea"
                rows="5"
                placeholder="请输入实验室简介"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="特色检测能力情况说明"
              prop="specialAbility"
              class="line-height-s"
            >
              <el-input
                v-model="form.specialAbility"
                type="textarea"
                rows="5"
                placeholder="请输入特色检测能力情况"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="实验室检测业务情况说明"
              prop="laboratoryCheckInfo"
              class="line-height-s"
            >
              <el-input
                v-model="form.laboratoryCheckInfo"
                type="textarea"
                rows="5"
                placeholder="请输入实验室检测业务情况"
              ></el-input>
            </el-form-item>
            <div class="regis-label">实验室资质</div>
            <el-form-item label="企业营业执照" class="line-height-s">
              <Upload
                :imgs="form.businessLicence"
                @onSuccess="businessLicenceSuccess"
              />
            </el-form-item>
            <el-form-item label="检验检测资质" class="line-height-s">
              <div class="flex regis-imgs">
                <div>
                  <Upload
                    :limit="3"
                    :imgs="form.checkQualification"
                    @onSuccess="checkQualificationSuccess"
                  />
                </div>
              </div>
            </el-form-item>
            <div class="regis-protocol">
              <el-checkbox v-model="protocolCheck">我已阅读</el-checkbox>
              <a :href="$oss('实验室入驻协议.html', 'protocols')" target="blank"
                >实验室入驻协议</a
              >并认可此协议
            </div>
          </el-form>
          <div class="regis-btn">
            <el-button
              type="primary"
              size="small"
              plain
              @click="register"
              :loading="btnLoading"
              >提&nbsp;&nbsp;交</el-button
            >
          </div>
        </el-card>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { regionData } from "element-china-area-data";
import { labRegister } from "@/api/lab";
import Upload from "@/components/ImgUpload/index";
import { sleep } from "@/utils/index";
export default {
  components: {
    Upload,
  },
  data() {
    return {
      btnLoading: false,
      form: {
        laboratoryName: "",
        laboratoryEmail: "",
        laboratoryLocation: [],
        laboratoryAddress: "",
        laboratoryContactsName: "",
        laboratoryContactsPhone: "",
        laboratoryBrief: "",
        specialAbility: "",
        laboratoryCheckInfo: "",
        userId: 990,
      },
      rules: {
        laboratoryName: [
          {
            required: true,
            message: "请输入单位名称",
            trigger: ["blur", "change"],
          },
        ],
        laboratoryEmail: [
          {
            required: true,
            message: "请输入企业邮箱",
            trigger: ["blur", "change"],
          },
        ],
        laboratoryLocation: [
          { required: true, message: "请选择省、市、区", trigger: "change" },
        ],
        laboratoryAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["blur", "change"],
          },
        ],
        laboratoryContactsName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["blur", "change"],
          },
        ],
        laboratoryContactsPhone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: ["blur", "change"],
          },
        ],
        laboratoryBrief: [
          {
            required: true,
            message: "请输入实验室简介",
            trigger: ["blur", "change"],
          },
        ],
        specialAbility: [
          {
            required: true,
            message: "请输入特色检测能力情况",
            trigger: ["blur", "change"],
          },
        ],
        laboratoryCheckInfo: [
          {
            required: true,
            message: "请输入实验室检测业务情况",
            trigger: ["blur", "change"],
          },
        ],
      },
      cityOptions: regionData,
      protocolCheck: false,
    };
  },
  created() {},
  methods: {
    handleChange() {},
    businessLicenceSuccess(fileUrls) {
      Object.assign(this.form, { businessLicence: fileUrls });
    },
    checkQualificationSuccess(fileUrls) {
      Object.assign(this.form, { checkQualification: fileUrls });
    },
    async register() {
      await this.$refs.ruleForm.validate();
      if (!this.protocolCheck) {
        this.$message.warning("请勾选协议");
        return;
      }
      try {
        const { laboratoryLocation, ...rest } = this.form;
        const [addressProvince, addressCity, addressDistrict] =
          this.$refs.cityCascader.getCheckedNodes()[0].pathLabels;
        const params = {
          laboratoryLocationProvince: addressProvince,
          laboratoryLocationProvinceCode: laboratoryLocation[0],
          laboratoryLocationCity: addressCity,
          laboratoryLocationCityCode: laboratoryLocation[1],
          laboratoryLocationCounty: addressDistrict,
          laboratoryLocationCountyCode: laboratoryLocation[2],
          ...rest,
        };
        this.btnLoading = true;
        await this.$axios.post(labRegister, params).finally(() => {
          this.btnLoading = false;
        });
        this.$message.success("注册成功,请等待审核");
        await sleep(1500);
        this.$router.push({ name: "home" });
      } catch (e) {
        this.$message.error((e && e.message) || "注册失败，请稍后再试");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$width: 800px;
.regis {
  &-inner {
    width: $width;
    max-width: $width;
    padding-top: 30px;
    min-height: 500px;
    /deep/ textarea {
      resize: none;
    }
  }
  &-title {
    margin-bottom: 20px;
    text-align: center;
    > label {
      position: relative;
      span {
        position: relative;
        font-size: 1.57rem;
        font-weight: 500;
        z-index: 10;
      }
      em {
        width: 100%;
        height: 12px;
        background: #b7d6ff;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
      }
    }
  }
  &-label {
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 1.14rem;
    font-weight: 600;
  }
  &-imgs {
    > div {
      margin-right: 15px;
    }
  }
  &-protocol {
    margin-bottom: 15px;
    > a {
      color: $theme-color;
      text-decoration: none;
      margin: 0 5px;
    }
  }
  &-btn {
    text-align: center;
    > .el-button {
      width: 100px;
    }
  }
}
.width-big {
  width: 310px;
}
.address {
  /deep/ .el-cascader {
    width: 280px;
    margin-right: 5px;
  }
}
.line-height-s {
  /deep/ .el-form-item__label {
    line-height: 20px;
    text-align: left;
  }
}
</style>
