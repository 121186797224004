<template>
  <div class="indu">
    <div class="indu-title">稀土行业中心</div>
    <div class="indu-text">{{ intro.join("") }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      intro: [
        `是我国稀土行业唯一的国家新材料测试评价中心，旨在依托包头稀土研究院，`,
        `联合行业内产学研用各类优势企业，建设测试评价服务体系，`,
        `为科研院所、企事业单位搭建一个专业、开放、共享、权威的服务平台。`,
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.indu {
  width: 336px;
  height: 276px;
  margin-right: 14px;
  color: white;
  background: url("https://store-img.cre-tep.com/frontDeskProject/images/home/行业中心背景.jpg");
  background-size: 100% 100%;
  &-title {
    text-align: center;
    font-size: 20px;
    // font-weight: bold;
    padding: 22px 0 12px 0;
  }
  &-text {
    font-size: 15px;
    padding: 0 30px;
    line-height: 32px;
    text-indent: 30px;
  }
}
</style>
