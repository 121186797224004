<template>
  <div class="ad-wrapper">
    <div class="ad-inner">
      <!-- 行广告位-->
      <template v-if="data.advertisementLocation === 'HAD'">
        <img
          :src="data.advertisementImagePath"
          class="ad-hImg pointer"
          @click="adClick(data.advertisementLink)"
        />
      </template>
      <!-- 分栏广告位 -->
      <template v-else>
        <div class="flex flex-row">
          <div class="flex-1">
            <img
              :src="data.advertisementImagePath"
              class="ad-fImg pointer"
              @click="adClick(data.advertisementLink)"
            />
          </div>
          <div class="flex-1">
            <img
              :src="data.advertisementImagePathSecond"
              class="ad-fImg pointer"
              @click="adClick(data.advertisementLinkSecond)"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "AD",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    adClick(link) {
      if (link) {
        window.open(link);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$img-h: 100px;
.ad {
  &-inner {
    width: $max-width;
    margin: 0 auto;
    // padding: 20px 0;
  }
  &-hImg {
    height: 100%;
    width: 100%;
    height: $img-h;
  }
  &-fImg {
    height: $img-h;
    width: 100%;
  }
}
</style>
