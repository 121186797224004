<template>
  <el-dialog
    title="审核账号"
    :visible.sync="dialogVisible"
    width="500px"
    class="dialog"
  >
    <el-form label-width="80px">
      <el-form-item label="姓名:">
        {{ data.realName }}
      </el-form-item>
      <el-form-item label="申请时间:">
        {{ data.createTime }}
      </el-form-item>
      <el-form-item label="审核:">
        <el-radio-group v-model="checkValue">
          <el-radio :label="'1'">通过</el-radio>
          <el-radio :label="'3'">不通过</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      <el-button type="primary" @click="confirmBtnClick" size="small"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { sheckApplyJoinLab } from "@/api/lab";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      checkValue: "1", // 默认通过
    };
  },
  methods: {
    async confirmBtnClick() {
      const { laboratoryId, relationId, userId } = this.data;
      await this.$axios.post(sheckApplyJoinLab, {
        laboratoryRelationStatus: this.checkValue,
        laboratoryId,
        relationId,
        userId,
      });
      this.$message.success("提交成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  /deep/ .el-dialog__body {
    padding: 10px 20px 0 20px;
  }
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
}
</style>
