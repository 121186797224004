<template>
  <div class="list flex flex-center">
    <div class="list-inner">
      <div class="list-bTitle">确认订单信息</div>
      <table class="table-onlyHead">
        <thead>
          <tr>
            <th width="55%">商品</th>
            <th width="15%">单价</th>
            <th width="15%">数量</th>
            <th width="15%">小计</th>
          </tr>
        </thead>
      </table>
      <div v-loading="loading">
        <div
          v-for="(item, index) in data.confirmWares"
          :key="index"
          class="mall"
        >
          <div class="table-mallName">
            商家：{{ item.productmallBusinessName }}
          </div>
          <table class="table-good">
            <tbody>
              <tr v-for="(rank, rIndex) in item.waresRanks" :key="rIndex">
                <td width="40%">
                  <div class="flex flex-row">
                    <div>
                      <img
                        :src="rank.productmallWaresImage"
                        class="list-cover"
                        v-if="rank.productmallWaresImage"
                      />
                      <img
                        v-else
                        :src="$oss('common/default_device.png')"
                        class="list-cover"
                      />
                    </div>
                    <div class="list-name">
                      {{ rank.productmallWaresName }}
                    </div>
                  </div>
                </td>
                <td width="15%">规格：{{ rank.waresRankName }}</td>
                <td width="15%">
                  <div
                    class="list-origin"
                    :class="{ 'list-originGray': +rank.discountStatus < 10 }"
                  >
                    ￥{{ rank.price }}
                  </div>
                  <div v-if="+rank.discountStatus < 10" class="list-discount">
                    ￥{{
                      ((+rank.price * +rank.discountStatus) / 10).toFixed(2)
                    }}
                  </div>
                </td>
                <td width="15%">{{ rank.quantity }}</td>
                <td width="15%">
                  <span class="list-resultPrice"
                    >￥{{ rank.waresCountPrice }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="remark flex flex-row">
            <div class="remark-title">给商家留言：</div>
            <div>
              <el-input
                type="textarea"
                class="remark-input"
                :rows="3"
                placeholder="请输入内容"
                v-model="item.orderLeavingMessage"
                resize="none"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>

      <div class="pay flex flex-right">
        <div class="pay-inner">
          <div class="pay-money">
            实付款:<span><span>￥</span>{{ data.countPrice }}</span>
          </div>
          <div class="pay-btn">
            <el-button
              @click="createOrder"
              :loading="createBtnLoading"
              type="primary"
              >立即付款</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    createBtnLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    createOrder() {
      this.$emit("createOrder");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.list {
  &-inner {
    width: $max-width;
    min-height: 400px;
  }
  &-bTitle {
    font-size: 1.07rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &-cover {
    width: 80px;
    height: 60px;
    object-fit: contain;
    border: 1px solid $border-color;
  }
  &-name {
    margin-left: 20px;
  }
  &-origin {
    font-weight: bold;
  }
  &-originGray {
    color: #9c9c9c;
    text-decoration: line-through;
    font-weight: normal;
  }
  &-discount {
    margin-top: 5px;
    font-weight: bold;
  }
  &-resultPrice {
    color: $price-color;

    font-weight: bold;
  }
}
.mall {
  margin-bottom: 20px;
}
.remark {
  margin-top: 10px;
  &-title {
    width: 90px;
  }
  &-input {
    width: 500px;
  }
}
table {
  // border-spacing: 0;
  // border-collapse: collapse;
  width: 100%;
  font-size: 0.85rem;
  text-align: center;
}
table thead tr th {
  border-bottom: 3px solid #b2d1ff;
  padding-bottom: 5px;
}
table tbody tr td {
  padding: 10px 0;
  border-bottom: 1px dotted #b2d1ff;
}
.pay {
  &-inner {
    padding-top: 20px;
  }
  &-money {
    margin-bottom: 20px;
    > span {
      color: $price-color;
      font-size: 1.57rem;
      font-weight: bold;
      margin-left: 15px;
      > span {
        font-size: 1.28rem;
      }
    }
  }
}
.table {
  &-onlyHead {
    margin-bottom: 20px;
  }
  &-good {
    border-top: 1px solid $border-color;
  }
  &-mallName {
    margin-bottom: 5px;
  }
}
</style>
