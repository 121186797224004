<template>
  <div class="deed">
    <QueryForm @toQuery="queryBtnClick" :load="loading" />
    <div class="flex flex-between bar">
      <div>
        订单数：<strong>{{ totalItem }}</strong>
      </div>
      <div>
        总金额：<strong>￥{{ totalMoney }} </strong>元
      </div>
    </div>
    <div class="tableWrapper" v-loading="loading">
      <div
        v-for="(item, index) in tableData"
        :key="index"
        class="list flex flex-row"
      >
        <div class="list-left flex-1">
          <div class="list-title first">
            <div>
              <label>委托单编号：</label>
              <span>{{ item.orderNo }}</span>
            </div>
          </div>
          <OrderBase :row="item" />
        </div>
        <div class="list-center flex flex-column">
          <div class="list-title">委托单流程&状态信息</div>
          <div class="flex-1 flex flex-middle list-flow">
            <div>
              <OrderFlow :row="item" />
              <Status :row="item" :payTypeMap="payTypeMap" />
            </div>
          </div>
        </div>
        <div class="list-right">
          <div class="list-title">操作</div>
          <Operation
            :row="item"
            @viewDeed="viewDeed(item)"
            @updatePrice="updatePrice(item)"
            @refresh="refresh"
            @viewLogistics="viewLogistics(item)"
            @addRetreatLogistics="addRetreatLogistics(item)"
            @viewRetreatLogistics="viewRetreatLogistics(item)"
            @uploadReport="uploadReportClick(item)"
          />
        </div>
      </div>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="委托单详情"
      :visible.sync="drawer"
      size="800px"
      class="drawer"
      :wrapperClosable="false"
    >
      <DeedDetail v-if="drawer" :deedId="currentRowData.checkmallOrderMainId" />
    </el-drawer>
    <!-- 废弃此订单弹窗 -->
    <DiscardDialog v-model="discardVisible" />
    <!--修改订单价格弹窗 -->
    <UpdatePriceDialog v-model="updatePriceVisible" />
    <!-- 物流弹窗-->
    <ViewLogisticsDialog
      v-model="viewLogisticsVisible"
      v-if="viewLogisticsVisible"
      :order="orderLogis"
    />
    <AddLogisticsDialog
      v-model="addLogisticsVisible"
      v-if="addLogisticsVisible"
      :deedId="currentRowData.checkmallOrderMainId"
      :order="currentRowData"
      :isRetreat="isRetreat"
      @addLogSuccess="refreshList"
    />
    <UploadReportDialog
      v-model="reportVisible"
      v-if="reportVisible"
      :data="currentRowData"
      @uploadReportSuccess="refreshList"
    />
  </div>
</template>
<script>
import { PAY_TYPE } from "@/constant/dict";
import { deedList, deedTotalMoney } from "@/api/userCenter";
import Pagination from "@/components/Pagination/index";
import OrderFlow from "@/components/OrderFlow/Deed";
import DeedDetail from "@/components/DeedDetail";
import Status from "@/components/DeedStatus/index";
import QueryForm from "./components/QueryForm";
import OrderBase from "./components/OrderBase";
import Operation from "./components/Operation";
import ViewLogisticsDialog from "@/components/Logistics/ViewLogistics";
import AddLogisticsDialog from "@/components/Logistics/AddLogistics";
import DiscardDialog from "./components/DiscardDialog";
import UpdatePriceDialog from "./components/UpdatePriceDialog";
import UploadReportDialog from "./components/UploadReportDialog";
export default {
  components: {
    Pagination,
    QueryForm,
    OrderBase,
    OrderFlow,
    Operation,
    Status,
    DeedDetail,
    DiscardDialog,
    UpdatePriceDialog,
    ViewLogisticsDialog,
    AddLogisticsDialog,
    UploadReportDialog,
  },
  data() {
    return {
      discardVisible: false,
      updatePriceVisible: false,
      totalItem: 0,
      totalMoney: 0,
      drawer: false,
      payTypeMap: {},
      page: {
        pageIndex: 1,
        pageSize: 5,
      },
      tableData: [],
      loading: false,
      queryParams: {},
      viewLogisticsVisible: false,
      orderLogis: {},
      addLogisticsVisible: false,
      currentRowData: {},
      isRetreat: false, // 是否是退样
      reportVisible: false,
    };
  },
  created() {
    this.getPayTayeDict();
    this.getDeedList();
    this.getTotalMoney();
  },
  methods: {
    // 获取总金额
    async getTotalMoney() {
      const totalMoney = await this.$axios.get(deedTotalMoney);
      this.totalMoney = totalMoney;
    },
    // 获取字典数据
    async getPayTayeDict() {
      const data = await this.$store.dispatch("getDictData", PAY_TYPE);
      const payMap = {};
      data.forEach((item) => {
        payMap[item.dictValue] =
          item.dictLabel === "未选择" ? "未选择支付方式" : item.dictLabel;
      });
      this.payTypeMap = payMap;
    },
    // 获取委托单列表数据
    async getDeedList() {
      this.loading = true;
      const { time, ...rest } = this.queryParams;
      const _params = {
        ...this.page,
        orderListType: 1, // 0：用户列表 1：实验室列表
        ...rest,
      };
      if (time && time.length) {
        Object.assign(_params, {
          beginTime: time[0],
          endTime: time[1],
        });
      }
      // orderListType:获取订单列表类型 0：用户列表 1：实验室列表
      const { list, totalItem } = await this.$axios
        .post(deedList, _params)
        .finally(() => {
          this.loading = false;
        });
      this.tableData = list;
      this.totalItem = totalItem;
    },
    // 查询按钮的点击
    queryBtnClick(params) {
      this.queryParams = params;
      this.page.pageIndex = 1;
      this.getDeedList();
    },
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getDeedList();
    },
    // 按钮状态修改后，刷新表格
    refresh() {
      this.getDeedList();
    },
    // 查看委托单
    viewDeed(data) {
      this.currentRowData = data;
      this.drawer = true;
    },
    // 废弃此订单
    // discardDeed(data) {
    //   console.log(data);

    //   this.discardVisible = true;
    // },
    // 修改订单价格
    updatePrice(data) {
      this.updatePriceVisible = true;
      console.log(data);
    },
    // 查看寄样物流信息
    viewLogistics(item) {
      const { sampleMailExpressCompany, sampleMailNumber } =
        item.checkmallOrdeListMailExpress;
      this.orderLogis = {
        orderExpressCompany: sampleMailExpressCompany,
        orderExpressNum: sampleMailNumber,
      };
      this.viewLogisticsVisible = true;
    },
    // 添加退样物流信息
    addRetreatLogistics(data) {
      this.currentRowData = data;
      this.addLogisticsVisible = true;
      this.isRetreat = true;
    },
    // 刷新列表数据
    refreshList() {
      this.getDeedList();
    },
    // 查看退样物流信息
    viewRetreatLogistics(item) {
      const { recoverMailExpressCompany, recoverMailNumber } =
        item.checkmallOrdeListMailExpress;
      this.orderLogis = {
        orderExpressCompany: recoverMailExpressCompany,
        orderExpressNum: recoverMailNumber,
      };
      this.viewLogisticsVisible = true;
    },
    // 点击上传报告按钮
    uploadReportClick(data) {
      this.currentRowData = data;
      this.reportVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.drawer {
  /deep/ .el-drawer__header span {
    font-weight: bold;
    font-size: 1.07rem;
  }
}
.deed {
  padding: 10px;
}
.tableWrapper {
  min-height: 400px;
  margin-bottom: 10px;
}
.list {
  border: 1px solid $border-color;
  margin-bottom: 10px;
  &-title {
    height: 35px;
    line-height: 35px;
    background: $head-bar-bg;
    padding-left: 10px;
    border-bottom: 1px solid $border-color;
    text-align: center;
    &.first {
      text-align: left;
    }
  }
  &-left {
    border-right: 1px solid $border-color;
  }
  &-center {
    width: 430px;
    border-right: 1px solid $border-color;
  }
  &-right {
    width: 150px;
  }
  &-time {
    font-weight: bold;
    margin-right: 15px;
  }
  &-flow {
    > div {
      width: 100%;
    }
  }
}
.bar {
  height: 35px;
  line-height: 35px;
  background: #eaf1fe;
  border: 1px solid #d6e3fd;
  padding: 0 10px;
  strong {
    color: $price-color;
  }
}
</style>
