<template>
  <div>
    <HeadBar class="headBar" /><!--顶部bar-->
    <div class="wrapper flex flex-center">
      <div class="wrapper-inner">
        <div class="wrapper-top">
          <Head
            :serve-name="serveDetail.checkmallWaresName"
            :laboratory-name="laboratory.checkmallBusinessName"
          />
          <OrderTabs
            :info="serveDetail"
            v-if="serveDetail.checkmallWaresId"
            ref="orderTab"
            @checkItemSelect="checkItemSelect"
            @sampleCountChange="sampleCountChange"
          />
        </div>
        <!--用户留言-->
        <LeaveMessage v-model="leavingMessage" />
        <!--用户地址-->
        <ReceiveAddressManege ref="address" />
        <!--样品寄送地址信息-->
        <SampleMailInfo :data="laboratory" />
        <MoneyBarFix
          @showDetail="showDetailClick"
          :tabsData="tabsData"
          :totalMoney="totalMoney"
        />
        <div class="btn">
          <el-button type="primary" @click="toConfirmOrder"
            >生成委托单</el-button
          >
        </div>
      </div>
    </div>
    <ConfirmDialog
      v-model="showDialog"
      :order="orderParams"
      :serve="serveDetail"
      :laboratory="laboratory"
      @confirmDeed="confirmDeed"
    />
    <FootBar />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  deviceDetail,
  ceateStrictMallOrder,
  labInfo,
  updateStrictOrderStatus,
} from "@/api/mall"; //
import Head from "./components/Head";
import OrderTabs from "./components/OrderTabs";
import MoneyBarFix from "./components/MoneyBarFix";
import ReceiveAddressManege from "@/components/AddressMange/index";
import SampleMailInfo from "./components/SampleMailInfo";
import ConfirmDialog from "./components/ConfirmDialog/index";
import LeaveMessage from "./components/LeaveMessage.vue";
import { DEED_ORDER_SUCCESS } from "@/constant/index";
import { buryPoint } from "@/api/common";
import { SET_DEEDPRINT } from "@/store/types";
export default {
  components: {
    ConfirmDialog,
    Head,
    OrderTabs,
    MoneyBarFix,
    ReceiveAddressManege,
    SampleMailInfo,
    LeaveMessage,
  },
  data() {
    return {
      showDialog: false,
      serveDetail: {},
      laboratory: {},
      tabsData: [],
      totalMoney: 0,
      orderParams: {},
      leavingMessage: "", // 用户留言
      routeFrom: {},
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = { fullPath: from.fullPath, name: from.name };
    });
  },
  async created() {
    await this.getDeviceDetail(); // 获取服务信息
    this.getBusinessInfo(); // 获取服务方信息
  },
  beforeDestroy() {},
  methods: {
    // 获取服务信息
    async getDeviceDetail() {
      const data = await this.$axios.get(deviceDetail, {
        id: this.$route.query.id,
      });
      this.serveDetail = data;
    },
    // 获取服务方信息
    async getBusinessInfo() {
      const data = await this.$axios.get(labInfo, {
        businessId: this.serveDetail.checkmallBusinessId,
      });
      this.laboratory = data;
    },
    // 生产委托单
    async toConfirmOrder() {
      const validCheckItem = this.validTabs();
      if (!validCheckItem) return;
      const rareCheckmallOrderSamplesEx = this.getTabsData(); // 获取样品数据
      this.showDialog = true;
      const userAddressId = this.getAddressData(); // 获取下单地址等数据
      if (!userAddressId) {
        this.$message.wraning("请选择或新增收货地址");
        return;
      }
      const { checkmallWaresId, checkmallWaresName, checkmallBusinessId } =
        this.serveDetail;
      const params = {
        // access_token: "9090",
        checkReportDeliveryType: 1, // 检测报告交付方式
        checkmallWaresId, //检测商城商品ID
        checkmallWaresName, //检测商城商品名称
        checkmallBusinessId, // 商城对应实验室id
        leavingMessage: this.leavingMessage, // 用户订单留言
        userAddressId,
        orderPrice: this.totalMoney, // 订单金额（单位分，修改后的最新价格）
        subcontractType: 0, // 是否接受分包（数据字典）（0-不接受；1-接受）
        userId: this.userBaseInfo.userId,
        rareCheckmallOrderSamplesEx,
      };
      this.orderParams = params;
      this.$store.commit(SET_DEEDPRINT, JSON.stringify(params));
    },
    async confirmDeed() {
      const orderId = await this.$axios.post(
        ceateStrictMallOrder,
        this.orderParams
      );
      if (orderId) {
        await this.$axios.post(updateStrictOrderStatus, {
          checkmallOrderMainId: orderId,
          orderCheckStatus: DEED_ORDER_SUCCESS,
        });
        this.$message.success("下单成功");
        // 埋点
        this.$axios.post(buryPoint, {
          eventId: "W-F-5-E1",
          eventName: "确认(生成)委托单",
          module: "检测服务",
          currentUrl: this.$route.fullPath,
          currentUrlAlias: this.$route.name,
          referrerUrl: this.routeFrom.fullPath,
          referrerUrlAlias: this.routeFrom.name,
          userId: this.userBaseInfo.userId,
        });
        this.$router.push({ name: "orderPay" });
      }
    },
    // 获取样品数据
    getTabsData() {
      const rareCheckmallOrderSamplesEx = [];
      const { forms, checkItem } = this.$refs.orderTab.getParamsData();
      const { serviceProject } = this.serveDetail;
      forms.forEach((item, index) => {
        const tabItemIds = Object.keys(checkItem[index]);
        const firstRank = [];
        tabItemIds.forEach((itemId) => {
          const { checkmallFirstRankName } = serviceProject.find((project) => {
            return project.checkmallFirstRankId === +itemId;
          });
          const params = {
            checkmallFirstRankId: itemId, // 检测项目
            checkmallFirstRankName,
          };
          // 选择的检测项目中，子项目勾选了才记录数据
          const itemChild = checkItem[index][itemId];
          if (itemChild && itemChild.length) {
            params.rareCheckmallFirstSecondRanks = itemChild;
            firstRank.push(params);
          }
        });
        const data = {
          rareCheckmallSampleFirstRankExs: firstRank,
          ...item,
        };
        rareCheckmallOrderSamplesEx.push(data);
      });
      return rareCheckmallOrderSamplesEx;
    },
    validTabs() {
      const { forms, checkItem } = this.$refs.orderTab.getParamsData();
      // 校验checkItem
      let validCheckItem = true;
      for (let i = 0; i < forms.length; i++) {
        const form = forms[i];
        if (!form.sampleName) {
          validCheckItem = false;
          this.$message.error(
            `样品组${String.fromCharCode(i + 65)}样品名称不能为空`
          );
          break;
        }
        const item = checkItem[i];
        if (!item || !Object.keys(item).length) {
          validCheckItem = false;
          this.$message.error(
            `样品组${String.fromCharCode(i + 65)}检测项目不能为空`
          );
          break;
        }
        const tabItemIds = Object.keys(item);
        const nullItem = tabItemIds.find((id) => item[id].length === 0);
        if (nullItem) {
          validCheckItem = false;
          this.$message.error(
            `请勾选样品组${String.fromCharCode(i + 65)}的检测子项目`
          );
          break;
        }
      }
      return validCheckItem;
    },
    computedTotalMoney() {
      const { forms, checkItem } = this.$refs.orderTab.getParamsData();
      let orderPrice = 0;
      forms.forEach((item, index) => {
        const tabItemIds = Object.keys(checkItem[index]);
        tabItemIds.forEach((itemId) => {
          // 选择的检测项目中，子项目勾选了才记录数据
          const itemChild = checkItem[index][itemId];
          if (itemChild && itemChild.length) {
            // 计算金额
            itemChild.forEach((child) => {
              const p = Number(child.price) * Number(item.sampleQuantity);
              orderPrice += p;
            });
          }
        });
      });
      this.totalMoney = orderPrice;
    },
    // 获取下单地址等数据
    getAddressData() {
      return this.$refs.address.selectAddressId;
    },
    // 费用明细按钮
    showDetailClick() {
      const rareCheckmallOrderSamplesEx = this.getTabsData();
      this.tabsData = rareCheckmallOrderSamplesEx;
    },
    checkItemSelect() {
      this.computedTotalMoney();
    },
    sampleCountChange() {
      this.computedTotalMoney();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.headBar {
  margin-bottom: 30px;
}
.wrapper {
  &-inner {
    width: $max-width;
    // position: relative;
  }
  &-top {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    margin-bottom: 20px;
  }
}
.btn {
  padding-top: 20px;
}
</style>
