<template>
  <div>
    <HeadBar /><!--顶部bar-->

    <HeadNav /><!-- 顶部导航-->
    <div class="act flex flex-center">
      <div class="act-inner">
        <div class="act-path">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'allianceList' }"
              >联盟专区</el-breadcrumb-item
            >
            <el-breadcrumb-item
              :to="{
                name: 'allianceDetail',
                query: { id: $route.query.detailId },
              }"
              >联盟专区详情</el-breadcrumb-item
            >
            <el-breadcrumb-item>联盟专区活动</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-row :gutter="20">
          <el-col :span="17">
            <div class="act-article" v-loading="loading">
              <h2 class="act-title">{{ detail.activityTitle }}</h2>
              <div class="act-time">
                {{ detail.createTime | transformTime }}
              </div>
              <EditorView :data="detail.activityContent" class="act-desc" />
            </div>
          </el-col>
          <el-col :span="7">
            <div class="new-title">最新活动</div>
            <div class="new-block">
              <div
                v-for="(item, index) in newActs"
                :key="index"
                class="new-list flex flex-row"
                @click="newActClick(item)"
              >
                <template v-if="index === 0">
                  <div class="new-cover"><img :src="item.activityImage" /></div>
                  <div class="flex-1 new-right flex flex-column flex-between">
                    <div class="new-name">{{ item.activityTitle }}</div>
                    <div class="new-time">
                      发布时间：{{ item.createTime | transformTime }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div><div class="new-point"></div></div>
                  <div>
                    <div class="new-name margin flex-1">
                      {{ item.activityTitle }}
                    </div>
                    <div class="new-time">
                      发布时间：{{ item.createTime | transformTime }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { parseTime } from "@/utils/index";
import { allianceActDetail, allianceActsList } from "@/api/allianceZone";
export default {
  filters: {
    transformTime(val) {
      return parseTime(val, "{y}-{m}-{d} {h}:{i}");
    },
  },
  data() {
    return {
      detail: {},
      newActs: [],
      loading: false,
      actId: null,
    };
  },
  created() {
    this.actId = this.$route.query.id;
    this.getActDetail();
    this.getNewActs();
  },
  methods: {
    async getActDetail() {
      this.loading = true;
      this.detail = await this.$axios
        .get(allianceActDetail, {
          leagueActivityId: this.actId,
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getNewActs() {
      const { list } = await this.$axios.get(allianceActsList, {
        pageIndex: 1,
        pageSize: 6,
      });

      this.newActs = list.filter(({ leagueActivityId }) => {
        return leagueActivityId !== +this.actId;
      });
    },
    newActClick(data) {
      this.$jump.push({
        name: "allianceActDetail",
        query: {
          id: data.leagueActivityId,
          detailId: this.$route.query.detailId,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.act {
  padding-top: 30px;
  &-inner {
    width: $max-width;
    min-height: 500px;
  }
  &-path {
    margin-bottom: 30px;
  }
  &-article {
    min-height: 300px;
  }
  &-title {
    text-align: center;
    margin-bottom: 10px;
  }
  &-time {
    text-align: center;
    margin-bottom: 10px;
    color: $font-color-gray;
    font-size: 1.14rem;
  }
}
.new {
  &-title {
    font-size: 18px;
    font-weight: 600;
    color: $theme-color;
    margin-bottom: 15px;
  }
  &-block {
    background: #f7f8f9;
    border-radius: 5px;
    padding: 10px;
  }
  &-list {
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  &-cover {
    width: 100px;
    height: 65px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-right {
    padding-left: 10px;
  }
  &-time {
    color: $font-color-gray;
    font-size: 0.85rem;
  }
  &-name {
    &.margin {
      margin-bottom: 12px;
    }
  }
  &-point {
    width: 5px;
    height: 5px;
    background: $theme-color;
    border-radius: 50%;
    float: left;
    margin-top: 7px;
    margin-right: 5px;
  }
}
</style>
