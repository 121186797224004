// 在线培训课程类别
export const trainCate = `/api/unoauth/course/getListRareOnlineTrainType`;

// 获取在线培训课程列表
export const trainList = `/api/unoauth/course/listOnlineTrainCourse`;

// 课程详情
export const trainDetail = `/api/unoauth/course/onlineTrainCourseInfo`;

// 新增在线培训订单
export const createOrder = `/api/unoauth/course/insertCourseOrder`;
