<template>
  <div
    class="good pointer"
    :class="{ isRight: index % 4 == 0 }"
    @click="toDetail"
  >
    <div class="good-cover">
      <img :src="info.imgsrc" v-if="info.imgsrc" />
      <img v-else :src="$oss('common/default_device.png')" />
    </div>
    <div class="good-deviceName ellipsis">{{ info.devicename }}</div>
    <div class="good-busi ellipsis" v-if="info.checkmallBusinessName">
      <i class="el-icon-s-shop"></i>
      <span>{{ info.checkmallBusinessName }}</span>
    </div>
    <div class="flex flex-row flex-between good-brand">
      <div class="good-model ellipsis">
        {{ info.brand }}<span v-if="info.model">({{ info.model }})</span>
      </div>
      <div>{{ info.city }}</div>
    </div>
    <div class="good-price">
      <span class="good-prePrice" v-if="info.discount !== '10'">
        <span v-if="!info.preferentialPrice || info.face">面议</span>
        <span v-else
          >￥{{ info.preferentialPrice }}/{{ unitMap[+info.unit] }}</span
        >
      </span>
      <span
        class="good-oriPrice"
        v-if="info.discount"
        :class="{ noDis: info.discount == '10' }"
      >
        <span v-if="!info.minPrince || info.face">面议</span>
        <span v-else>￥{{ info.minPrince }}/{{ unitMap[+info.unit] }}</span>
      </span>
    </div>
    <div class="good-btn">
      <span @click.stop="toPay" class="pointer">在线下单</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    index: {
      type: Number,
      default: null,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    unitMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    toDetail() {
      this.$jump.push({ name: "deviceDetail", query: { id: this.info.id } });
    },
    toPay() {
      this.toDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.good {
  width: 290px;
  background: #ffffff;
  border: 1px solid #f4eeee;
  margin-right: 20px;
  margin-bottom: 20px;
  &.isRight {
    margin-right: 0;
  }
  &-cover {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    padding-top: 32px;
    img {
      width: 214px;
      height: 177px;
      object-fit: cover;
    }
  }
  &-deviceName {
    padding: 0 33px;
    font-size: 18px;
    font-weight: bold;
    color: #202020;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
  &-busi {
    padding: 0 33px;
    font-size: 16px;
    color: #202020;
    margin-bottom: 8px;
    text-align: center;
    width: 100%;
    i {
      color: #4483ff;
      font-size: 21px;
      position: relative;
      top: 2px;
      margin-right: 4px;
    }
  }
  &-brand {
    padding: 0 33px;
    color: #808080;
    margin-bottom: 4px;
  }
  &-model {
    max-width: 150px;
  }
  &-price {
    padding: 0 33px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 18px;
  }
  &-prePrice,
  .noDis {
    color: #e67009;
    text-decoration: none;
  }
  &-oriPrice {
    margin-left: 20px;
    color: #808080;
    text-decoration: line-through;
  }
  &-btn {
    text-align: center;
    margin-bottom: 30px;
    span {
      display: inline-block;
      padding: 8px 27px;
      color: white;
      background: #4483ff;
      border-radius: 2px;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
