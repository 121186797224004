<template>
  <el-dialog title="用户评价" :visible.sync="visible" width="30%" class="dia">
    <div>
      <div class="dia-rate flex flex-row">
        <div class="label">评分</div>
        <el-rate v-model="form.orderEvaluateScore" show-text :texts="texts">
        </el-rate>
      </div>
      <div>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="form.orderEvaluateContent"
          resize="none"
          :maxlength="200"
        >
        </el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('input', false)">取 消</el-button>
      <el-button type="primary" @click="confirmClick">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getOrderEvaluate, addOrEditEvaluate } from "@/api/userCenter";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    // 商城订单类别（数据字典）（0-检测商城；1-商品商城）
    mallOrderType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 好评（4-5星）、中评（2-3星）、差评（1星）
      texts: ["差评", "中评", "中评", "好评", "好评"],
      form: {
        orderEvaluateScore: null,
        orderEvaluateContent: "",
        orderId: null,
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.form.orderId =
      this.data[
        this.mallOrderType === "0"
          ? "checkmallOrderMainId"
          : "productmallOrderRankId"
      ];
    this.getCurrentEvaluate();
  },
  methods: {
    async getCurrentEvaluate() {
      const res = await this.$axios.post(getOrderEvaluate, {
        mallOrderType: this.mallOrderType,
        orderId: this.form.orderId,
      });
      if (res) {
        Object.assign(this.form, res);
      }
    },
    async confirmClick() {
      const params = {
        mallOrderType: this.mallOrderType,
        ...this.form,
      };
      await this.$axios.post(addOrEditEvaluate, params);
      this.$message.success("评论成功,请等待审核");
      this.$emit("input", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.dia {
  /deep/ .el-dialog__body {
    padding: 10px 20px;
  }
  &-rate {
    margin-bottom: 12px;
    .label {
      margin-right: 10px;
    }
  }
}
</style>
