<template>
  <div class="btns">
    <!--未付款且不是协议支付-->
    <div v-if="showPay">
      <el-button size="mini" type="danger" plain @click="toPay(row)"
        >去付款</el-button
      >
    </div>
    <!--已下单，支付方式为协议支付或者已付款-->
    <template v-if="showEditMailInfo">
      <div>
        <el-button
          size="mini"
          type="danger"
          plain
          @click="$emit('addLogistics')"
          >填写样品快递信息</el-button
        >
      </div>
      <div>
        <el-button
          size="mini"
          type="primary"
          plain
          @click="$emit('viewLogistics')"
          >查看寄样快递信息</el-button
        >
      </div>
    </template>
    <div v-if="showComfirm">
      <el-button
        size="mini"
        type="danger"
        plain
        @click="comfirmBtnClick"
        :loading="confirmLoading"
        >确认完成</el-button
      >
    </div>
    <div v-if="showResult">
      <el-button size="mini" type="success" plain @click="showResultBtnClick"
        >查看结果</el-button
      >
    </div>
    <div>
      <el-button size="mini" type="primary" plain @click="viewDeed"
        >查看委托单</el-button
      >
    </div>
    <div>
      <el-button size="mini" type="primary" plain @click="askForBtn"
        >申请申诉</el-button
      >
      <!-- <el-button size="mini" type="primary" plain>查看申述结果</el-button> -->
    </div>
    <div>
      <el-button size="mini" type="primary" plain @click="$emit('askForResult')"
        >查看申述结果</el-button
      >
    </div>
    <div v-if="showEvaluate">
      <el-button size="mini" type="primary" plain @click="$emit('evaluate')"
        >去评价</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  DEED_ORDER_SUCCESS,
  DEED_ORDER_FINISH,
  // DEED_ORDER_WAITSAMPLE,
  DEED_ORDER_REPORT,
  PAYTYPE_XY,
  PAYSTATUS_NO,
  PAYSTATUS_SUCCESS,
} from "@/constant/index";
import { updateStrictOrderStatus } from "@/api/mall";
// import { toAskFor } from "@/api/userCenter";
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      confirmLoading: false,
    };
  },
  computed: {
    // 去付款
    showPay() {
      const { orderPayStatus, orderPayType } = this.row;
      return orderPayStatus === PAYSTATUS_NO && orderPayType !== PAYTYPE_XY;
    },
    // 填写样品快递信息
    showEditMailInfo() {
      const { orderCheckStatus, orderPayType, orderPayStatus } = this.row;
      const success = orderCheckStatus === DEED_ORDER_SUCCESS;
      const pay =
        orderPayType === PAYTYPE_XY || orderPayStatus === PAYSTATUS_SUCCESS;
      return success && pay;
    },
    // 确认完成
    showComfirm() {
      const { orderCheckStatus } = this.row;
      return DEED_ORDER_REPORT === orderCheckStatus;
    },
    // 查看结果、
    showResult() {
      const { orderCheckStatus } = this.row;
      return DEED_ORDER_FINISH === orderCheckStatus;
    },
    showEvaluate() {
      const { orderCheckStatus } = this.row;
      return DEED_ORDER_FINISH === orderCheckStatus;
    },
  },
  methods: {
    // 去付款
    viewDeed() {
      this.$emit("viewDeed");
    },
    // 去支付
    async toPay() {
      try {
        this.$router.push({ name: "orderPay" });
      } catch (e) {
        this.$message.error(e && e.msg);
      }
    },
    // 确认完成
    async comfirmBtnClick() {
      this.confirmLoading = true;
      await this.changeStatus(DEED_ORDER_FINISH, "已确认完成");
      this.confirmLoading = false;
    },
    async changeStatus(status, tip) {
      try {
        await this.$axios.post(updateStrictOrderStatus, {
          checkmallOrderMainId: this.row.checkmallOrderMainId,
          orderCheckStatus: status,
        });
        this.$message.success(tip);
        this.$emit("refresh");
      } catch (e) {
        this.$message.error(e && e.msg);
      }
    },
    // 查看结果
    showResultBtnClick() {
      this.$emit("showResult");
    },
    // 申请申述
    askForBtn() {
      this.$emit("askFor");
    },
  },
};
</script>
<style lang="scss" scoped>
.btns {
  padding-top: 10px;
  > div {
    margin-bottom: 10px;
    text-align: center;
  }
  /deep/ .el-button {
    width: 130px;
  }
}
</style>
