<!-- 我申请的专家评估-->
<template>
  <div class="apply">
    <el-form :inline="true" :model="form" class="qForm" :size="queryFormSize">
      <el-form-item label="评估状态">
        <el-select
          v-model="form.reviewApplyStatus"
          placeholder="请选择分类"
          clearable
        >
          <el-option
            :label="item.dictLabel"
            :value="item.dictValue"
            v-for="(item, index) in applyStatusOption"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
          v-model="form.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryBtn" :loading="loading"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="apply-table">
      <el-table :data="lists" border v-loading="loading">
        <el-table-column prop="expertName" label="专家名称"> </el-table-column>
        <el-table-column prop="userName" label="申请评估人名称">
        </el-table-column>
        <el-table-column label="评估状态">
          <template slot-scope="{ row }">
            {{ statusMap[+row.reviewApplyStatus] }}
          </template>
        </el-table-column>
        <el-table-column label="申请时间">
          <template slot-scope="{ row }">
            <div>{{ row.createTime | transformTime }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" @click="viewBtn(row)" size="mini"
              >查看</el-button
            >
            <el-button
              type="danger"
              @click="closeBtn(row)"
              size="mini"
              v-if="row.reviewApplyStatus === statusOpen"
              >关闭</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <DetailDialog
      v-model="showDrawer"
      :data="selectData"
      v-if="showDrawer"
      :isExpert="true"
    />
    <CloseDialog
      :data="selectData"
      v-model="showCloseDialog"
      v-if="showCloseDialog"
      @refresh="refresh"
    />
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
  </div>
</template>
<script>
import { parseTime } from "@/utils/index";
import { QUERY_FORM_SIZE } from "@/constant/size";
import Pagination from "@/components/Pagination/index";
import DetailDialog from "@/components/EstimateDetail/index";
import CloseDialog from "./components/CloseDialog.vue";
import { myEstList } from "@/api/expert";
import { EST_APPLY_STATUS, EST_APPLY_STATUS_OPEN } from "@/constant/dict";
export default {
  components: {
    Pagination,
    DetailDialog,
    CloseDialog,
  },
  filters: {
    transformTime(val) {
      return parseTime(val);
    },
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      showDrawer: false,
      showCloseDialog: false,
      form: {
        reviewApplyStatus: null,
        time: [],
      },
      totalItem: 0,
      lists: [],
      selectData: {},
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      applyStatusOption: [],
      statusMap: {},
      statusOpen: EST_APPLY_STATUS_OPEN, // 评审中
      loading: false,
    };
  },
  created() {
    this.getStatus();
    this.getList();
  },
  methods: {
    async getStatus() {
      const data = await this.$store.dispatch("getDictData", EST_APPLY_STATUS);
      this.applyStatusOption = data;
      const statusMap = {};
      data.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });

      this.statusMap = statusMap;
    },
    refresh() {
      this.page.pageIndex = 1;
      this.getList();
    },
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    async getList() {
      this.loading = true;
      const { reviewApplyStatus, time } = this.form;
      const params = {
        ...this.page,
        checkUserType: "PE", // 查询用户类别（PU-平台用户；PE-专家）
        reviewApplyStatus,
      };
      if (time && time.length) {
        Object.assign(params, {
          startReviewDate: time[0],
          endReviewDate: time[1],
        });
      }
      const { totalItem, list } = await this.$axios
        .post(myEstList, params)
        .finally(() => {
          this.loading = false;
        });
      this.lists = list;
      this.totalItem = totalItem;
    },
    queryBtn() {
      this.page.pageIndex = 1;
      this.getList();
    },
    viewBtn(data) {
      this.selectData = data;
      this.showDrawer = true;
    },
    async closeBtn(data) {
      this.selectData = data;
      this.showCloseDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.apply {
  padding: 10px;
  &-table {
    min-height: 450px;
    margin-bottom: 10px;
  }
}
.qForm {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
}
</style>
