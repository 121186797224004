<template>
  <div class="bread">
    <div class="bread-inner flex flex-row flex-between">
      <div class="bread-title">{{ title }}</div>
      <div class="flex flex-row bread-crumb">
        <div>当前您所在位置：</div>
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'baseIntroduction' }"
            >中心概况</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.bread {
  padding: 0 62px;
  &-inner {
    height: 68px;
    border-bottom: 1px solid #ebebeb;
    width: 100%;
  }
  &-title {
    font-size: 22px;
    font-weight: bold;
    color: #222222;
    padding-top: 28px;
  }
  &-crumb {
    margin-top: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    height: 18px;
    line-height: 18px;
  }
}
.breadcrumb {
  line-height: 18px;
  font-size: 16px;
}
</style>
