<template>
  <div class="lab">
    <div class="list">
      <label>实验室名称：</label><span>{{ data.checkmallBusinessName }}</span>
    </div>
    <div class="list">
      <label>实验室地址：</label>
      <span>{{ data.laboratoryLocationProvince }}</span>
      <span>{{ data.laboratoryLocationCity }}</span>
      <span>{{ data.laboratoryLocationCounty }}</span>
      <span>{{ data.laboratoryAddress }}</span>
    </div>
    <div class="list">
      <label>商城联系人：</label>
      <span>{{ data.checkmallBusinessContactsName }}</span>
      <label class="list-tel">商城联系人电话：</label>
      <span>{{ data.checkmallBusinessContactsPhone }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.list {
  margin-bottom: 8px;
  > label {
    color: #909399;
  }
  &-tel {
    margin-left: 20px;
  }
}
</style>
