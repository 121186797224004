<template>
  <div>
    <el-table
      :data="data"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40" align="center">
      </el-table-column>
      <el-table-column prop="orderNo" label="订单编号"> </el-table-column>
      <el-table-column prop="price" label="订单金额" width="100px">
      </el-table-column>
      <el-table-column prop="deedNo" label="委托单号"> </el-table-column>
      <el-table-column prop="labName" label="实验室名称" min-width="150px">
      </el-table-column>
      <el-table-column prop="type" label="订单类别" width="100px">
      </el-table-column>
      <el-table-column prop="time" label="支付时间" width="100px">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    money: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    // 出来表格多选
    handleSelectionChange(selection) {
      let money = 0;
      selection.forEach((item) => {
        money += +item.price;
      });
      this.$emit("update:selection", selection);
      this.$emit("update:money", money);
    },
  },
};
</script>
