<template>
  <el-form :model="form" :rules="rules" ref="ruleForm" label-width="150px">
    <el-form-item label="发票性质：">
      <el-select v-model="form.openingType" placeholder="请选择发票性质">
        <el-option
          v-for="item in billOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="发票寄送地址：">
      <el-select
        v-model="form.userAddressId"
        placeholder="请选择发票寄送地址"
        class="address"
      >
        <el-option
          v-for="item in address"
          :key="item.id"
          :label="item.address"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span class="editAddress" @click="addOrUpdateAddress">新增/修改地址</span>
    </el-form-item>
    <el-form-item label="发票抬头(企业名称)：">
      <el-input v-model="form.invoiceTitle" placeholder="请输入内容"></el-input>
    </el-form-item>
    <el-form-item label="纳税人识别号：">
      <el-input
        v-model="form.taxCertificate"
        placeholder="请输入纳税人识别号"
      ></el-input>
    </el-form-item>
    <el-form-item label="开户银行：">
      <el-input
        v-model="form.accountBankName"
        placeholder="请输入开户银行"
      ></el-input>
    </el-form-item>
    <el-form-item label="开户银行账号：">
      <el-input
        v-model="form.accountBankNum"
        placeholder="请输入开户银行账号"
      ></el-input>
    </el-form-item>
    <el-form-item label="企业地址：">
      <el-input
        v-model="form.registerCompany"
        placeholder="请输入企业地址"
      ></el-input>
    </el-form-item>
    <el-form-item label="企业电话：">
      <el-input
        v-model="form.registerPhone"
        placeholder="请输入企业电话"
      ></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import { addressList } from "@/api/userCenter";
export default {
  data() {
    return {
      form: {
        openingType: null,
        invoiceTitle: "",
        taxCertificate: "",
        accountBankName: "",
        accountBankNum: "",
        registerCompany: "",
        registerPhone: "",
      },
      rules: {},
      address: [],
      billOptions: [{ id: 1, name: "纸质" }],
    };
  },
  created() {
    this.getAddressList();
  },
  methods: {
    async getAddressList() {
      const data = await this.$axios.get(addressList);
      this.address = data.map((item) => {
        const link = `${item.linkMan}(${item.linkPhone}) `;
        const addressDetail = `${item.addressProvince}${item.addressCity}${item.addressDistrict}${item.addressDetailed}`;
        return {
          address: `${link}${addressDetail}`,
          id: item.userAddressId,
        };
      });
    },
    addOrUpdateAddress() {
      this.$router.push({ name: "addressManage" });
    },
  },
};
</script>
<style lang="scss" scoped>
.address {
  width: 400px;
}
.editAddress {
  margin-left: 10px;
  cursor: pointer;
  color: #3073f4;
}
</style>
