<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="zone flex flex-center">
      <div class="zone-inner">
        <div class="zone-path">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'allianceList' }"
              >联盟专区</el-breadcrumb-item
            >
            <el-breadcrumb-item>联盟专区详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <DetailBrief :data="detail" /><!-- 联盟信息简介-->
        <Notice :data="detail.leagueNotices" />
        <DetailTabs :data="detail" /><!-- 联盟信息-->
        <Activities /><!-- 联盟活动-->
        <Specialist /><!-- 联盟专家-->
        <Members /><!-- 联盟成员-->
        <Download /><!-- 联盟资料下载-->
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { allianceDetail } from "@/api/allianceZone";
import DetailBrief from "./components/DetailBrief";
import DetailTabs from "./components/DetailTabs";
import Activities from "./components/Activities";
import Specialist from "./components/Specialist";
import Members from "./components/Members";
import Download from "./components/Download";
import Notice from "./components/Notice";
export default {
  components: {
    DetailBrief,
    DetailTabs,
    Activities,
    Specialist,
    Members,
    Download,
    Notice,
  },
  data() {
    return {
      detail: {},
      leagueId: null,
    };
  },
  created() {
    this.leagueId = this.$route.query.id;
    this.getAllianceDetail();
  },
  methods: {
    async getAllianceDetail() {
      const data = await this.$axios.get(allianceDetail, {
        leagueId: this.leagueId,
      });
      this.detail = data;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.zone {
  padding-top: 30px;
  &-inner {
    width: $max-width;
    min-height: 500px;
  }
  &-path {
    margin-bottom: 30px;
  }
}
</style>
