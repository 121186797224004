<template>
  <div class="flex flex-row flex-wrap">
    <CourseItem
      :class="{ isRight: (index + 1) % 4 === 0 }"
      class="cor"
      v-for="(course, index) in data"
      :key="index"
      :data="course"
    />
  </div>
</template>
<script>
import CourseItem from "@/components/CourseItem/index.vue";
export default {
  components: {
    CourseItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.cor {
  margin-right: 20px;
  margin-bottom: 20px;
}
.isRight {
  margin-right: 0px;
}
</style>
