<template>
  <div class="result">
    <el-collapse v-model="activeNames">
      <el-collapse-item title="样品信息" name="1">
        <Sample :data="detail.rareCheckmallOrderSamplesEx" />
      </el-collapse-item>
      <el-collapse-item title="检测结果" name="2"> </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { deedDetail } from "@/api/userCenter";
import Sample from "./Sample";
export default {
  components: {
    Sample,
  },
  props: {
    deedId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      activeNames: ["1", "2"],
      detail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.detailLoading = true;
      const data = await this.$axios
        .get(deedDetail, {
          orderId: this.deedId,
        })
        .finally(() => {
          this.detailLoading = false;
        });
      this.detail = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.result {
  padding: 0 15px;
}
</style>
