<template>
  <div class="lab flex flex-row">
    <div class="lab-title"><img src="@/assets/home/mall/lab-title.jpg" /></div>
    <div class="lab-imgs flex flex-row flex-1">
      <div class="lab-img pointer" v-for="(item, index) in data" :key="index">
        <img :src="item.checkmallBusinessLogo" />
      </div>
    </div>
  </div>
</template>
<script>
import { carefullyChosenLabs } from "@/api/home";
export default {
  props: {
    cateId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.getCarefullyChosenLabs();
  },
  methods: {
    async getCarefullyChosenLabs() {
      const res = await this.$axios.post(carefullyChosenLabs, {
        limit: 5,
        serviceCategorySuperId: this.cateId,
      });
      this.data = res || [];
    },
  },
};
</script>
<style lang="scss" scoped>
.lab {
  margin: 0 14px 13px 14px;
  height: 65px;
  line-height: 65px;
  border-bottom: 2px solid #f0f0f8;

  &-title {
    width: 127px;
    height: 28px;
    // margin-right: 52px;
  }
  &-img {
    width: 164px;
    height: 100%;
    text-align: center;
    &:hover {
      background: #edf3ff;
    }
    > img {
      width: 80%;
      height: 60%;
      object-fit: contain;
    }
  }
}
</style>
