<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="detail">
      <div class="detail-inner">
        <div class="detail-path flex flex-row">
          <div class="detail-pathTitle">我的位置：</div>
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb"
          >
            <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'capabilityHall' }"
              >能力大厅</el-breadcrumb-item
            >
            <el-breadcrumb-item>正文</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="flex flex-row">
          <div class="ability flex-1" v-loading="detailLoading">
            <AbilityBrief :data="detail" />
            <div class="ability-block">
              <div class="ability-title">参考标准</div>
              <div>{{ detail.checkReferenceStandard || "无" }}</div>
            </div>
            <div class="ability-block">
              <div class="ability-title">检验依据</div>
              <div>{{ detail.checkAccord }}</div>
            </div>
            <div class="ability-block">
              <div class="ability-title">检验方法</div>
              <div>{{ detail.checkMethod }}</div>
            </div>
            <div class="ability-block">
              <div class="ability-title">检验指标</div>
              <div>{{ detail.checkSpeQuota }}</div>
            </div>
            <div class="ability-block">
              <div class="ability-title">检验描述</div>
              <div>{{ detail.checkDesc }}</div>
            </div>
            <div class="ability-block">
              <div class="ability-title">案例描述</div>
              <div>{{ detail.checkCaseDescribe }}</div>
            </div>
          </div>
          <div class="lab">
            <RecommendLab />
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import AbilityBrief from "./components/AbilityBrief.vue";
import RecommendLab from "./components/RecommendLab";
import { abilityDetail } from "@/api/hall";
export default {
  components: {
    AbilityBrief,
    RecommendLab,
  },
  data() {
    return {
      detail: {},
      detailLoading: false,
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.detailLoading = true;
      const res = await this.$axios
        .get(abilityDetail, {
          abilityId: this.$route.query.id,
        })
        .finally(() => {
          this.detailLoading = false;
        });
      this.detail = res;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.detail {
  min-height: 550px;
  padding-bottom: 30px;
  background: $home-bg;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-path {
    height: 50px;
    line-height: 50px;
    color: #666666;
  }
}
.breadcrumb {
  line-height: 50px;
}
.footer {
  padding-top: 0;
}
.ability {
  background: white;
  padding: 18px;
  &-block {
    margin-bottom: 30px;
  }
  &-title {
    font-size: 15px;
    font-weight: bold;
    color: #202224;
    margin-bottom: 10px;
  }
}
.lab {
  width: 250px;
  margin-left: 14px;
}
</style>
