<template>
  <div class="hall">
    <div class="hall-inner">
      <BlockHeader title="能力大厅" />
      <div class="hall-con flex flex-row flex-wrap">
        <el-popover
          v-for="(item, index) in halls"
          :key="index"
          placement="right"
          :title="item.laboratoryAbilityTypeName"
          width="400"
          trigger="hover"
          @show="popoverShow(index)"
        >
          <div class="flex flex-row flex-wrap items">
            <div
              v-for="(child, cIndex) in childAbility[index]"
              :key="cIndex"
              class="items-item pointer"
              @click="abilityChildClick(item)"
            >
              {{ child.laboratoryAbilityTypeName }}
            </div>
          </div>
          <div class="hall-item" slot="reference">
            <div class="hall-icon">
              <div>
                <img :src="item.laboratoryAbilityTypeIco" />
              </div>
            </div>
            <div class="hall-name">{{ item.laboratoryAbilityTypeName }}</div>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script>
import BlockHeader from "../BlockHeader.vue";
import { abilityType } from "@/api/hall";
export default {
  name: "modular_ability",
  components: { BlockHeader },
  data() {
    return {
      halls: [],
      childAbility: {},
    };
  },
  created() {
    this.getType();
  },
  methods: {
    async getType(pid = 0) {
      const data = await this.$axios.get(abilityType, { pid });
      if (!pid) this.halls = data;
      return data;
    },
    async popoverShow(index) {
      const data = this.halls[index];
      if (!this.childAbility[index]) {
        const child = await this.getType(data.laboratoryAbilityTypeId);
        this.$set(this.childAbility, index, child);
      }
    },
    abilityChildClick(parent) {
      this.$jump.push({
        name: "capabilityHall",
        query: { pId: parent.laboratoryAbilityTypeId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "../../common.scss";
.items {
  &-item {
    margin-right: 10px;
    margin-bottom: 5px;
    &:hover {
      color: #006fdb;
    }
  }
}
.hall {
  &-inner {
    width: $max-width;
    margin: 0 auto;
    padding-top: 20px;
  }
  &-con {
    padding-top: 20px;
    text-align: center;
  }
  &-item {
    width: 110px;
    height: 120px;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
    &:hover {
      color: #006fdb;
      .hall-icon {
        img {
          transform: rotate(360deg);
        }
      }
    }
  }
  &-icon {
    margin-bottom: 20px;
    text-align: center;
    > div {
      width: 80px;
      height: 80px;
      display: inline-block;
      border: 1px dotted #000;
      border-radius: 50%;
      img {
        width: 45px;
        height: 45px;
        margin-top: 18px;
        transition: all 0.4s linear;
      }
    }
  }
  &-name {
    text-align: center;
  }
}
</style>
