<template>
  <div>
    <div v-if="leftAd && leftAd.advertisementImagePath" class="leftGGgg side">
      <i class="el-icon-close pointer" @click="leftAd = null"></i>
      <img :src="leftAd.advertisementImagePath" @click="adClick(leftAd)" />
    </div>
    <div
      v-if="rightAd && rightAd.advertisementImagePath"
      class="rightGGgg side"
    >
      <i class="el-icon-close pointer" @click="rightAd = null"></i>
      <img :src="rightAd.advertisementImagePath" @click="adClick(rightAd)" />
    </div>
    <div v-if="bottomAd && bottomAd.advertisementImagePath">
      <div class="bottomGGgg" v-show="bottomAd.show" :class="{ bottomShorten }">
        <i
          class="el-icon-close pointer"
          @click="closeBottomAD"
          v-show="!bottomShorten"
        ></i>
        <img
          @click.stop="bottomImgClick"
          :src="
            bottomAd[
              bottomShorten
                ? 'advertisementMiniImagePath'
                : 'advertisementImagePath'
            ]
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import { dynamicAd } from "@/api/home";
import { sleep } from "@/utils/index";
export default {
  data() {
    return {
      leftAd: {},
      rightAd: {},
      bottomAd: {},
      bottomShorten: false,
    };
  },
  created() {
    this.getAds();
  },
  methods: {
    async getAds() {
      const res = await this.$axios.get(dynamicAd);
      this.leftAd = res.find((item) => item.advertisementLocation === "LAD");
      this.rightAd = res.find((item) => item.advertisementLocation === "RAD");
      const bottomAd = res.find((item) => item.advertisementLocation === "BAD");
      if (bottomAd) {
        this.bottomAd = {
          ...bottomAd,
          show: false,
        };
        await sleep(3000);
        this.bottomAd.show = true;
      }
    },
    closeBottomAD() {
      this.bottomShorten = true;
    },
    bottomImgClick() {
      if (this.bottomShorten) {
        this.bottomShorten = false;
      } else {
        const { advertisementLink } = this.bottomAd;
        if (advertisementLink) {
          window.open(advertisementLink);
        }
      }
    },
    adClick({ advertisementLink }) {
      if (advertisementLink) {
        window.open(advertisementLink);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin common {
  position: fixed;
  top: 30%;
  width: 205px;
  height: 308px;
  z-index: 10;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@mixin close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  font-size: 25px;
  font-weight: bold;
}
.leftGGgg {
  left: 4%;
  @include common;
}
.rightGGgg {
  right: 4%;
  @include common;
}
.side {
  > i {
    @include close;
  }
}
.bottomGGgg {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 11;
  width: 100%;
  height: 97px;
  transition: all 1s;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > i.el-icon-close {
    @include close;
  }
  > i.el-icon-arrow-right {
    position: absolute;
    right: 10px;
    top: 10px;
    color: black;
    font-size: 100px;
    font-weight: bold;
  }
  &.bottomShorten {
    width: 100px;

    overflow: hidden;
    transition: width 1s;
    img {
      border-radius: 0 50px 50px 0;
      object-fit: cover;
    }
  }
  // &.bottomExpend {
  //   width: 100%;
  //   transition: width 1s;
  // }
}
</style>
