<template>
  <div class="ent">
    <el-tabs v-model="activeName">
      <el-tab-pane label="企业设置" name="first">
        <BaseInfo :data="entInfo" v-loading="loading" />
      </el-tab-pane>
      <el-tab-pane label="商户信息" name="second">
        <Business :data="entInfo" @setMallInfo="setSuperMall" />
        <SetKefu
          :status="superMallInfo.status"
          :businessId="superMallInfo.businessId"
          :isEnt="true"
          businessType="2"
          v-if="superMallInfo.businessId"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import BaseInfo from "./components/BaseInfo.vue";
import Business from "./components/Business.vue";
import { queryCurrentEnt } from "@/api/enterprise";
import SetKefu from "@/components/KefuDialog/index.vue";
export default {
  components: {
    BaseInfo,
    Business,
    SetKefu,
  },
  data() {
    return {
      activeName: "first",
      entInfo: {},
      loading: false,
      superMallInfo: {},
    };
  },
  created() {
    this.getEntInfo();
  },
  methods: {
    async getEntInfo() {
      this.loading = true;
      const res = await this.$axios.post(queryCurrentEnt).finally(() => {
        this.loading = false;
      });
      const {
        enterpriseLocationProvinceCode,
        enterpriseLocationCityCode,
        enterpriseLocationCountyCode,
      } = res;
      Object.assign(res, {
        location: [
          enterpriseLocationProvinceCode,
          enterpriseLocationCityCode,
          enterpriseLocationCountyCode,
        ],
      });
      this.entInfo = res;
    },
    setSuperMall(data) {
      this.superMallInfo = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.ent {
  padding: 0 15px;
}
</style>
