<template>
  <div>
    <NewsItem v-for="(item, index) in data" :key="index" :data="item" />
  </div>
</template>
<script>
import NewsItem from "@/components/NewsItem/index";
export default {
  props: {
    data: {
      type: Array,
      default: () => ({}),
    },
  },
  components: {
    NewsItem,
  },
};
</script>
