<template>
  <div class="company">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="申请加入实验室" name="first">
        <CompanyLab
          :statusMap="statusMap"
          v-if="['user', 'lab'].includes(capacity)"
          @applySuccess="applySuccess"
        />
        <div v-else>不能同时申请加入实验室和企业</div>
      </el-tab-pane>
      <el-tab-pane label="申请加入企业" name="second">
        <CompanyEnt
          :statusMap="statusMap"
          :capacity="capacity"
          v-if="['user', 'ent'].includes(capacity)"
          @applySuccess="applySuccess"
        />
        <div v-else>不能同时申请加入实验室和企业</div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { LAB_USER_STATUS } from "@/constant/dict";
import CompanyLab from "./CompanyLab.vue";
import CompanyEnt from "./CompanyEnt.vue";
import { judgeUserRole } from "@/api/userCenter";
export default {
  components: {
    CompanyLab,
    CompanyEnt,
  },
  data() {
    return {
      searchLoading: false,
      btnLoading: false,
      activeName: "first",
      applyHistory: [],
      statusMap: {},
      capacity: "", // 用户当前的身份/包括审核中
    };
  },
  created() {
    this.getDict();
    this.queryUserRole();
  },
  methods: {
    // 判断用户当前身份，数据包括审核中的身份  lab:实验室，ent:企业，user:用户
    async queryUserRole() {
      const res = await this.$axios.get(judgeUserRole);
      const { capacity } = res;
      this.capacity = capacity;
    },
    applySuccess() {
      this.queryUserRole();
    },
    // 获取数据字典
    async getDict() {
      const data = await this.$store.dispatch("getDictData", LAB_USER_STATUS);
      const statusMap = {};
      data.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });
      this.statusMap = statusMap;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.form {
  &-select {
    width: 350px;
  }
  &-lab {
    margin-bottom: 20px;
    border: 1px solid $border-color;
    /deep/ .el-form-item {
      margin-bottom: 0px;
    }
  }
}
.tabs {
}
</style>
