export default [
  {
    title: "[呵呵]",
    image: require("../assets/emoji/smilea_thumb.gif"),
  },
  {
    title: "[嘻嘻]",
    image: require("../assets/emoji/tootha_thumb.gif"),
  },
  {
    title: "[哈哈]",
    image: require("../assets/emoji/laugh.gif"),
  },
  {
    title: "[可爱]",
    image: require("../assets/emoji/tza_thumb.gif"),
    content: "☺️",
  },
  {
    title: "[可怜]",
    image: require("../assets/emoji/kl_thumb.gif"),
  },
  {
    title: "[挖鼻屎]",
    image: require("../assets/emoji/kbsa_thumb.gif"),
  },
  {
    title: "[吃惊]",
    image: require("../assets/emoji/cj_thumb.gif"),
  },
  {
    title: "[害羞]",
    image: require("../assets/emoji/shamea_thumb.gif"),
  },
  {
    title: "[挤眼]",
    image: require("../assets/emoji/zy_thumb.gif"),
  },
  {
    title: "[闭嘴]",
    image: require("../assets/emoji/bz_thumb.gif"),
  },
  {
    title: "[鄙视]",
    image: require("../assets/emoji/bs2_thumb.gif"),
  },
  {
    title: "[爱你]",
    image: require("../assets/emoji/lovea_thumb.gif"),
  },
  {
    title: "[泪]",
    image: require("../assets/emoji/sada_thumb.gif"),
  },
  {
    title: "[偷笑]",
    image: require("../assets/emoji/heia_thumb.gif"),
  },
  {
    title: "[亲亲]",
    image: require("../assets/emoji/qq_thumb.gif"),
  },
  {
    title: "[生病]",
    image: require("../assets/emoji/sb_thumb.gif"),
  },
  {
    title: "[太开心]",
    image: require("../assets/emoji/mb_thumb.gif"),
  },
  {
    title: "[懒得理你]",
    image: require("../assets/emoji/ldln_thumb.gif"),
  },
  {
    title: "[右哼哼]",
    image: require("../assets/emoji/yhh_thumb.gif"),
  },
  {
    title: "[左哼哼]",
    image: require("../assets/emoji/zhh_thumb.gif"),
  },
  {
    title: "[嘘]",
    image: require("../assets/emoji/x_thumb.gif"),
  },
  {
    title: "[衰]",
    image: require("../assets/emoji/cry.gif"),
  },
  {
    title: "[委屈]",
    image: require("../assets/emoji/wq_thumb.gif"),
  },
  {
    title: "[吐]",
    image: require("../assets/emoji/t_thumb.gif"),
  },
  {
    title: "[打哈气]",
    image: require("../assets/emoji/k_thumb.gif"),
  },
  {
    title: "[抱抱]",
    image: require("../assets/emoji/bba_thumb.gif"),
  },
  {
    title: "[怒]",
    image: require("../assets/emoji/angrya_thumb.gif"),
  },
  {
    title: "[疑问]",
    image: require("../assets/emoji/yw_thumb.gif"),
  },
  {
    title: "[馋嘴]",
    image: require("../assets/emoji/cza_thumb.gif"),
  },
  {
    title: "[拜拜]",
    image: require("../assets/emoji/88_thumb.gif"),
  },
  {
    title: "[思考]",
    image: require("../assets/emoji/sk_thumb.gif"),
  },
  {
    title: "[汗]",
    image: require("../assets/emoji/sweata_thumb.gif"),
  },
  {
    title: "[困]",
    image: require("../assets/emoji/sleepya_thumb.gif"),
  },
  {
    title: "[睡觉]",
    image: require("../assets/emoji/sleepa_thumb.gif"),
  },
  {
    title: "[钱]",
    image: require("../assets/emoji/money_thumb.gif"),
  },
  {
    title: "[失望]",
    image: require("../assets/emoji/sw_thumb.gif"),
  },
  {
    title: "[酷]",
    image: require("../assets/emoji/cool_thumb.gif"),
  },
  {
    title: "[花心]",
    image: require("../assets/emoji/hsa_thumb.gif"),
  },
  {
    title: "[哼]",
    image: require("../assets/emoji/hatea_thumb.gif"),
  },
  {
    title: "[鼓掌]",
    image: require("../assets/emoji/gza_thumb.gif"),
  },
  {
    title: "[晕]",
    image: require("../assets/emoji/dizzya_thumb.gif"),
  },
  {
    title: "[悲伤]",
    image: require("../assets/emoji/bs_thumb.gif"),
  },
];
