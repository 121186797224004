<template>
  <div class="devices flex flex-row" v-loading="listLoading">
    <div
      v-for="(item, index) in lists"
      :key="index"
      class="device pointer"
      @click="deviceClick(item)"
    >
      <div class="device-cover">
        <el-image
          :src="item.imgsrc"
          fit="cover"
          v-if="item.imgsrc"
          lazy
        ></el-image>
        <img v-else :src="$oss('common/default_device.png')" />
      </div>
      <div class="device-desc">
        <div class="device-name ellipsis">
          {{ item.devicename }}
        </div>
        <div class="device-price">
          <span v-if="item.face || !item.preferentialPrice">面议</span>
          <template v-else>
            <span>￥{{ item.preferentialPrice }}</span>
            <span>/{{ unitMap[+item.unit] }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mallList } from "@/api/mall";
import { SERVICE_UNIT_TYPE } from "@/constant/dict";
export default {
  props: {
    cateId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      page: {
        pageIndex: 1,
        pageSize: 4,
      },
      lists: [],
      unitMap: {},
    };
  },
  created() {
    this.getDicts();
    this.getDevicesList();
  },

  methods: {
    async getDevicesList() {
      this.listLoading = true;
      const { list } = await this.$axios
        .post(mallList, {
          ...this.page,
          serviceCategorySuperId: this.cateId,
        })
        .finally(() => {
          this.listLoading = false;
        });
      this.lists = list || [];
    },
    deviceClick(data) {
      this.$jump.push({ name: "deviceDetail", query: { id: data.id } });
    },
    async getDicts() {
      const units = await this.$store.dispatch(
        "getDictData",
        SERVICE_UNIT_TYPE
      );
      const unitMap = {};
      units.forEach((item) => {
        unitMap[item.dictValue] = item.dictLabel;
      });
      this.unitMap = unitMap;
    },
  },
};
</script>
<style lang="scss" scoped>
.devices {
  margin: 0 14px;
  min-height: 207px;
}
.device {
  width: 225px;
  margin-right: 17px;
  &:last-child {
    margin-right: 0;
  }
  &-cover {
    width: 100%;
    height: 153px;
    overflow: hidden;
    .el-image {
      width: 100%;
      height: 100%;
    }
    /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s ease-in-out 0s;
    }
  }
  &-desc {
    text-align: center;
    background: white;
    padding-top: 10px;
  }
  &-name {
    font-size: 16px;
    padding: 0 10px;
    max-width: 80%;
  }
  &-price {
    font-weight: bold;
    color: #ff4911;
  }
}
.device:hover .device-cover /deep/ img {
  transform: scale(1.05);
}
</style>
