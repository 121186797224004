<template>
  <div>
    <div v-for="(cart, cartIndex) in data" :key="cartIndex" class="list">
      <div class="list-busName flex flex-row">
        <div class="list-busCheck">
          <el-checkbox
            v-model="businessCheck[cartIndex]"
            @change="businessCheckChange($event, cartIndex)"
          ></el-checkbox>
        </div>
        <div>
          商家：<span>{{ cart.productmallBusinessName }}</span>
        </div>
      </div>
      <el-table
        :ref="`multipleTable${cartIndex}`"
        :data="cart.cartRankList"
        :show-header="false"
        @selection-change="handleGoodChange($event, cartIndex)"
        class="list-table"
      >
        <el-table-column type="selection" width="50px" align="center">
        </el-table-column>
        <el-table-column min-width="49%">
          <template slot-scope="{ row }">
            <div class="flex flex-row ware">
              <div class="ware-cover">
                <img
                  :src="row.productmallWaresImage"
                  v-if="row.productmallWaresImage"
                />
                <img v-else :src="$oss('common/default_device.png')" />
              </div>
              <div class="ware-name flex-1">
                {{ row.productmallWaresName }}
              </div>
              <div class="ware-rank">规格：{{ row.waresRankName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="15%" align="center">
          <template slot-scope="{ row }">
            <div
              :class="{ isDiscount: !!+row.isDiscount }"
              v-if="!!+row.isDiscount"
            >
              ￥{{ row.price }}
            </div>
            <div class="list-uniPrice">￥{{ row.discountPrice }}</div>
          </template>
        </el-table-column>
        <el-table-column min-width="15%" align="center">
          <template slot-scope="scope">
            <el-input-number
              size="mini"
              :min="1"
              :max="10000"
              v-model="scope.row.quantity"
              @change="inputNumberChange($event, scope.row)"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column min-width="10%" align="center">
          <template slot-scope="{ row }">
            <div class="list-totalPrice">
              ￥{{ (+row.discountPrice * +row.quantity).toFixed(2) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="10%" align="center">
          <template slot-scope="{ row, $index }">
            <div
              @click="delteCartBtn(row, cartIndex, $index)"
              class="list-delte pointer"
            >
              删除
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { updateCartNum, delteCart } from "@/api/mall"; //
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allCheck: false,
      businessCheck: {},
      selectGoodsMap: {}, // 选中的商品,父组件要获取的值
    };
  },
  methods: {
    // 商品列表选中/取消选中
    handleGoodChange(selection, cartIndex) {
      this.selectGoodsMap[cartIndex] = selection; // 选中商品记录下来
      const isAllcheck =
        selection.length === this.data[cartIndex].cartRankList.length;
      this.$set(this.businessCheck, cartIndex, isAllcheck);
      this.judgeIsCheckAll();
      let num = 0;
      Object.keys(this.selectGoodsMap).forEach((key) => {
        num += this.selectGoodsMap[key].length;
      });
      this.$emit("selectNumChange", num);
    },
    // 选中/取消选中商家
    businessCheckChange(val, cartIndex) {
      if (val) {
        this.$refs[`multipleTable${cartIndex}`][0].toggleAllSelection();
      } else {
        this.$refs[`multipleTable${cartIndex}`][0].clearSelection();
      }
      this.judgeIsCheckAll();
    },
    // 判断此时是否为全部选中/取消
    judgeIsCheckAll() {
      // 判断是否所有商家都选中/取消
      let isCheckAll = true;
      const businessLen = this.data.length;
      for (let i = 0; i < businessLen; i++) {
        if (!this.businessCheck[i]) isCheckAll = false;
      }
      this.$emit("allCheckChange", isCheckAll);
    },
    // 父组件调用的方法：选中/取消选中所有
    allCartCheckChange(val) {
      const businessLen = this.data.length;
      for (let i = 0; i < businessLen; i++) {
        this.businessCheckChange(val, i);
      }
    },
    // 修改商品数量
    async inputNumberChange(curVal, rowData) {
      const { productmallShoppingCartId } = rowData;
      this.$emit("inputNumberChange");
      await this.$axios.post(updateCartNum, {
        productmallShoppingCartId,
        quantity: curVal,
      });
    },
    // 删除商品
    async delteCartBtn(row) {
      await this.$confirm("确定删除该商品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$axios.post(delteCart, {
        cartIds: [row.productmallShoppingCartId],
      });
      this.$emit("refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.list {
  &-busName {
    margin-bottom: 5px;
    padding-left: 16px;
  }
  &-busCheck {
    margin-right: 5px;
  }
  &-table {
    border: 1px solid $border-color;
    margin-bottom: 20px;
    /deep/ .cell {
      padding: 0;
    }
  }
  &-uniPrice {
    font-weight: bold;
    font-size: 0.85rem;
  }
  &-totalPrice {
    font-weight: bold;
    color: $price-color;
  }
}
.ware {
  &-cover {
    width: 100px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-name {
    padding-left: 10px;
  }
  &-rank {
    width: 200px;
  }
}
.isDiscount {
  text-decoration: line-through;
  color: $font-color-gray;
  font-size: 0.85rem;
}
</style>
