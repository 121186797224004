<template>
  <table class="table-bordered">
    <tbody>
      <tr>
        <td>联系人</td>
        <td>{{ laboratory.checkmallBusinessContactsName }}</td>
      </tr>
      <tr>
        <td>联系方式</td>
        <td>{{ laboratory.checkmallBusinessContactsPhone }}</td>
      </tr>
      <tr>
        <td>样品信息<br />测试要求</td>
        <td>
          <CheckItem :items="order.rareCheckmallOrderSamplesEx" />
        </td>
      </tr>
      <tr>
        <td>订单备注</td>
        <td>{{ order.leavingMessage }}</td>
      </tr>
      <tr>
        <td>下单须知</td>
        <td>样品要求无毒，无腐蚀性，无放射性</td>
      </tr>
      <tr>
        <td>权责声明</td>
        <td>
          <div>
            1、委托方对送检物品及其相关信息资料的真实性、合法性承担全部负责；
          </div>
          <div>
            2、委托方对送检物品的任何已知或潜在安全风险有如实告知义务，并在订单备注声明；
          </div>
          <div>
            3、检测结果仅对本次送检物品有效，结果使用所产生的直接或间接损失，包头稀土测试平台不承担任何责任。
          </div>
          <div>
            <span>4、</span>
            <span class="protocol pointer" @click="$emit('showProtocol')"
              >委托方已阅读协议，且同意协议内容。</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <el-button type="primary" plain size="mini" @click="confirmDeed"
            >确认委托单</el-button
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import CheckItem from "./CheckItem";
export default {
  components: {
    CheckItem,
  },
  props: {
    laboratory: {
      type: Object,
      default: () => ({}),
    },
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      orderId: null,
    };
  },
  created() {
    this.orderId = this.$route.query.orderId;
  },
  methods: {
    goBack() {
      this.$router.replace({ name: "order", query: { id: this.orderId } });
    },
    async confirmDeed() {
      this.$emit("confirmDeed");
      //   /**
      //    *BEFOREHANDORDER(100,"预下单"),
      //     USERORDER(200, "下单完成"),
      //     WAITSAMPLE(300, "等待样品"),
      //     TESTING(400, "正在检测"),
      //     CREATREPORT(500, "报告编制"),
      //     COMPLETEORDER(600, "完成订单");
      //    */
      //   try {
      //     await this.$axios.post(updateStrictOrderStatus, {
      //       checkmallOrderMainId: this.orderId,
      //       orderCheckStatus: DEED_ORDER_SUCCESS,
      //     });
      //     this.$message.success("下单成功");
      //   } catch (e) {
      //     this.$message.error(e && e.msg);
      //   }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  max-width: 655px;
  font-size: 0.85rem;
  margin-bottom: 15px;
}
.table-bordered {
  border: 1px solid #ddd;
}
.table-bordered > tbody > tr > td {
  border: 1px solid #ddd;
  padding: 10px 15px;
  &:first-child {
    width: 85px;
    max-width: 85px;
    text-align: center;
    vertical-align: middle;
  }
}
.protocol {
  color: $theme-color;
}
</style>
