<template>
  <el-dialog
    title="申请评估"
    :visible.sync="dialogVisible"
    width="60%"
    class="dialog"
  >
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="申请评估描述:" prop="reviewApplyContent">
        <el-input
          type="textarea"
          v-model="form.reviewApplyContent"
          resize="none"
          rows="8"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="上传图片:">
        <ImgUpload />
      </el-form-item> -->
      <el-form-item label="上传文件:">
        <FileUpload @onSuccess="fileUploadSuccess" :limit="3" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      <el-button type="primary" @click="submitBtn" size="small"
        >提 交</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { submitEstimateInfo } from "@/api/expert";
// import ImgUpload from "@/components/ImgUpload/index";
import FileUpload from "@/components/FileUpload/index";
export default {
  components: {
    FileUpload,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        reviewApplyContent: "", // 申请内容
        reviewApplyFilePath: "",
      },
      rules: {
        reviewApplyContent: [
          { required: true, message: "请输入申请评估的内容", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async submitBtn() {
      await this.$refs.form.validate();
      const { id } = this.$route.query;
      await this.$axios.post(submitEstimateInfo, {
        expertId: +id,
        ...this.form,
      });
      this.$message.success(
        `提交成功，可以去"用户中心"->"专家评审记录"查看结果`
      );
      this.$refs["form"].resetFields();
      this.dialogVisible = false;
    },
    fileUploadSuccess(files) {
      Object.assign(this.form, { reviewApplyFilePath: files });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  /deep/ .el-dialog__body {
    padding: 10px 20px;
  }
}
.upload {
  &-tip {
    height: 20px;
    line-height: 20px;
    margin-top: 5px;
  }
}
</style>
