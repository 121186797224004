<template>
  <div class="train">
    <el-form :inline="true" :model="form" class="qForm" :size="queryFormSize">
      <el-form-item label="视频名称">
        <el-input
          v-model.trim="form.courseName"
          placeholder="请输入视频名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryBtnClick">查询</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-table :data="lists" border v-loading="tableLoading">
        <el-table-column prop="cover" label="视频封面" width="170px">
          <template slot-scope="scope">
            <div class="train-cover">
              <img :src="scope.row.courseLogo" v-if="scope.row.courseLogo" />
              <img :src="$oss('common/default_device.png')" v-else />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="courseName" label="视频名称"> </el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              plain
              @click="toView(scope.row)"
              size="mini"
              >去观看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import { QUERY_FORM_SIZE } from "@/constant/size";
import { myTrainClass } from "@/api/userCenter";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      form: {
        courseName: "",
      },
      tableLoading: false,
      lists: [],
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.tableLoading = true;
      const { list, totalItem } = await this.$axios
        .post(myTrainClass, {
          ...this.page,
          ...this.form,
        })
        .finally(() => {
          this.tableLoading = false;
        });
      this.lists = list || [];
      this.totalItem = totalItem;
    },
    // 分页请求
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    queryBtnClick() {
      this.page.pageIndex = 1;
      this.getList();
    },
    toView(item) {
      this.$router.push({
        name: "onlineTrainDetail",
        query: { id: item.courseId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.train {
  &-cover {
    width: 150px;
    height: 100px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-name {
    color: #3073f4;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.qForm {
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
}
</style>
