<template>
  <el-upload
    :action="cloudParams.uploadUrl"
    :data="postData"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    :before-upload="beforeUpload"
    multiple
    :limit="limit"
    :on-exceed="handleExceed"
    :file-list="fileList"
    :on-success="onSuccess"
  >
    <el-button size="small" type="primary">点击上传</el-button>
    <div slot="tip" class="upload-tip">
      只能上传jpg、png、pdf、excel文件，且不超过2M
    </div>
  </el-upload>
</template>
<script>
import { fileCloudToken } from "@/api/common";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      cloudParams: {
        uploadUrl: "",
      },
      postData: {
        token: "",
      },
      fileList: [],
    };
  },
  created() {
    this.getToken();
  },
  methods: {
    async getToken() {
      const res = await this.$axios.get(fileCloudToken);
      this.cloudParams = res;
      this.postData.token = res.token;
    },
    beforeUpload(file) {
      this.postData.key = `${new Date().getTime()}/${file.name}`;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onSuccess(res, file, fileList) {
      console.log(res, file, fileList);
      const urls = fileList.map(
        (item) => `${this.cloudParams.displayUrl}/${item.response.key}`
      );
      this.$emit("onSuccess", urls.join(";"));
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>
