<template>
  <el-carousel :interval="40000" arrow="always" height="276px" class="banners">
    <el-carousel-item v-for="(item, index) in banners" :key="index">
      <img
        :src="item.articleImage"
        @click="bannerClick(item)"
        class="banners-img pointer"
      />
    </el-carousel-item>
  </el-carousel>
</template>
<script>
import { newsBanner } from "@/api/home";
export default {
  data() {
    return {
      banners: [],
    };
  },
  created() {
    this.getNewsImgs();
  },
  methods: {
    async getNewsImgs() {
      const res = await this.$axios.get(newsBanner);
      this.banners = res;
      console.log("res", res);
    },
    bannerClick(data) {
      this.$jump.push({ name: "newsDetail", query: { id: data.articleId } });
    },
  },
};
</script>
<style lang="scss" scoped>
.banners {
  width: 280px;
  height: 276px;
  margin-left: 14px;
  &-img {
    width: 280px;
    height: 276px;
    vertical-align: middle;
    object-fit: cover;
  }
  /deep/ .el-carousel__button {
    width: 4px;
  }
}
</style>
