<template>
  <div class="status">
    <span>
      <el-tag
        size="small"
        v-if="row.orderPayType && payTypeMap[row.orderPayType]"
        >{{ payTypeMap[row.orderPayType] }}</el-tag
      >
    </span>
    <span>
      <el-tag
        size="small"
        :type="row.orderPayStatus === PAYSTATUS_SUCCESS ? 'success' : 'danger'"
      >
        {{ row.orderPayStatus === PAYSTATUS_SUCCESS ? "已支付" : "未支付" }}
      </el-tag>
    </span>
  </div>
</template>
<script>
import { PAYSTATUS_SUCCESS } from "@/constant/index";
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    payTypeMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PAYSTATUS_SUCCESS,
    };
  },
};
</script>
<style lang="scss" scoped>
.status {
  padding: 10px;
  > span {
    margin-right: 10px;
  }
}
</style>
