<template>
  <div class="detail flex flex-row">
    <div class="detail-left">
      <img :src="info.checkmallWaresLogo" class="detail-cover" />
    </div>
    <div class="detail-right flex-1">
      <div class="detail-title">
        <span>{{ info.checkmallWaresName }}</span>
        <img src="@/assets/common/address.png" />
        <label>{{ info.laboratoryLocationCity }}</label>
      </div>
      <div class="detail-line" v-if="info.checkmallWaresBrand">
        <label>品牌</label>
        <span>{{ info.checkmallWaresBrand }}</span>
      </div>
      <div class="detail-line" v-if="info.checkmallWaresModel">
        <label>型号</label><span>{{ info.checkmallWaresModel }}</span>
      </div>
      <div class="detail-line">
        <label>模式</label>
        <span class="pattern">{{ info.checkmallWaresPattern }}</span>
      </div>
      <div class="specs flex flex-row">
        <div class="specs-left">规格</div>
        <div class="flex-1">
          <Specs :info="info.serviceProject" :unitMap="unitMap" />
        </div>
      </div>
      <div class="pay">
        <el-button type="primary" @click="toOrderPage" size="small"
          >立即下单</el-button
        >
        <el-button type="primary" plain @click="toContact" size="small"
          >联系客服</el-button
        >
      </div>
      <div class="promise">
        <label>服务承诺:</label>
        <el-tag size="small" type="success">交易担保</el-tag>
        <el-tag size="small" type="success">快速测试</el-tag>
        <el-tag size="small" type="success">结果分析</el-tag>
      </div>
    </div>
  </div>
</template>
<script>
import Specs from "./Specs.vue";
import { mapGetters } from "vuex";
import { getBusinessCustomerServer } from "@/api/userCenter";
export default {
  name: "detail",
  components: {
    Specs,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    unitMap: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["token", "userBaseInfo"]),
  },
  data() {
    return {};
  },
  methods: {
    toOrderPage() {
      if (!this.token) {
        this.$store.dispatch("login");
        return;
      }
      this.$router.push({
        name: "deviceOrder",
        query: { id: this.info.checkmallWaresId },
      });
    },
    // 联系客服
    toContact() {
      if (!this.token) {
        this.$store.dispatch("login");
        return;
      }
      this.getCustomer();
    },
    async getCustomer() {
      const { checkmallBusinessId } = this.info;
      const res = await this.$axios.post(getBusinessCustomerServer, {
        businessId: checkmallBusinessId,
        businessType: "1",
      });
      if (res) {
        if (res.userId === this.userBaseInfo.userId) {
          this.$message.error("不能咨询自己");
          return;
        }
        this.$emit("openConsult", res);
      } else {
        this.$message.info("该商城暂时还没有客服");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$line-color: #f0f0f0;
.detail {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 15px;
  &-left {
    width: 200px;
  }
  &-cover {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: $border-radius;
  }
  &-right {
    padding-left: 20px;
  }
  &-title {
    margin-bottom: 15px;
    span {
      font-size: 1.42rem;
      margin-right: 20px;
    }
    label {
      font-size: 0.85rem;
      color: #999;
    }
    img {
      width: 14px;
      margin-right: 3px;
    }
  }
  &-line {
    border-top: 1px solid $line-color;
    height: 40px;
    line-height: 40px;
    &:last-child {
      border-bottom: 1px solid $line-color;
    }
    label {
      margin-right: 15px;
    }
    span {
      font-size: 0.85rem;
    }
  }
}
.pattern {
  border: 1px solid $theme-color;
  color: $theme-color;
  padding: 2px 3px;
  border-radius: $border-radius;
}
.specs {
  margin-bottom: 8px;
  &-left {
    border-top: 1px solid $line-color;
    width: 45px;
    line-height: 40px;
  }
}
.pay {
  margin-bottom: 15px;
}
.promise {
  > label {
    margin-right: 20px;
  }
  /deep/ .el-tag {
    margin-right: 15px;
  }
}
</style>
