<template>
  <el-dialog
    title="上传检测报告"
    :visible.sync="dialogVisible"
    width="600px"
    class="dialog"
  >
    <div>
      <div class="dialog-title">文件列表：</div>
      <div class="lists">
        <div
          v-for="(item, index) in reportList"
          :key="index"
          class="lists-item pointer"
          @click="fileClick(item)"
        >
          {{ item.checkmallOrderReportName }}
        </div>
      </div>
      <div>
        <el-upload
          :data="postData"
          :action="cloudParams.uploadUrl"
          :multiple="false"
          :on-success="onSuccess"
          :before-upload="beforeUpload"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <span slot="tip" class="dialog-tip">上传文件不超过5MB</span>
        </el-upload>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveBtn">保 存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { deedDetail, saveReport } from "@/api/userCenter";
import { fileCloudToken } from "@/api/common";
import { updateStrictOrderStatus } from "@/api/mall";
import { DEED_ORDER_REPORT } from "@/constant/index";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      detail: {},
      reportList: [],
      cloudParams: {
        uploadUrl: "",
      },
      postData: {
        token: "",
      },
      fileList: [],
    };
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.getDeedDetail();
    this.getToken();
  },
  methods: {
    async getToken() {
      const res = await this.$axios.get(fileCloudToken);
      this.cloudParams = res;
      this.postData.token = res.token;
    },
    async getDeedDetail() {
      const data = await this.$axios.get(deedDetail, {
        orderId: this.data.checkmallOrderMainId,
      });
      this.detail = data;
      const { checkmallOrderReportResponds } = data;
      this.reportList = checkmallOrderReportResponds || [];
    },
    onSuccess(res, file, fileList) {
      const { displayUrl } = this.cloudParams;
      const files = fileList.map(({ name, response }) => {
        return {
          checkmallOrderReportPath: `${displayUrl}/${response.key}`,
          checkmallOrderReportName: name,
          checkmallOrderMainId: this.data.checkmallOrderMainId,
        };
      });
      this.fileList = files;
    },
    beforeUpload(file) {
      const isLtM = file.size / Math.pow(1024, 2) < 5;
      if (!isLtM) {
        this.$message.error("文件大小不能超过 5MB!");
      }
      this.postData.key = `deedReportFile/${new Date().getTime()}/${file.name}`;
      return isLtM;
    },
    async saveBtn() {
      await this.$axios.post(saveReport, this.fileList);
      this.$message.success("上传报告成功");
      try {
        await this.$axios.post(updateStrictOrderStatus, {
          checkmallOrderMainId: this.data.checkmallOrderMainId,
          orderCheckStatus: DEED_ORDER_REPORT,
        });
      } catch (e) {
        //
      }

      this.$emit("uploadReportSuccess");
      this.$emit("input", false);
    },
    fileClick(data) {
      window.open(data.checkmallOrderReportPath);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.dialog {
  /deep/ .el-dialog__body {
    padding: 10px 20px;
  }
  &-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  &-tip {
    margin-left: 5px;
  }
}
.lists {
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  min-height: 50px;
  &-item {
    margin-bottom: 10px;
    &:hover {
      color: $theme-color;
      text-decoration: underline;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
