<template>
  <div class="flex flex-row flex-wrap">
    <div
      class="lab"
      v-for="(item, index) in data"
      :key="index"
      :class="{ isRight: (index + 1) % 4 === 0 }"
      @click="labClick(item)"
    >
      <div class="lab-cover">
        <img :src="item.laboratoryLogo" />
      </div>
      <div class="ellipsis lab-name">{{ item.laboratoryName }}</div>
      <div class="ellipsis lab-address">
        <span>{{ item.laboratoryLocationProvince }}</span>
        <span>{{ item.laboratoryLocationCity }}</span>
        <span>{{ item.laboratoryLocationCounty }}</span>
        <span>{{ item.laboratoryAddress }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    labClick({ laboratoryId }) {
      this.$jump.push({ name: "labDetail", query: { id: laboratoryId } });
    },
  },
};
</script>
<style lang="scss" scoped>
.lab {
  width: 291px;
  background: #ffffff;
  border-radius: 3px;
  margin-right: 18px;
  margin-bottom: 20px;
  &.isRight {
    margin-right: 0;
  }
  &-cover {
    text-align: center;
    margin: 6px 0;
    img {
      width: 184px;
      height: 90px;
      object-fit: contain;
    }
  }
  &-name {
    text-align: center;
    max-width: 100%;
    width: 100%;
    color: #424242;
    font-size: 15px;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  &-address {
    font-size: 12px;
    color: #7c7c7c;
    max-width: 100%;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 22px;
    text-align: center;
  }
}
</style>
