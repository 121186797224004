<template>
  <div class="cous">
    <el-tabs v-model="tabActive" @tab-click="handleClick">
      <el-tab-pane
        :label="item.label"
        :name="item.name"
        v-for="(item, index) in tabs"
        :key="index"
      ></el-tab-pane>
    </el-tabs>
    <div class="cous-wrap">
      <div class="flex flex-row flex-wrap cous-items">
        <div
          v-for="(item, index) in coupons"
          :key="index"
          class="cous-item"
          :class="{ isRight: (index + 1) % 2 === 0 }"
        >
          <Coupon :data="item" range="userCenter" />
        </div>
      </div>
    </div>

    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
  </div>
</template>
<script>
import { queryUserCoupons } from "@/api/activity";
import Coupon from "@/components/Coupon/index";
import Pagination from "@/components/Pagination/index";
export default {
  components: {
    Coupon,
    Pagination,
  },
  data() {
    return {
      tabs: [
        { name: "unUsed", label: "未使用" },
        { name: "used", label: "已使用" },
        { name: "expired", label: "已过期" },
      ],
      tabActive: "unUsed",
      // 优惠券使用状态（数据字典）（0-未使用；1-已使用；2-已过期）
      couponTypeMap: {
        unUsed: 0,
        used: 1,
        expired: 2,
      },
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      coupons: [],
      totalItem: 0,
    };
  },
  created() {
    // 获取优惠券列表
    this.getCoupons();
  },
  methods: {
    // 获取优惠券列表
    async getCoupons() {
      const { list, totalItem } = await this.$axios.post(queryUserCoupons, {
        ...this.page,
        couponUseStatus: this.couponTypeMap[this.tabActive],
      });
      this.totalItem = totalItem;
      this.coupons = list;
    },
    // tab点击
    handleClick() {
      this.page.pageIndex = 1;
      this.totalItem = 0;
      this.getCoupons();
    },
    // 分页处理
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getCoupons();
    },
  },
};
</script>
<style lang="scss" scoped>
.cous {
  padding: 0 15px;
  &-wrap {
    min-height: 500px;
  }
  &-item {
    width: 400px;
    margin-bottom: 30px;
    margin-right: 30px;
  }
}
</style>
