<template>
  <div class="strict">
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav class="strict-nav" /><!-- 顶部导航-->
    <Banner />
    <Cates v-loading="loading" :data="parentCates" />
    <div class="strict-devices">
      <div class="strict-devicesInner">
        <div
          v-for="(item, index) in parentCates"
          :key="index"
          class="device flex flex-row"
        >
          <BlockType :data="item" :index="index" />
          <div class="flex-1 device-items">
            <Devices :cateId="item.serviceCategoryId" />
          </div>
        </div>
      </div>
    </div>
    <FootBar class="footBar" />
  </div>
</template>
<script>
import Banner from "./components/Bnnner.vue";
import Cates from "./components/Cates";
import BlockType from "./components/BlockType.vue";
import Devices from "./components/Devices.vue";
import { queryKind } from "@/api/mall";
export default {
  components: {
    Banner,
    Cates,
    BlockType,
    Devices,
  },
  data() {
    return {
      parentCates: [],
      loading: false,
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    async getCategory() {
      // 获取顶级分类
      this.loading = true;
      const pData = await this.$axios
        .get(queryKind, {
          superCategoryId: 0,
        })
        .finally(() => {
          this.loading = false;
        });
      pData.forEach((item) => {
        Object.assign(item, { child: [] });
      });
      this.parentCates = pData;
      pData.forEach(async (item) => {
        const child = await this.$axios.get(queryKind, {
          superCategoryId: item.serviceCategoryId,
        });
        Object.assign(item, { child });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.strict {
  &-devices {
    padding: 62px 0;
    min-height: 500px;
    background: #f0f0f8;
  }
  &-devicesInner {
    width: $max-width;
    margin: 0 auto;
  }
}
.device {
  margin-bottom: 63px;
  &:last-child {
    margin-bottom: 0;
  }
}
.footBar {
  padding-top: 0;
}
</style>
