<template>
  <div
    class="good pointer"
    @click="toGoodDetail"
    :class="{ isRight: (index + 1) % 5 === 0 }"
  >
    <div class="good-cover">
      <img
        :src="good.productmallWaresImage"
        v-if="good.productmallWaresImage"
      />
      <img v-else :src="$oss('common/default_device.png')" />
    </div>
    <div class="good-desc">
      <div class="good-name ellipsis">{{ good.productmallWaresName }}</div>
      <div class="good-price" v-if="good.productmallWareMinprice">
        ￥{{ good.productmallWareMinprice }}
      </div>
      <div class="good-price" v-else>面议</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    good: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    toGoodDetail() {
      this.$jump.push({
        name: "goodsDetail",
        query: { id: this.good.productmallWaresId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$max-width: 224px;
.good {
  margin-bottom: 30px;
  margin-right: 25px;
  max-width: $max-width;
  background: white;
  &.isRight {
    margin-right: 0;
  }
  &-cover {
    width: $max-width;
    height: 153px;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s ease-in-out 0s;
    }
  }
  &-desc {
    text-align: center;
    background: white;
    padding: 10px 0;
  }
  &-price {
    color: $price-color;
  }
}
.good:hover .good-cover img {
  transform: scale(1.05);
}
</style>
