<template>
  <div>
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="企业营业执照" prop="businessLicence">
        <ImgUpload :imgs="form.businessLicence" @onSuccess="licUploadSuccess" />
        <!-- <div class="form-imgTip">建议图片上传尺寸95*95，后缀为JPG/JEPG/PNG格式的图片,做多</div> -->
      </el-form-item>
      <el-form-item label="检验检测资质" prop="checkQualification">
        <ImgUpload
          :limit="3"
          :imgs="form.checkQualification"
          @onSuccess="quaUploadSuccess"
        />
      </el-form-item>
      <el-form-item label="">
        <el-button
          type="primary"
          plain
          size="small"
          :loading="btnLoading"
          @click="saveBtn"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import ImgUpload from "@/components/ImgUpload/index";
import { getUserLabInfo } from "@/api/userCenter";
import { updateLaboratory } from "@/api/lab";
export default {
  components: {
    ImgUpload,
  },
  data() {
    return {
      form: {
        checkQualification: "",
        businessLicence: "",
        laboratoryId: null,
      },
      rules: {},
      btnLoading: false,
    };
  },
  created() {
    this.getUserLabDetail();
  },
  methods: {
    async getUserLabDetail() {
      const data = await this.$axios.get(getUserLabInfo);
      const { checkQualification, businessLicence, laboratoryId } = data;
      Object.assign(this.form, {
        checkQualification,
        businessLicence,
        laboratoryId,
      });
    },
    quaUploadSuccess(file) {
      this.form.checkQualification = file;
    },
    licUploadSuccess(file) {
      this.form.businessLicence = file;
    },
    async saveBtn() {
      this.btnLoading = true;
      await this.$axios
        .post(updateLaboratory, {
          ...this.form,
          modifyType: "1", // 修改类型(0-修改实验室信息；1-修改资质) 修改时必填
        })
        .finally(() => {
          this.btnLoading = false;
        });
      this.$message.success("保存成功");
    },
  },
};
</script>
