import Emoji from "@/constant/emoji.js";
// import { parseTime } from "@/utils/index";
import { MSG_SYS, MSG_TEXT } from "@/constant/chart.js";
export default {
  data() {
    return {};
  },
  methods: {
    initSocketEvent() {
      //加入房间成功
      this.socket.on("joinSuccess", (user) => {
        console.log("joinSuccess", user);
        const { identity, room, sendId, sendName, socketId } = user;
        Object.assign(this.userRoomInfo, {
          identity,
          room,
          sendId,
          sendName,
          socketId,
          online: true,
        });
        const isFun =
          this.joinSuccessCallback &&
          typeof this.joinSuccessCallback === "function";
        if (isFun) this.joinSuccessCallback();
      });
      //接收信息
      this.socket.on("reviceMsg", (data) => {
        console.log("reviceMsg", data);
        this.reviceSocketMessage(data);
      });
    },
    //加入会话
    joinChat(data = {}) {
      const { userId, userRealName } = this.userBaseInfo;
      const { identity } = this.userRoomInfo;
      // console.log(joinChat)
      this.socket.emit("joinChat", {
        sendId: userId,
        sendName: userRealName,
        messageType: MSG_SYS,
        identity,
        ...data,
      });
    },
    closeSeesion() {
      const { sendId, room } = this.userRoomInfo;
      this.socket.emit("closeSeesion", {
        sendId,
        room,
      });
      Object.assign(this.userRoomInfo, {
        online: false,
      });
    },
    // socket发送消息
    sendMessage(msg) {
      const { userId, userRealName } = this.userBaseInfo;
      const { room, socketId, identity } = this.userRoomInfo;
      const message = {
        sendName: userRealName,
        sendId: userId,
        messageType: MSG_TEXT,
        room,
        socketId,
        identity,
        // cerateTime: parseTime(new Date(), "{h}:{i}:{s}"),
        ...msg,
      };
      this.socket.emit("sendMsg", message);
    },
    // 消息过滤
    filterMsg(item) {
      const emojiReg = /\[.+?\]/g;
      const message = item.message.replace(emojiReg, function (a) {
        const emo = Emoji.find((e) => e.title === a);
        return `<img src='${emo.image}'/> `;
      });
      Object.assign(item, { message });
      return item;
    },
    getFriendsList(params) {
      this.socket.emit("getFriendsList", params);
    },
    // 获取历史记录
    getHistory({ customerId, consultId }) {
      // unionId:联合id，方面查询聊天记录，格式：客服id-咨询者id
      const { room } = this.userRoomInfo;
      this.socket.emit("getHistory", {
        room,
        unionId: `${customerId}-${consultId}`,
      });
    },
  },
};
