<template>
  <div class="tabs">
    <el-tabs v-model="activeName">
      <el-tab-pane label="实验室设置" name="first"
        ><BaseInfo v-if="activeName === 'first'"
      /></el-tab-pane>
      <!-- <el-tab-pane label="实验室设备管理" name="two">
        <DeviceManage />
      </el-tab-pane> -->
      <!-- <el-tab-pane label="实验室地图" name="second">开发中...</el-tab-pane> -->
      <el-tab-pane label="资质验证" name="third">
        <Qualification v-if="activeName === 'third'" />
      </el-tab-pane>
      <!--实验室管理员才能申请加入服务、商品商城-->
      <el-tab-pane
        label="商户信息"
        name="fourth"
        v-if="userBaseInfo.isLaboratory"
      >
        <Business v-if="activeName === 'fourth'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import BaseInfo from "./components/BaseInfo.vue";
import Qualification from "./components/Qualification.vue";
import Business from "./components/Business/index.vue";
import { mapGetters } from "vuex";
// import DeviceManage from "./components/DeviceManage.vue";
export default {
  components: {
    BaseInfo,
    Qualification,
    Business,
    // DeviceManage,
  },
  data() {
    return { activeName: "first" };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  padding: 0 10px;
}
</style>
