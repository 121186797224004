<template>
  <div class="form">
    <el-form :model="form" ref="ruleForm" label-width="100px">
      <el-form-item label="发票抬头：">{{ form.invoiceTitle }} </el-form-item>
      <el-form-item label="开票金额：">
        {{ form.price }}
      </el-form-item>
      <el-form-item label="发票性质：">
        {{ form.openingType }}
      </el-form-item>
      <el-form-item label="申请时间：">
        {{ form.time }}
      </el-form-item>
      <el-form-item label="税务证号：">
        {{ form.no }}
      </el-form-item>
      <el-form-item label="联系人：">
        {{ form.accountBankName }}
      </el-form-item>
      <el-form-item label="寄送地址：">
        {{ form.userAddress }}
      </el-form-item>
      <el-form-item label="联系电话：">
        {{ form.registerPhone }}
      </el-form-item>
      <div class="form-line"></div>
      <el-form-item label="委托单编号：">
        {{ form.deedNo }}
      </el-form-item>
      <el-form-item label="实验室名称：">
        {{ form.registerCompany }}
      </el-form-item>
      <el-form-item label="订单金额："> {{ form.price }}元 </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        openingType: "电子",
        price: "800",
        time: "2021-09-18",
        userAddress: "湖南省长沙市岳麓区雅阁国际",
        invoiceTitle: "长沙新材料产业研究院有限公司",
        taxCertificate: "",
        accountBankName: "刘小姐",
        accountBankNum: "",
        registerCompany: "深圳市倍测科技有限公司",
        registerPhone: "18772101041",
        no: "91430124MA4L6QLF3E",
        deedNo: "深圳市倍测科技有限公司",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.form {
  margin: 0 20px;
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
  &-line {
    margin: 10px 0;
    border-bottom: 1px dashed #ddd;
  }
}
</style>
