<template>
  <table border="1" cellspacing="0" width="100%" class="cus-table">
    <tbody align="center">
      <tr>
        <td colspan="6" class="bold">客服信息</td>
      </tr>
      <tr>
        <td width="14%">检测依据</td>
        <td colspan="5">
          <div class="flex flex-row flex-between padding-between">
            <div>
              <input
                type="checkbox"
                v-model="testBasis.customer"
                class="input-box"
              />
              <label>客户指定检测方法</label>
            </div>
            <div>
              <input
                type="checkbox"
                v-model="testBasis.center"
                class="input-box"
              />
              <label>本中心选定检测方法</label>
              <span>( </span>
              <label v-for="(item, index) in centerMethodOtps" :key="index">
                <input type="checkbox" class="input-box" />
                {{ item.label }}
              </label>
              <span>)</span>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td width="14%">检测报告格式</td>
        <td colspan="3" width="42%">
          <div class="flex flex-row flex-between padding-between">
            <div>
              <input type="checkbox" class="input-box" v-model="checked" />
              <label>RKLH/JLC-23-001</label>
            </div>
            <div>
              <input type="checkbox" class="input-box" v-model="checked" />
              <label>RKLH/JLC-23-002</label>
            </div>
          </div>
        </td>
        <td width="14%">报告领取方式</td>
        <td width="30%">
          <label v-for="(item, index) in reportGetTypeOtps" :key="index">
            <input type="checkbox" class="input-box" />
            {{ item.label }}
          </label>
          <!-- <el-checkbox-group v-model="reportGetType" class="check-boxs">
            <el-checkbox
              v-for="(item, index) in reportGetTypeOtps"
              :key="index"
              :label="item.value"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group> -->
        </td>
      </tr>
      <tr>
        <td>检测费用</td>
        <td>{{ price }}元</td>
        <td width="10%">加急费</td>
        <td width="16%">
          <div class="check-boxs">
            <label>
              <input type="checkbox" v-model="checked" />
              100%
            </label>
            <label>
              <input type="checkbox" v-model="checked" />
              40%
            </label>
          </div>
        </td>
        <td>样品处置方式</td>
        <td>
          <label v-for="(item, index) in sampleHdlMethodOtps" :key="index">
            <input type="checkbox" />
            {{ item.label }}
          </label>
        </td>
      </tr>
      <tr>
        <td>付款方式</td>
        <td colspan="3"></td>
        <td>预完成时间</td>
        <td>年&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;日</td>
      </tr>
      <tr>
        <td>备注信息</td>
        <td colspan="5"></td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    // 检测依据
    hasBasis: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      checked: false,
      // 本中心选定检测方法
      centerMethodOtps: [
        { label: "国标", value: 1 },
        { label: "行标", value: 2 },
        { label: "地标", value: 3 },
        { label: "非标", value: 4 },
      ],
      // reportGetType: [], // 报告领取方式
      reportGetTypeOtps: [
        { label: "自取", value: 1 },
        { label: "邮寄", value: 2 },
        { label: "传真", value: 3 },
        { label: "邮件", value: 4 },
      ],
      sampleHdlMethod: [],
      sampleHdlMethodOtps: [
        { label: "取回", value: 1 },
        { label: "寄回", value: 2 },
        { label: "本单位处理", value: 3 },
      ],
      // : {
      //   customer: false,
      //   center: false,
      // },
    };
  },
  computed: {
    testBasis() {
      const data = {
        customer: false,
        center: false,
      };
      data[this.hasBasis ? "customer" : "center"] = true;
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
.cus {
  &-table {
    margin-top: -2px;
    tr td {
      padding: 4px 0;
    }
  }
}
.padding-between {
  padding: 0 10px;
}
.input-box {
  vertical-align: middle;
}
</style>
