<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <HeadNav /><!-- 顶部导航-->
    <div class="searchBar flex flex-center flex-middle">
      <SearchBar
        :num="tableData.length"
        v-model="searchKey"
        @btnClick="searchBarClick"
      />
    </div>
    <div class="hall">
      <DemandType
        :data="typeData"
        v-loading="typeLoading"
        @typeClick="typeClick"
      />
      <div class="hall-con flex flex-row">
        <div class="hall-list flex-1">
          <Demand
            v-for="(item, index) in tableData"
            :key="index"
            :data="item"
          />
        </div>
        <div class="hall-news">
          <HallNews />
        </div>
      </div>
      <!-- 分页-->
      <Pagination
        :total="totalItem"
        :page="page"
        @pageIndexChange="handlePageChange"
      />
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import { demandList, abilityType } from "@/api/hall";
import SearchBar from "@/components/SearchBar/index";
import Pagination from "@/components/Pagination/index";
import DemandType from "./components/DemandType.vue";
import Demand from "@/components/DemandItem/index.vue";
import HallNews from "./components/HallNews.vue";
export default {
  components: { SearchBar, DemandType, Pagination, Demand, HallNews },
  data() {
    return {
      searchKey: "",
      tableData: [],
      firstTypeId: 0,
      totalItem: 0,
      demandTotal: 0,
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      tableLoading: true,
      typeData: [],
    };
  },
  created() {
    this.getType();
    this.getDeviceTatal();
  },
  methods: {
    // 获取需求分类
    async getType() {
      this.typeLoading = true;
      const data = await this.$axios
        .get(abilityType, { pid: 0 })
        .finally(() => {
          this.typeLoading = false;
        });
      this.typeData = data;
      this.firstTypeId = data[0].laboratoryAbilityTypeId;
      this.getTableList();
    },
    // 获取需求列表
    async getTableList() {
      this.tableLoading = true;
      const params = {
        ...this.page,
        firstTypeId: this.firstTypeId || null,
        searchKey: this.searchKey,
      };
      const { list, totalItem, total } = await this.$axios.post(
        demandList,
        params
      );
      this.tableLoading = false;
      this.totalItem = totalItem;
      this.demandTotal = total;
      this.tableData = list || [];
    },
    // 检索bar的触发
    searchBarClick() {
      this.getTableList();
    },
    // 分页查询
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getTableList();
    },
    // 需求分类的点击
    typeClick(item) {
      this.firstTypeId = item.laboratoryAbilityTypeId;
      this.getTableList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.footer {
  padding-top: 0;
}
.searchBar {
  height: 93px;
  background: url("https://store-img.cre-tep.com/frontDeskProject/images/demandHall/searchBg.png");
  background-size: 100% 102%;
  background-position-y: -2px;
  /deep/ .search-input {
    width: 600px;
  }
  /deep/ .el-input-group__append {
    background: #4483ff;
    border-color: #4483ff;
    color: white;
    width: 60px;
    text-align: center;
    font-size: 20px;
    .el-button {
      &:hover {
        .el-icon-search {
          color: white;
        }
      }
    }
  }
}
.hall {
  background: $home-bg;
  padding-top: 14px;
  padding-bottom: 20px;
  &-con {
    width: $max-width;
    margin: 0 auto;
  }
  &-list {
    margin-right: 20px;
  }
  &-news {
    width: 300px;
  }
}
</style>
