<template>
  <div>
    <HeadBar />
    <!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="searchBar flex flex-center flex-middle">
      <SearchBar
        :num="lists.length"
        v-model="searchKey"
        @btnClick="searchBarClick"
        placeholder="请输入关键字"
        :showNum="false"
      />
    </div>
    <div class="lists">
      <div class="lists-inner">
        <CateSelect @typeClick="typeClick" />
        <div class="lists-user">
          <div class="flex flex-row flex-wrap">
            <Expert
              v-for="(item, index) in lists"
              :key="index"
              :data="item"
              :eduMap="eduMap"
              :class="{ isRight: (index + 1) % 4 == 0 }"
            />
          </div>
        </div>
      </div>

      <!-- 分页-->
      <Pagination
        :total="totalItem"
        :page="page"
        @pageIndexChange="handlePageChange"
      />
    </div>

    <FootBar class="footer" />
  </div>
</template>
<script>
import { expertList } from "@/api/expert";
import CateSelect from "./components/CateSelect.vue";
import SearchBar from "@/components/SearchBar/index";
import Pagination from "@/components/Pagination/index";
import Expert from "@/components/Expert/index";
import { EDU_GRADE } from "@/constant/dict";
export default {
  components: {
    Pagination,
    SearchBar,
    CateSelect,
    Expert,
  },
  data() {
    return {
      totalItem: 0,
      activeIndex: 0,
      searchKey: "",
      cates: [],
      lists: [],
      page: {
        pageIndex: 1,
        pageSize: 12,
      },
      eduMap: {},
      loading: false,
      currentCateId: null,
    };
  },
  created() {
    this.getDict();
  },
  methods: {
    async getDict() {
      const res = await this.$store.dispatch("getDictData", EDU_GRADE);
      const eduMap = {};
      res.forEach((item) => {
        eduMap[item.dictValue] = item.dictLabel;
      });
      this.eduMap = eduMap;
    },
    // 分页加载
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    async getList() {
      this.listLoading = true;
      const params = {
        ...this.page,
        fieldCategoryId: this.currentCateId,
      };
      if (this.searchKey) {
        params.expertName = this.searchKey;
      }
      const { list, totalItem } = await this.$axios
        .post(expertList, params)
        .finally(() => {
          this.listLoading = false;
        });
      const result = list || [];
      result.forEach((item) => {
        const fields = [];
        item.fieldExes.forEach((field) => {
          fields.push(field.fieldCategoryName);
        });
        Object.assign(item, { fields });
      });
      this.lists = result;
      this.totalItem = totalItem;
    },
    cateClick(index) {
      this.activeIndex = index;
    },
    toDetail() {
      this.$router.push({ name: "expertsDetail" });
    },
    searchBarClick() {
      this.getList();
    },
    typeClick(params = {}) {
      const { firstTypeId, secondTypeId } = params;
      if (secondTypeId) this.currentCateId = secondTypeId;
      else this.currentCateId = firstTypeId;
      this.page.pageIndex = 1;
      this.totalItem = 0;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.searchBar {
  height: 180px;
  // background: url("../../../assets/experts/bg.png");
  background-size: 100% 100%;
  /deep/ .search-input {
    width: 600px;
  }
  /deep/ .el-button {
    width: 130px;
  }
  /deep/ .el-input-group__append {
    background: #4483ff;
    border-color: #4483ff;
    color: white;
    width: 60px;
    text-align: center;
    font-size: 20px;
    .el-button {
      &:hover {
        .el-icon-search {
          color: white;
        }
      }
    }
  }
}
.lists {
  background: $home-bg;
  padding-bottom: 30px;
  &-inner {
    min-height: 400px;
    padding-top: 30px;
  }
  &-user {
    width: $max-width;
    margin: 0 auto;
  }
}
.isRight {
  margin-right: 0;
}
.footer {
  padding-top: 0;
}
</style>
