<template>
  <div class="items">
    <div class="items-inner">
      <div v-for="(item, index) in data" :key="index">
        <div class="items-typeName">{{ item.typeName }}</div>
        <div class="flex flex-row flex-wrap">
          <CourseItem
            :class="{ isRight: (index + 1) % 4 === 0 }"
            class="items-cor"
            v-for="(course, index) in item.onlineTrainCourseListResponds"
            :key="index"
            :data="course"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CourseItem from "@/components/CourseItem/index.vue";
export default {
  components: {
    CourseItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.items {
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-cor {
    margin-right: 20px;
    margin-bottom: 20px;
    &.isRight {
      margin-right: 0px;
    }
  }
  &-typeName {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    padding-top: 40px;
    padding-bottom: 20px;
  }
}
</style>
