<template>
  <div class="act">
    <el-tabs v-model="activeName">
      <el-tab-pane label="联盟活动" name="first">
        <div class="flex flex-row flex-wrap act-con" v-loading="loading">
          <div
            v-for="(item, index) in acts"
            :key="index"
            class="act-item"
            @click="actClick(item)"
          >
            <div class="act-img"><img :src="item.activityImage" /></div>
            <div class="act-title flex flex-row flex-between">
              <div class="flex-1 ellipsis">{{ item.activityTitle }}</div>
              <div class="time">{{ item.createTime | transformTime }}</div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
  </div>
</template>
<script>
import { parseTime } from "@/utils/index";
import { allianceActsList } from "@/api/allianceZone";
import Pagination from "@/components/Pagination/index";
export default {
  filters: {
    transformTime(val) {
      return parseTime(val, "{y}-{m}-{d}");
    },
  },
  components: {
    Pagination,
  },
  data() {
    return {
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      activeName: "first",
      acts: [],
      loading: true,
      detailId: null,
    };
  },
  created() {
    this.detailId = this.$route.query.id;
    this.getActList();
  },
  methods: {
    actClick({ leagueActivityId }) {
      this.$router.push({
        name: "allianceActDetail",
        query: { id: leagueActivityId, detailId: this.detailId },
      });
    },
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getActList();
    },
    async getActList() {
      this.loading = true;
      const { list, totalItem } = await this.$axios
        .get(allianceActsList, {
          ...this.page,
          leagueId: this.detailId,
        })
        .finally(() => {
          this.loading = false;
        });
      this.totalItem = totalItem;
      this.acts = list;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$max-w: 375px;
.act {
  /deep/ .el-tabs__header .el-tabs__item {
    font-size: 1.28rem !important;
    font-weight: bold;
  }
  &-con {
    padding-top: 5px;
    min-height: 300px;
  }
  &-item {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  &-img {
    width: $max-w;
    height: 250px;
    margin-bottom: 7px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-title {
    width: $max-w;
    height: 20px;
    line-height: 20px;
    > .time {
      width: 90px;
      text-align: right;
      color: $font-color-gray;
    }
  }
}
</style>
