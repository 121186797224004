<template>
  <div class="items-block" @click="itemClick(data)">
    <div class="items-cover">
      <img :src="data.courseLogo" v-if="data.courseLogo" />
      <img :src="$oss('common/default_device.png')" v-else />
    </div>
    <div class="items-detail">
      <div class="items-name ellipsis">{{ data.courseName }}</div>
      <div class="items-price">
        <span v-if="data.courseOriginal">￥{{ data.courseOriginal }}元</span>
        <span v-if="data.isFree === 1">免费</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    itemClick(item) {
      this.$router.push({
        name: "onlineTrainDetail",
        query: { id: item.courseId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.items {
  &-block {
    // margin-right: 20px;
    // margin-bottom: 20px;
    cursor: pointer;
  }
  &-cover {
    width: 290px;
    height: 165px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-detail {
    padding-top: 12px;
    padding-bottom: 12px;
    background: white;
  }
  &-name {
    padding: 0 14px;
    margin-bottom: 5px;
    max-width: 250px;
    font-size: 16px;
    color: #333333;
  }
  &-price {
    color: #ff1e00;
    padding: 0 14px;
    font-size: 20px;
  }
}
</style>
