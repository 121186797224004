<template>
  <div class="news">
    <div class="news-head">
      <img src="@/assets/demandHall/naba.png" />
      <span>最新动态</span>
    </div>
    <div class="news-data">
      <span>入驻实验室 {{ labTotal }}家</span>
      <span class="news-dataDevices">设备 {{ deviceTotal }}个</span>
    </div>
    <div class="news-lists" v-loading="listLoading">
      <div v-for="(item, index) in news" :key="index" class="news-list">
        <div class="news-title">更新了一个服务</div>
        <div class="news-con">{{ item.devicename }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mallList } from "@/api/mall";
import { labTotalNum, labDeviceTotal } from "@/api/lab";
export default {
  data() {
    return {
      listLoading: false,
      news: [],
      page: {
        pageIndex: 1,
        pageSize: 7,
      },
      labTotal: 0,
      deviceTotal: 0,
    };
  },
  created() {
    this.getNews();
    this.getLabTotal();
    this.getDeviceTatal();
  },
  methods: {
    async getNews() {
      this.listLoading = true;
      const { list } = await this.$axios
        .post(mallList, {
          ...this.page,
        })
        .finally(() => {
          this.listLoading = false;
        });
      this.news = list || [];
    },
    async getLabTotal() {
      const res = await this.$axios.post(labTotalNum, {});
      this.labTotal = res;
    },
    async getDeviceTatal() {
      const res = await this.$axios.get(labDeviceTotal);
      this.deviceTotal = res;
    },
  },
};
</script>
<style lang="scss" scoped>
.news {
  background: white;
  &-head {
    padding-left: 20px;
    padding-top: 13px;
    margin-bottom: 10px;
    img {
      width: 26px;
      height: 25px;
      vertical-align: top;
      margin-right: 5px;
    }
    span {
      font-size: 20px;
      color: #1c1c1c;
    }
  }
  &-data {
    padding-left: 40px;
    padding-right: 13px;
    color: #737373;
    margin-bottom: 19px;
  }
  &-dataDevices {
    float: right;
  }
  &-lists {
    min-height: 300px;
    padding-left: 40px;
    padding-right: 14px;
  }
  &-list {
    border-bottom: 1px solid #dde7ef;
    padding-bottom: 10px;
    margin-bottom: 22px;
    &:last-child {
      border-bottom: none;
    }
  }
  &-title {
    font-size: 15px;
    color: #1c1c1c;
    margin-bottom: 3px;
  }
  &-con {
    color: #4483ff;
  }
}
</style>
