<!--样品信息-->
<template>
  <table border="1" cellspacing="0" width="100%" class="table">
    <tbody align="center">
      <tr>
        <td colspan="4" class="bold">样品信息</td>
      </tr>
      <tr>
        <td class="bold" width="14%">样品名称</td>
        <td class="bold" width="18%">样品编号</td>
        <td width="20%">中心编号</td>
        <td class="bold">检测项目</td>
      </tr>
      <tr v-for="(item, index) in data" :key="index">
        <td>
          <div class="sam">
            <span class="sam-name">{{ item.sampleName }}</span>
            <span>x{{ item.sampleQuantity }}</span>
          </div>
        </td>
        <td>
          <div class="sam">{{ item.sampleLabel }}</div>
        </td>
        <td>
          <div class="sam"></div>
        </td>
        <td align="left">
          <div class="sam sam-pro">
            <div
              v-for="pro in item.rareCheckmallSampleFirstRankExs"
              :key="pro.checkmallFirstRankId"
            >
              <span>{{ pro.checkmallFirstRankName }}</span>
              <span> [</span>
              <span
                v-for="(rank, rIndex) in pro.rareCheckmallFirstSecondRanks"
                :key="rank.checkmallSecondRankId"
              >
                {{ rank.checkmallSecondRankName }}
                <span
                  v-if="
                    rIndex &&
                    rIndex + 1 == pro.rareCheckmallFirstSecondRanks.length
                  "
                  >、</span
                >
              </span>
              <span>]</span>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="bold">样品状态</td>
        <td colspan="3">
          <div class="status flex flex-row flex-wrap">
            <div
              v-for="(item, index) in statusOption"
              :key="index"
              class="status-item"
            >
              <input
                type="checkbox"
                class="status-box"
                v-model="checkStatus[index]"
              />
              <label>{{ item.label }}</label>
              <span class="status-val">{{ item.val }}</span>
              <label>{{ item.unit }}</label>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      checkStatus: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
      },
      statusOption: [
        { label: "粉", unit: "g", val: "" },
        { label: "块", unit: "g", val: "" },
        { label: "屑", unit: "g", val: "" },
        { label: "粒", unit: "g", val: "" },
        { label: "泥", unit: "g", val: "" },
        { label: "清液", unit: "mL", val: "" },
        { label: "浊液", unit: "mL", val: "" },
        { label: "其他", unit: "", val: this.status },
      ],
      ch: true,
    };
  },
  created() {
    this.checkStatus[this.statusOption.length - 1] = true;
  },
};
</script>
<style lang="scss" scoped>
.table {
  margin-top: -2px;
  tr td {
    padding: 4px 0;
  }
}
.status {
  padding-top: 5px;
  padding-left: 10px;
  text-align: left;
  &-item {
    margin-right: 4px;
    margin-bottom: 5px;
  }
  &-box {
    margin-right: 2px;
    vertical-align: middle;
  }
  &-val {
    border-bottom: 1px solid #2c3e50;
    padding: 0 15px;
  }
}
.sam {
  min-height: 20px;
  &-name {
    margin-right: 10px;
  }
  &-pro {
    padding: 0 10px;
  }
}
</style>
