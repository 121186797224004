<template>
  <div class="bar" id="box">
    <div class="bar-inner flex flex-row flex-between" :class="{ fixed }">
      <div class="bar-all">
        <label>费用合计:</label> <span>￥{{ totalMoney }}</span>
      </div>
      <div class="bar-detail">
        <span @click="showDetail">
          <label class="pointer">费用明细</label>
          <i class="el-icon-d-arrow-left pointer" :class="{ showBarList }"></i>
        </span>
      </div>
      <MoneyBarList v-show="showBarList" :items="tabsData" />
    </div>
  </div>
</template>
<script>
import _throttle from "lodash/throttle";
import MoneyBarList from "./MoneyBarList";
export default {
  name: "moneyBarFix",
  props: {
    tabsData: {
      type: Array,
      default: () => [],
    },
    totalMoney: {
      type: Number,
      default: 0,
    },
  },
  components: {
    MoneyBarList,
  },
  data() {
    return { fixed: false, showBarList: false };
  },
  created() {
    window.addEventListener("scroll", this.addScrollListener);
  },
  mounted() {
    this.addScrollListener();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.addScrollListener);
  },
  methods: {
    showDetail() {
      this.$emit("showDetail");
      this.showBarList = !this.showBarList;
    },
    addScrollListener: _throttle(function () {
      this.showBarList = false;
      const $box = document.getElementById("box");
      const divH = $box.offsetHeight; // div高度
      const windowH =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight; // 浏览器高度兼容写法

      //为了保证兼容性，这里取两个值，哪个有值取哪一个
      //scrollTop就是触发滚轮事件时滚轮的高度
      var obj = this.getRect($box);
      if (obj.top >= windowH) this.fixed = true; //console.log("84在视口之下");
      if (obj.top < windowH && obj.bottom >= windowH) {
        // console.log("84正在出现");
      }
      if (
        obj.top > 0 &&
        obj.top < windowH &&
        obj.bottom > 0 &&
        obj.bottom < windowH
      ) {
        // console.log("84正在视口中");
        this.fixed = false;
      }
      if (obj.top <= 0 && obj.bottom <= divH && obj.bottom > 0) {
        // console.log("84正在离开视口");
      }
      if (obj.bottom <= 0) {
        // console.log("84在视口之上,已经离开视口");
      }
    }, 200),
    getRect(element) {
      const rect = element.getBoundingClientRect(); // 距离视窗的距离
      const top = document.documentElement.clientTop
        ? document.documentElement.clientTop
        : 0; // html元素对象的上边框的宽度
      const left = document.documentElement.clientLeft
        ? document.documentElement.clientLeft
        : 0;
      return {
        top: rect.top - top,
        bottom: rect.bottom - top,
        left: rect.left - left,
        right: rect.right - left,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.bar {
  width: $max-width;
  position: relative;
  &-inner {
    background: rgb(198, 226, 255);
    height: 58px;
    width: $max-width;
    line-height: 58px;
    border-radius: $border-radius;
    &.fixed {
      position: fixed;
      float: left;
      bottom: 0;
      z-index: 9;
      background: white;
      border: 1px solid $theme-color;
      border-radius: 0;
    }
  }
  &-all,
  &-detail {
    font-size: 1.14rem;
    color: #222;
  }
  &-all {
    margin-left: 20px;
    > span {
      color: $theme-color;
      font-size: 1.42rem;
    }
  }
  &-detail {
    margin-right: 20px;
    i {
      color: $theme-color;
      font-size: 1.42rem;
      margin-left: 5px;
      transform: rotateZ(90deg);
      // transition: transform 0.5s;
      &.showBarList {
        transform: rotateZ(270deg);
        // transition: transform 0.5s;
      }
    }
  }
}
</style>
