<template>
  <div class="ns">
    <News />
    <Settled />
  </div>
</template>
<script>
import News from "./News.vue";
import Settled from "./Settled.vue";
export default {
  components: {
    News,
    Settled,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.ns {
  padding-top: 30px;
  padding-bottom: 20px;
  background: #f0f0f8;
}
</style>
