<template>
  <div class="wrapper">
    <HeadBar />
    <HeadNav />
    <div class="mall">
      <div class="mall-inner">
        <GoodsType @toSearch="toSearch" />
        <div class="mall-search">
          <SearchBar
            :num="totalItem"
            v-model="searchKey"
            @btnClick="resultSearchClick"
          />
        </div>
        <div class="mall-lists" v-loading="listLoading">
          <div class="flex flex-wrap">
            <GoodItem
              v-for="(item, index) in results"
              :key="index"
              :good="item"
              :index="index"
            />
          </div>
        </div>

        <Pagination
          :total="totalItem"
          :page="page"
          @pageIndexChange="handlePageChange"
        />
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import SearchBar from "@/components/SearchBar/index";
import GoodItem from "@/components/GoodItem/index";
import Pagination from "@/components/Pagination/index";
import GoodsType from "@/components/GoodTypesSelect/index";
import { fineMallGoodList } from "@/api/mall";

export default {
  components: {
    SearchBar,
    GoodItem,
    Pagination,
    GoodsType,
  },
  data() {
    return {
      searchKey: "", // 关键字查询
      results: [],
      totalItem: 0,
      listLoading: false,
      page: {
        pageIndex: 1,
        pageSize: 20,
      },
      typeQueryParams: {},
    };
  },

  methods: {
    // 分页
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getGoods();
    },
    cateChangeToSearch() {
      this.searchKey = "";
      this.page.pageIndex = 1;
      this.getGoods();
    },
    // 结果中检索
    resultSearchClick() {
      this.page.pageIndex = 1;
      this.getGoods();
    },
    toSearch(data) {
      this.typeQueryParams = data;
      this.getGoods();
    },
    // 列表查询
    async getGoods() {
      const params = {
        ...this.page,
      };
      const { activeThridMenuId, activeSecondMenuId, activeFirstMenuId } =
        this.typeQueryParams;
      if (activeThridMenuId) {
        params.serviceCategoryThridId = activeThridMenuId;
      } else if (activeSecondMenuId) {
        params.serviceCategorySecondId = activeSecondMenuId;
      } else if (activeFirstMenuId) {
        params.serviceCategoryFirstId = activeFirstMenuId;
      }
      if (this.searchKey) {
        params.productmallWaresName = this.searchKey;
      }
      this.listLoading = true;
      const { list, totalItem } = await this.$axios
        .post(fineMallGoodList, params)
        .finally(() => {
          this.listLoading = false;
        });
      this.totalItem = totalItem;
      this.results = list || [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.mall {
  background: $home-bg;
  min-height: 550px;
  padding-top: 20px;
  padding-bottom: 20px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    /deep/ .el-tabs__nav-wrap::after {
      height: 1px;
    }
  }
  &-search {
    background: #f7faff;
    border-left: 1px solid #eeeff1;
    border-right: 1px solid #eeeff1;
    border-bottom: 1px solid #eeeff1;
    padding: 7px 53px;
    margin-bottom: 20px;
  }
  &-lists {
    min-height: 400px;
  }
}
.footer {
  padding-top: 0;
}
</style>
