<template>
  <div class="bill">
    <el-form :inline="true" :model="form" class="qForm" :size="queryFormSize">
      <el-form-item label="订单编号">
        <el-input
          v-model="form.demandTitle"
          placeholder="请输入订单编号"
          class="title"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票状态">
        <el-select
          v-model="form.demandStatus"
          placeholder="请选择状态"
          class="demandStatus"
          clearable
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in statusOption"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryBtnClick">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="myTable">
      <BillTable
        :data="tableData"
        @viewDetail="viewDetail"
        @pass="passClick"
        @reject="rejectClick"
      />
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="发票详细信息"
      :visible.sync="showDrawer"
      size="700px"
      class="drawer"
    >
      <BillDetail />
    </el-drawer>
    <!-- 通过弹窗 -->
    <PassDialog v-model="passVisible" />
    <!-- 驳回弹窗 -->
    <RejectDialog v-model="rejectVisible" />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import { QUERY_FORM_SIZE } from "@/constant/size";
import BillTable from "./components/BillTable.vue";
import BillDetail from "./components/BillDetail.vue";
import PassDialog from "./components/PassDialog.vue";
import RejectDialog from "./components/RejectDialog.vue";
export default {
  components: {
    Pagination,
    BillTable,
    BillDetail,
    PassDialog,
    RejectDialog,
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      passVisible: false,
      rejectVisible: false,
      form: {},
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 5,
      },
      statusOption: [
        { id: 1, name: "待审核" },
        { id: 2, name: "通过" },
        { id: 3, name: "驳回" },
      ],
      tableData: [
        {
          id: 1,
          orderNo: "2008311234560269",
          price: "350",
          head: "深圳市倍测国际检测",
          type: "增值税普通发票",
          attr: "电子",
          getType: "客户自取",
          status: 1,
        },
        {
          id: 2,
          orderNo: "2008311234560269",
          price: "350",
          head: "深圳市倍测国际检测",
          type: "增值税普通发票",
          attr: "电子",
          getType: "客户自取",
          status: 2,
        },
      ],
      showDrawer: false,
    };
  },
  methods: {
    handlePageChange() {},
    // 查询按钮点击
    queryBtnClick() {},
    // 查看详情
    viewDetail() {
      this.showDrawer = true;
    },
    // 通过按钮
    passClick() {
      this.passVisible = true;
    },
    // 驳回按钮
    rejectClick() {
      this.rejectVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.drawer {
  /deep/ .el-drawer__header span {
    font-weight: bold;
    font-size: 1.07rem;
  }
}
.bill {
  padding: 10px;
}
.qForm {
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
}
.myTable {
  min-height: 500px;
}
</style>
