<template>
  <div class="head flex">
    <div class="head-kf"><img src="@/assets/chartroom/serviceImg.png" /></div>
    <div class="head-name">
      {{ userBaseInfo && userBaseInfo.userRealName }}
    </div>
    <div class="head-status">
      <span class="head-statusLabel">在线状态：</span>
      <el-switch
        v-model="onlineStatus"
        active-color="#13ce66"
        @change="onlineStatusChange"
      >
      </el-switch>
      <span class="head-statusValue">{{ onlineStatus ? "在线" : "离线" }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
    onlineStatus: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    onlineStatusChange(val) {
      this.$emit("onlineChange", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  height: 40px;
  line-height: 40px;
  background: #deecff;
  padding: 0 10px;
  &-kf {
    margin-top: 6px;
    height: 30px;
    line-height: 30px;
    width: 30px;
    background: white;
    border-radius: 50%;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  &-name {
    margin-left: 10px;
  }
  &-status {
    margin-left: 20px;
  }
  &-statusValue {
    margin-left: 8px;
  }
}
</style>
