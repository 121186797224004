<template>
  <div>
    <HeadBar :showLogin="false" /><!--顶部bar-->
    <div class="regis flex flex-center">
      <div class="regis-inner">
        <el-card shadow="hover" class="regis-card">
          <div class="regis-title">
            <label>
              <span>企 业 入 驻</span>
              <em></em>
            </label>
          </div>
          <div class="flex flex-center">
            <el-form
              :model="form"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
            >
              <el-form-item label="企业名称" prop="enterpriseName">
                <el-input
                  v-model="form.enterpriseName"
                  placeholder="请输入企业名称"
                  class="width-big"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业地址" prop="enterpriseAddress">
                <el-input
                  v-model="form.enterpriseAddress"
                  placeholder="请输入企业地址"
                  class="width-big"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系人员" prop="enterpriseContactsName">
                <el-input
                  v-model="form.enterpriseContactsName"
                  placeholder="请输入联系人员"
                  class="width-big"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="enterpriseContactsPhone">
                <el-input
                  v-model="form.enterpriseContactsPhone"
                  placeholder="请输入联系电话"
                  class="width-big"
                ></el-input>
              </el-form-item>
              <div class="regis-bottom">
                <div class="regis-protocol">
                  <el-checkbox v-model="protocol">我已阅读并同意 </el-checkbox>
                  <a :href="$oss('用户协议.html', 'protocols')" target="blank"
                    >《平台用户协议》</a
                  >
                </div>
                <el-button
                  type="primary"
                  size="small"
                  plain
                  @click="register"
                  :loading="submitLoading"
                  >注&nbsp;&nbsp;册</el-button
                >
              </div>
            </el-form>
          </div>
        </el-card>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { settledEnterprise } from "@/api/enterprise";
import { sleep } from "@/utils/index";
export default {
  data() {
    return {
      submitLoading: false,
      form: {
        enterpriseName: "",
        enterpriseAddress: "",
        enterpriseContactsName: "",
        enterpriseContactsPhone: "",
      },
      protocol: false,
      rules: {
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        enterpriseAddress: [
          { required: true, message: "请输入企业地址", trigger: "blur" },
        ],
        enterpriseContactsName: [
          { required: true, message: "请输入联系人员", trigger: "blur" },
        ],
        enterpriseContactsPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async register() {
      try {
        await this.$refs.ruleForm.validate();
        if (!this.protocol) {
          this.$message.error("请先勾选平台协议");
          return;
        }
        this.submitLoading = true;
        await this.$axios
          .post(settledEnterprise, { ...this.form })
          .finally(() => {
            this.submitLoading = false;
          });
        this.$message.success("提交成功，请等待审核");
        await sleep(1000);
        this.$router.push({ name: "home" });
      } catch (e) {
        console.log(e);
        //
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$width: 800px;
.regis {
  border-top: 1px solid $border-color;
  &-inner {
    width: $width;
    max-width: $width;

    padding-top: 30px;
    min-height: 500px;
  }
  &-title {
    margin-bottom: 20px;
    text-align: center;
    > label {
      position: relative;
      span {
        position: relative;
        font-size: 1.57rem;
        font-weight: 500;
        z-index: 10;
      }
      em {
        width: 100%;
        height: 12px;
        background: #b7d6ff;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
      }
    }
  }
  &-bottom {
    padding-left: 100px;
    /deep/ .el-button {
      width: 150px;
    }
  }
  &-protocol {
    margin-bottom: 10px;
    > a {
      text-decoration: none;
      color: $theme-color;
    }
  }
}
.width-big {
  width: 310px;
}
.width-middle {
  width: 220px;
}
.width-small {
  width: 160px;
}
.code {
  display: inline-block;

  overflow: hidden;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  width: 80px;
  height: 34px;
  background: $border-color;
  margin-left: 10px;
  > img {
    width: 100%;
    height: 100%;
  }
}
.smsCaptcha {
  margin-left: 10px;
  width: 140px;
  /deep/ .el-button {
    width: 100%;
  }
}
</style>
