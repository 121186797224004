<template>
  <div class="mall">
    <div class="mall-head flex flex-row flex-between">
      <div class="mall-headTitle">设置商城信息</div>
      <div
        v-if="form.checkmallBusinessStatus !== mallStatusChecking"
        class="mall-headBtn"
      >
        <el-button
          type="primary"
          plain
          size="mini"
          @click="saveSubmit"
          :loading="saveLoading"
          >提交</el-button
        >
      </div>
    </div>
    <el-form
      class="mall-form"
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      v-loading="formLoading"
    >
      <el-form-item label="检测商城商家名称" prop="checkmallBusinessName">
        <el-input v-model="form.checkmallBusinessName"></el-input>
      </el-form-item>
      <div class="flex">
        <div>
          <el-form-item label="检测商城商家logo" prop="checkmallBusinessLogo">
            <ImgUpload
              @onSuccess="imgUploadSuccess"
              :imgs="form.checkmallBusinessLogo"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item
            label="商家联系人姓名"
            prop="checkmallBusinessContactsName"
          >
            <el-input v-model="form.checkmallBusinessContactsName"></el-input>
          </el-form-item>
          <el-form-item
            label="商家联系电话"
            prop="checkmallBusinessContactsPhone"
          >
            <el-input v-model="form.checkmallBusinessContactsPhone"></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="检测商城商家介绍" prop="checkmallBusinessBrief">
        <el-input
          v-model="form.checkmallBusinessBrief"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="审核状态"
        v-if="form.checkmallBusinessStatus"
        class="mall-formItemLast"
      >
        {{ mallStatus[+form.checkmallBusinessStatus] }}
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import ImgUpload from "@/components/ImgUpload";
import { joinOrUpdateStrictMall, getStrictMallDetail } from "@/api/userCenter";
import { MALL_STATUS, MALL_STATUS_CHECKING } from "@/constant/dict";
import RULE from "./rules";
export default {
  components: {
    ImgUpload,
  },
  data() {
    return {
      // （0-关闭；1-启用；2-审核中（默认审核中）；3-审核不通过）
      mallStatus: MALL_STATUS,
      mallStatusChecking: MALL_STATUS_CHECKING,
      form: {
        checkmallBusinessName: "",
        checkmallBusinessLogo: "",
        checkmallBusinessContactsName: "",
        checkmallBusinessContactsPhone: "",
        checkmallBusinessBrief: "",
      },
      saveLoading: false,
      formLoading: false,
      btnDisabled: false,
      rules: RULE.strictFormRule,
      dialogVisible: false,
    };
  },
  created() {
    this.getStrictMallDetail();
  },

  methods: {
    async getStrictMallDetail() {
      this.formLoading = true;
      const res = await this.$axios.get(getStrictMallDetail).finally(() => {
        this.formLoading = false;
      });
      if (res) {
        this.form = res;
        const { checkmallBusinessId, checkmallBusinessStatus } = res;
        this.$emit("setMallInfo", {
          businessId: checkmallBusinessId,
          status: checkmallBusinessStatus,
        });
      }
    },
    async saveSubmit() {
      await this.$refs.ruleForm.validate();
      this.saveLoading = true;
      console.log(this.form);
      // if (this.saveLoading) return;
      await this.$axios
        .post(joinOrUpdateStrictMall, {
          ...this.form,
        })
        .finally(() => {
          this.saveLoading = false;
        });
      this.$message.success("提交成功，请等待审核");
      this.getStrictMallDetail();
    },
    imgUploadSuccess(file) {
      this.form.checkmallBusinessLogo = file;
    },
    // 打开设置客服弹窗
    openKefuDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.mall {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  margin-bottom: 10px;
  &-head {
    border-bottom: 1px solid #dcdfe6;
    padding: 8px 10px;
    font-weight: bold;
  }
  &-headTitle {
    padding-top: 5px;
  }
  &-form {
    padding: 8px 10px 0 10px;
  }
  &-formItemLast {
    margin-bottom: 0;
  }
}
</style>
