<template>
  <div>
    <HeadBar :showLogin="false" />
    <!--顶部bar-->
    <div class="regis flex flex-center">
      <div class="regis-inner">
        <el-card shadow="hover" class="regis-card">
          <div class="regis-title">
            <label>
              <span>注&nbsp;&nbsp;册</span>
              <em></em>
            </label>
          </div>
          <div class="flex flex-center">
            <el-form
              :model="form"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
            >
              <el-form-item label="登录用户名" prop="userLoginName">
                <el-input
                  v-model="form.userLoginName"
                  placeholder="支持字母、数字、'-'、'_'的组合，4-16个字符"
                  class="width-big"
                ></el-input>
              </el-form-item>
              <el-form-item label="真实姓名" prop="userRealName">
                <el-input
                  v-model="form.userRealName"
                  placeholder="请输入真实姓名"
                  class="width-big"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  v-model="form.password"
                  type="password"
                  class="width-big"
                  placeholder="请输入密码，6-20个字符"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="passwordAgain">
                <el-input
                  v-model="form.passwordAgain"
                  type="password"
                  placeholder="请再次输入密码"
                  class="width-big"
                ></el-input>
              </el-form-item>
              <el-form-item label="电子邮箱" prop="userEmail">
                <el-input
                  v-model="form.userEmail"
                  placeholder="请输入电子邮箱"
                  class="width-big"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机" prop="userPhone">
                <el-input
                  v-model="form.userPhone"
                  placeholder="请输入手机号码"
                  class="width-big"
                ></el-input>
              </el-form-item>
              <el-form-item label="短信验证码" prop="smsCaptcha">
                <PhoneCode
                  v-model="form.smsCaptcha"
                  :phone="form.userPhone"
                  :codeType="registerCode"
                />
              </el-form-item>
              <div class="regis-bottom">
                <div class="regis-protocol">
                  <el-checkbox v-model="protocol">我已阅读并同意 </el-checkbox>
                  <a :href="$oss('用户协议.html', 'protocols')" target="blank"
                    >《平台用户协议》</a
                  >
                  <a
                    :href="$oss('用户注册隐私协议.html', 'protocols')"
                    target="blank"
                    >《全汇测平台用户注册隐私协议》</a
                  >
                </div>
                <el-button
                  type="primary"
                  size="small"
                  plain
                  @click="register"
                  :loading="submitLoading"
                  >注&nbsp;&nbsp;册</el-button
                >
              </div>
            </el-form>
          </div>
        </el-card>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { userRegister } from "@/api/login";
import { sleep } from "@/utils/index";
import { Base64 } from "js-base64";
import PhoneCode from "@/components/PhoneCode/index";
import { SYSCODE_TYPE } from "@/constant/index";
export default {
  components: {
    PhoneCode,
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的邮箱地址"));
      }
      callback();
    };

    const validatePwd = (rule, value, callback) => {
      const reg = /(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/;
      if (!reg.test(value)) {
        callback(new Error("密码6-16位，必须包含字母、数字、特殊字符"));
      }
      callback();
    };
    const validatePhone = (rule, value, callback) => {
      const reg =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      }
      callback();
    };
    const validateCode = (rule, value, callback) => {
      const reg = /^\d{6}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的短信验证码"));
      }
      callback();
    };
    return {
      registerCode: SYSCODE_TYPE.register,
      submitLoading: false,
      form: {
        userLoginName: "",
        userRealName: "",
        password: "",
        passwordAgain: "",
        userEmail: "",
        userPhone: "", //15820287923
        smsCaptcha: "",
      },
      protocol: false,
      rules: {
        userLoginName: [
          { required: true, message: "请输入登录用户名", trigger: "blur" },
          {
            min: 4,
            max: 16,
            message: "长度在 4 到 16 个字符",
            trigger: "blur",
          },
        ],
        userRealName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
          { validator: validatePwd, trigger: "blur" },
        ],
        passwordAgain: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
        userEmail: [
          { required: true, message: "请输入电子邮箱", trigger: "blur" },
          { validator: validateEmail, trigger: "blur" },
        ],
        userPhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: validatePhone, trigger: "blur" },
        ],
        smsCaptcha: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
          { validator: validateCode, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async register() {
      await this.$refs.ruleForm.validate();
      if (!this.protocol) {
        this.$message.error("请先勾选平台协议");
        return;
      }
      const {
        password,
        passwordAgain,
        smsCaptcha,
        userLoginName,
        userEmail,
        userPhone,
        userRealName,
      } = this.form;
      if (password !== passwordAgain) {
        this.$message.error("密码与确认密码不一致，请重新输入");
        return;
      }
      const params = {
        userLoginName,
        password: Base64.toBase64(password),
        smsCaptcha,
        userEmail,
        userPhone,
        userRealName,
      };
      // 个人用户注册
      this.submitLoading = true;
      await this.$axios.post(userRegister, params).finally(() => {
        this.submitLoading = false;
      });
      this.$message.success("注册成功");
      await sleep(1000);
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$width: 800px;
.regis {
  border-top: 1px solid $border-color;
  &-inner {
    width: $width;
    max-width: $width;

    padding-top: 30px;
    min-height: 500px;
  }
  &-title {
    margin-bottom: 20px;
    text-align: center;
    > label {
      position: relative;
      span {
        position: relative;
        font-size: 1.57rem;
        font-weight: 500;
        z-index: 10;
      }
      em {
        width: 100%;
        height: 12px;
        background: #b7d6ff;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
      }
    }
  }
  &-bottom {
    padding-left: 100px;
    /deep/ .el-button {
      width: 150px;
    }
  }
  &-protocol {
    margin-bottom: 10px;
    > a {
      text-decoration: none;
      color: $theme-color;
    }
  }
}
.width-big {
  width: 310px;
}
.width-middle {
  width: 220px;
}
.width-small {
  width: 160px;
}
.code {
  display: inline-block;

  overflow: hidden;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  width: 80px;
  height: 34px;
  background: $border-color;
  margin-left: 10px;
  > img {
    width: 100%;
    height: 100%;
  }
}
.smsCaptcha {
  margin-left: 10px;
  width: 140px;
  /deep/ .el-button {
    width: 100%;
  }
}
</style>
