<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="detail flex flex-center" v-loading="loading">
      <div class="detail-inner">
        <Brief :data="detail" />
        <Introduce :data="detail" />
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import Brief from "./components/Brief.vue";
import Introduce from "./components/Introduce.vue";
import { trainDetail } from "@/api/onlineTrain";
export default {
  components: {
    Brief,
    Introduce,
  },
  data() {
    return {
      id: null,
      detail: {},
      loading: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.loading = true;
      const data = await this.$axios
        .get(trainDetail, { id: this.id })
        .finally(() => {
          this.loading = false;
        });
      this.detail = data;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.detail {
  padding-top: 20px;
  &-inner {
    width: $max-width;
  }
}
</style>
