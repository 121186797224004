<template>
  <div class="flex message">
    <div class="message-label">用户留言：</div>
    <div class="flex-1">
      {{ data }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.message {
  background: #eff3f9;
  padding: 10px;
  &-label {
    font-weight: bold;
    width: 70px;
  }
}
</style>
