<template>
  <div class="flex flex-row flex-wrap">
    <ServiceItem
      v-for="(good, index) in data"
      :key="index"
      :info="good"
      :index="index + 1"
      :unitMap="unitMap"
    />
  </div>
</template>
<script>
import { SERVICE_UNIT_TYPE } from "@/constant/dict";
import ServiceItem from "./Item.vue";
export default {
  components: {
    ServiceItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      unitMap: {},
    };
  },
  created() {
    this.getDicts();
  },
  methods: {
    async getDicts() {
      const units = await this.$store.dispatch(
        "getDictData",
        SERVICE_UNIT_TYPE
      );
      const unitMap = {};
      units.forEach((item) => {
        unitMap[item.dictValue] = item.dictLabel;
      });
      this.unitMap = unitMap;
    },
  },
};
</script>
