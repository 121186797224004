<template>
  <div class="link flex flex-center">
    <div class="link-inner">
      <div class="link-theme flex flex-center">
        <div>友情链接</div>
      </div>
      <div v-loading="loading" class="link-block flex flex-row flex-wrap">
        <div
          v-for="(item, index) in links"
          :key="index"
          class="link-item"
          :class="{ isRight: (index + 1) % 4 === 0 }"
        >
          <el-select
            v-model="linkValue[index]"
            :placeholder="item.linksCategoryName"
            size="large"
            @change="linkClick"
          >
            <el-option
              v-for="(link, lIndex) in item.linksRespondList"
              :key="lIndex"
              :label="link.linksName"
              :value="link.linksAddress"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { friendlyLink } from "@/api/home";
export default {
  name: "modular_links",
  data() {
    return {
      links: [],
      loading: false,
      linkValue: { 0: null, 1: null, 2: null, 3: null, 4: null },
    };
  },
  created() {
    this.getLink();
  },
  methods: {
    async getLink() {
      const res = await this.$axios.get(friendlyLink);
      this.links = res;
    },
    linkClick(data) {
      window.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "../../common.scss";
.link {
  // background: #e8f1f6;
  padding: 30px 0;
  &-inner {
    width: $max-width;
    overflow: hidden;
  }
  &-theme {
    font-size: 22px;
    line-height: 34px;
    color: #2d65dd;
    border-bottom: 1px solid #dfdff1;
    margin-bottom: 45px;
    text-align: center;
    > div {
      padding-bottom: 5px;
      position: relative;
      &::after {
        display: inline-block;
        position: absolute;
        bottom: -1px;
        left: 0;
        content: "";
        width: 100%;
        height: 2px;
        background: #2f82ff;
      }
    }
  }
  &-block {
    min-height: 80px;
  }
  &-item {
    margin-right: 30px;
    margin-bottom: 30px;
    width: 282px;
    &.isRight {
      margin-right: 0;
    }
    > div {
      width: 100%;
    }
    /deep/ input::-webkit-input-placeholder {
      color: #666666;
      font-size: 14px;
    }
    /deep/ .el-input__inner {
      border: none;
      border-radius: 0;
      height: 48px;
      line-height: 48px;
    }
    /deep/ .el-select__caret {
      font-size: 20px;
      color: #4b4b4b;
    }
  }
}
</style>
