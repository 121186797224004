<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <Banner />
    <ClassCate @cateChange="cateChange" />
    <div class="con">
      <div class="videos" v-loading="classLoading">
        <Course :data="videos" />
      </div>

      <!-- 分页-->
      <!-- <Pagination
        :total="totalItem"
        :page="page"
        @pageIndexChange="handlePageChange"
      /> -->
    </div>

    <FootBar class="footer" />
  </div>
</template>
<script>
// import Pagination from "@/components/Pagination/index";
import ClassCate from "./components/ClassCate.vue";
import Course from "./components/Courses.vue";
import Banner from "./components/Banner.vue";
import { trainList } from "@/api/onlineTrain";
export default {
  components: {
    ClassCate,
    Course,
    // Pagination,
    Banner,
  },
  data() {
    return {
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 20,
      },
      loading: false,
      videos: [],
      currentCate: {},
      classLoading: false,
    };
  },
  methods: {
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getClasses();
    },
    async getClasses() {
      this.videos.length = 0;
      this.classLoading = true;
      const params = {
        ...this.page,
      };
      const { id, parent } = this.currentCate;
      if (id) {
        Object.assign(params, { categoryId: id, parent });
      }
      const data = await this.$axios.post(trainList, params).finally(() => {
        this.classLoading = false;
      });
      this.videos = data;
    },
    cateChange(item) {
      this.currentCate = item;
      this.getClasses();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.con {
  background: #f7f8f9;
  margin-bottom: 20px;
}
.videos {
  min-height: 400px;
}
.footer {
  padding-top: 0 !important;
}
</style>
