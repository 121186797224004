<template>
  <div class="pwd">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="原密码" prop="oldPassword">
        <el-input v-model="form.oldPassword"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="form.newPassword" type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="againPassword">
        <el-input v-model="form.againPassword" type="password"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" plain size="small" @click="submitBtn">
          提&nbsp;交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { updateUserInfo } from "@/api/login";
import { Base64 } from "js-base64";
export default {
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        againPassword: "",
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: "请输入原密码",
            trigger: ["blur", "change"],
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: ["blur", "change"],
          },
        ],
        againPassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    async submitBtn() {
      await this.$refs.form.validate();
      const { newPassword, oldPassword, againPassword } = this.form;
      if (newPassword !== againPassword) {
        this.$message.warning("新密码与确认密码不一致，请重新输入");
        return;
      }
      await this.$axios.post(updateUserInfo, {
        newPassword: Base64.toBase64(newPassword),
        oldPassword: Base64.toBase64(oldPassword),
        modifyType: "1", //(0-修改基本信息；1-修改密码；2-修改手机号)
      });
      this.$message.success("密码修改成功，请重新登录");
      this.$store.dispatch("Logout");
    },
  },
};
</script>
<style lang="scss" scoped>
.pwd {
  width: 500px;
  padding-top: 10px;
}
</style>
