<template>
  <div class="cate">
    <!-- <img :src="require(`../../../../assets/home/mall/cate${index}.png`)" /> -->
    <img :src="$oss(`home/cate${index}.png`)" />
    <div class="cate-con flex flex-column flex-between">
      <div>
        <div class="cate-title">{{ data.serviceCategoryName }}</div>
        <div class="cate-child">
          <span v-for="(item, index) in cateChild" :key="index"
            >{{ item.serviceCategoryName }}、</span
          >
        </div>
      </div>
      <div>
        <span class="cate-more pointer" @click="moreClick">查看更多</span>
      </div>
    </div>
  </div>
</template>
<script>
import { queryKind } from "@/api/mall";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cateChild: [],
      childLen: 0,
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    async getCategory() {
      // 获取顶级分类
      const pData = await this.$axios.get(queryKind, {
        superCategoryId: this.data.serviceCategoryId,
      });
      const max = 8;
      this.cateChild = pData.slice(0, Math.min(pData.length, max));
      this.childLen = this.cateChild.length;
    },
    moreClick() {
      this.$jump.push({
        name: "mallStrict",
        query: { cateId: this.data.serviceCategoryId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cate {
  width: 239px;
  height: 296px;
  position: relative;
  overflow: hidden;
  &-con {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 36px 30px 48px 30px;
    box-sizing: border-box;
  }
  &-title {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  &-child {
    color: white;
    line-height: 25px;
  }
  &-more {
    border: 1px solid #ffffff;
    border-radius: 14px;
    padding: 4px 12px;
    color: white;
    display: inline-block;
  }
}
</style>
