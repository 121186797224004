<template>
  <div class="page flex flex-center">
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      prev-text="上一页"
      next-text="下一页"
      :page-size="page.pageSize"
      :current-page="page.pageIndex"
      @current-change="pageIndexChange"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    pageIndexChange(index) {
      this.$emit("pageIndexChange", index);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  /deep/ .btn-prev {
    padding: 0 8px;
  }
  /deep/ .btn-next {
    padding: 0 8px;
  }
}
</style>
