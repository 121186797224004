<template>
  <div class="menu">
    <el-collapse v-model="collapse" accordion>
      <el-collapse-item
        :name="menu.title"
        v-for="(menu, index) in menus"
        :key="index"
      >
        <template slot="title">
          <div
            class="menu-title"
            :class="{ active: menuActive === menu.title }"
          >
            {{ menu.title }}
          </div>
        </template>
        <div
          v-for="child in menu.child"
          :key="child.pathName"
          class="menu-path pointer"
          :class="{ active: activePath === child.pathName }"
          @click="selectMenu(child.pathName)"
        >
          {{ child.title }}
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { filterAuth } from "@/utils/auth";
import { userIsCustomer } from "@/api/userCenter";
export default {
  props: {
    pathName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      collapse: "",
      menuActive: "",
      activePath: "",
      menus: [
        {
          title: "用户中心",
          child: [
            { title: "账号信息", pathName: "account" },
            { title: "我的需求", pathName: "myDemand" },
            { title: "我的消息", pathName: "myMessage" },
            { title: "我的优惠券", pathName: "myCoupons" },
            { title: "严选商城委托单", pathName: "strictMallTrustDeed" },
            { title: "优选商城订单", pathName: "superMallOrder" },
            // { title: "发票管理", pathName: "billManage" },
            { title: "地址管理", pathName: "addressManage" },
            { title: "我参与的培训", pathName: "userCenterOnlineTrain" },
            { title: "专家评估记录", pathName: "myApplyEstimate" },
            { title: "申请入驻为专家", pathName: "expertRegister" },
            { title: "会议管理", pathName: "meettingMange" },
          ],
        },
        // ...AuthMenu,
      ],
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  watch: {
    pathName(newVal) {
      this.setCollapseActive(newVal);
    },
  },
  created() {
    this.filterAuths();
    // 判断用户是否为客服
    this.judgeUserIsCustomer();
    this.setCollapseActive();
  },
  methods: {
    // 判断用户是否为客服
    async judgeUserIsCustomer() {
      const res = await this.$axios.get(userIsCustomer);
      if (res) {
        this.menus[0].child.push({ title: "咨询中心", pathName: "chartroom" });
      }
    },
    // 菜单权限过滤
    async filterAuths() {
      if (!this.userBaseInfo.userId) {
        await this.$store.dispatch("GetUserBaseInfo");
      }
      const { userJurisdictionList, isExpert } = this.userBaseInfo;

      const menus = [...filterAuth(userJurisdictionList)];
      if (isExpert) {
        menus.push({
          title: "专家中心",
          child: [
            {
              title: "申请评估管理",
              pathName: "applyEstimate",
            },
          ],
        });
      }
      this.menus.push(...menus);
    },
    selectMenu(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({ name: pathName });
      }
    },
    setCollapseActive(pathName = this.$route.name) {
      this.activePath = pathName;
      const menu = this.menus.find((item) => {
        const child = item.child.find((child) => child.pathName === pathName);
        return child;
      });
      this.collapse = menu.title;
      this.menuActive = menu.title;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.menu {
  height: 100%;
  /deep/ .el-collapse {
    border: none;
  }
  /deep/ .el-collapse-item__header {
    background: #f2f7ff;
    border: none;
    &:hover {
      background: #e3ecff;
    }
  }
  /deep/ .el-collapse-item__content {
    padding-bottom: 0;
  }
  &-title {
    font-size: 14px;
    color: #666666;
    padding-left: 23px;
    &.active {
      color: #4482ff;
      font-weight: bold;
    }
  }
  &-path {
    height: 46px;
    line-height: 46px;
    background: #f2f7ff;
    padding-left: 47px;
    border-left: 3px solid #f2f7ff;
    &:hover {
      background: #e3ecff;
    }
    &.active {
      background: #e3ecff;
      color: #4482ff;
      border-left: 3px solid #4482ff;
    }
  }
}
</style>
