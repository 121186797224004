// 严选商城
export const mallList = `/api/unoauth/checkmall/getRareCheckmallWaresList`; // 获取设备列表
export const deviceDetail = `/api/unoauth/checkmall/getRareCheckmallWaresDetailed`; // 获取设备详情
export const queryBrand = `/api/unoauth/checkmall/listCheckmallWaresBrand`; // 获取品牌
export const queryKind = `/api/unoauth/checkmall/listCheckmallWaresCategory`; // 获取分类
export const queryAddress = `/api/unoauth/checkmall/listCheckmallWaresCity`; // 获取地区

export const labInfo = `/api/unoauth/checkmall/getRareCheckmallBusiness`; // 根据实验室商户信息ID获取实验室商户信息

export const ceateStrictMallOrder = `/api/oauth/checkmallorder/setCheckmallOrder`; // 生产委托单
export const updateStrictOrderStatus = `/api/oauth/checkmallorder/setOrderState`; // 更新委托单状态

// 优选商城
export const fineMallGoodList = `/api/unoauth/productmall/order/selectProductmallByOrderList`; // 商城展示列表-获取产品商城产品列表
export const finaMallGoodDetail = `/api/unoauth/productmall/getProductmallWaresDetail`; // 获取产品商城产品详细信息
export const chooseedGoodList = `/api/oauth/productmall/order/confirmProductmallSelectWares`; // 确认产品商城选择的商品
export const createGoodOrder = `/api/oauth/productmall/order/insertProductmallOrder`; // 新增（确认）产品商城订单`

// 优选商城主页
export const getDiscountGoods = `/api/unoauth/homepage/selectProductmallListDiscounted`; // 折扣商品
export const getQualityShop = `/api/unoauth/homepage/selectProductmallTopBusinessAndWares`; // 获取优质店铺

// 获取店铺信息
export const getShopInfo = `/api/unoauth/productmallbusiness/getProductBusinessInfoById`;

// 商品详情页
export const addGoodToCart = `/api/oauth/productmall/shoppingCart/insertShoppingCart`; // 加入购物车
export const hotGoodRecommend = `/api/unoauth/productmall/getProductmallWaresHeatList`;

// 购物车页面
export const goodCartList = `/api/oauth/productmall/shoppingCart/selectUserProductmallShoppingCartList`; // 查找我的购物车商品列表
export const updateCartNum = `/api/oauth/productmall/shoppingCart/updateProductmallShoppingCartQuantity`; // 修改购物车商品数量
export const delteCart = `/api/oauth/productmall/shoppingCart/removeProductmallShoppingCartWares`; //修改购物车商品数量
export const cartCreateOrder = `/api/oauth/productmall/order/insertProductmallOrderByShoppingCart`; // 购物车修改订单

// 评价
//查询商品评价分类数量（全部、好评（4-5星）、中评（2-3星）、差评（1星））
export const evaluateCount = `/api/unoauth/evaluate/gradeNum`;
// 查询商品所有评价（审核通过）
export const evaluateListById = `/api/unoauth/evaluate/list`;
