<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="detail flex flex-center">
      <div class="detail-inner">
        <el-row :gutter="30">
          <el-col :span="17">
            <div class="detail-intro">
              <h1 class="detail-title">{{ detail.courseName }}</h1>
              <div class="detail-time">{{ detail.createTime }}</div>
            </div>
            <div class="detail-viewWrapper">
              <video
                class="video"
                :src="detail.courseUrl"
                autoplay
                loop
                controls
              ></video>
            </div>
          </el-col>
          <el-col :span="7">
            <div class="recommend-title">推荐视频</div>
            <div
              v-for="(item, index) in recommend"
              :key="index"
              class="flex flex-row recommend-item pointer"
              @click="toCourseDetail(item)"
            >
              <div class="recommend-cover"><img :src="item.courseLogo" /></div>
              <div class="flex-1">
                <div class="recommend-name">{{ item.courseName }}</div>
                <div class="recommend-time">{{ item.createTime }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { trainDetail, trainList } from "@/api/onlineTrain";
export default {
  data() {
    return {
      recommend: [],
      detail: {},
      page: {
        pageIndex: 1,
        pageSize: 5,
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
    this.getRecommend();
  },
  methods: {
    async getDetail() {
      this.loading = true;
      const data = await this.$axios
        .get(trainDetail, { id: this.id })
        .finally(() => {
          this.loading = false;
        });
      this.detail = data;
    },
    async getRecommend() {
      const data = await this.$axios.post(trainList, this.page);
      const res = data[0].onlineTrainCourseListResponds.slice(0, 5);
      this.recommend = res;
    },
    toCourseDetail(item) {
      this.$jump.push({
        name: "onlineTrainDetail",
        query: { id: item.courseId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.detail {
  &-inner {
    width: $max-width;
    padding-top: 20px;
  }
  &-viewWrapper {
    width: 100%;
    height: 604px; // 宽/高=1.42
    background: black;
  }
  &-intro {
    margin-bottom: 15px;
  }
  &-title {
    font-size: 1.28rem;
    font-weight: normal;
    margin-bottom: 5px;
  }
  &-time {
    font-size: 0.85rem;
    color: #999;
  }
}
.video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.recommend {
  &-title {
    font-size: 1.14rem;
    margin-bottom: 15px;
    padding-top: 24px;
  }
  &-item {
    margin-bottom: 20px;
  }
  &-cover {
    width: 160px;
    height: 104px;
    margin-right: 10px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  &-name {
    margin-bottom: 5px;
  }
  &-time {
    font-size: 0.85rem;
    color: #999;
  }
}
</style>
