<template>
  <div>
    <DemandItem v-for="(item, index) in data" :key="index" :data="item" />
  </div>
</template>
<script>
import DemandItem from "@/components/DemandItem/index.vue";
export default {
  components: {
    DemandItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
