<template>
  <div class="seek flex flex-column">
    <div class="seek-head flex flex-row flex-between">
      <div>客服：{{ customer.userRealName }}</div>
      <div><i class="el-icon-close pointer" @click="closeClick"></i></div>
    </div>
    <div class="flex-1 seek-conver">
      <Conversation :msgs="conversation" />
    </div>
    <SendMsg @send="sendBtnClick" />
  </div>
</template>
<script>
import io from "socket.io-client"; //引入socket.io-client
import Conversation from "./Conversation.vue";
import SendMsg from "./SendMsg.vue";
import msgMixin from "@/components/ConsultDialog/msgMixin.js";
import { mapGetters } from "vuex";
import { IDENTITY_CONSULT } from "@/constant/chart.js";
import { APISOCKET } from "@/api/constant";
export default {
  components: {
    Conversation,
    SendMsg,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    // 客服
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  mixins: [msgMixin],
  data() {
    return {
      socket: null,
      conversation: [],
      reviceInfo: {},
      userRoomInfo: {
        identity: IDENTITY_CONSULT,
      },
    };
  },
  created() {
    const { userId, userRealName } = this.customer;
    Object.assign(this.reviceInfo, {
      reviceId: userId,
      reviceName: userRealName,
    });
  },
  async mounted() {
    this.socket = io(APISOCKET);
    this.initSocketEvent();
    this.initSocketEventService();
    this.joinChat({
      ...this.reviceInfo, // 客服信息
    });
    this.conversation = [];
    const timeId = setTimeout(() => {
      const { connected } = this.socket;
      if (!connected) {
        this.$notify.error({
          title: "提示",
          message: "请联系系统管理员升级https协议",
          position: "bottom-right",
        });
      }

      clearTimeout(timeId);
    }, 2000);
  },
  methods: {
    reviceSocketMessage(data) {
      this.conversation.push(data);
    },
    initSocketEventService() {
      // 返回历史记录
      this.socket.on("historyMessage", (data) => {
        console.log("historyMessage", data);
        this.conversation.push(...data);
      });
    },
    closeClick() {
      this.closeSeesion();
      this.$emit("input", false);
    },
    sendBtnClick(val) {
      const { connected } = this.socket;
      if (connected) {
        this.sendMessage({
          ...this.reviceInfo,
          message: val,
        });
      } else {
        this.$message.error("请联系系统管理员升级https协议"); // 请联系系统管理员升级https协议
      }
    },
    // 咨询者加入房间成功后，获取历史消息记录
    joinSuccessCallback() {
      this.getHistory({
        customerId: this.customer.userId,
        consultId: this.userBaseInfo.userId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.seek {
  position: fixed;
  width: 450px;
  height: 500px;
  z-index: 2;
  right: 0;
  bottom: 0;
  background: white;
  border: 1px solid #4482ff;
  &-head {
    height: 35px;
    line-height: 35px;
    background: #4482ff;
    padding: 0 5px;
    color: white;
    i {
      font-size: 30px;
      margin-top: 2px;
    }
  }
  &-conver {
    overflow: auto;
  }
}
</style>
