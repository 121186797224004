<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="zone flex flex-center">
      <div class="zone-inner">
        <div class="zone-path">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>联盟专区</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="zone-block" v-loading="loading">
          <div
            v-for="(item, index) in lists"
            :key="index"
            class="flex zone-item"
            @click="zoneClick(item)"
          >
            <div class="zone-cover pointer"><img :src="item.leagueIco" /></div>
            <div class="flex-1 flex flex-column flex-between zone-brief">
              <div class="pointer">
                <h3 class="zone-title">{{ item.leagueName }}</h3>
                <div class="zone-intro">{{ item.leagueBrief }}</div>
                <div class="zone-member">
                  <label class="zone-label">联盟成员：</label
                  ><span>{{ item.memberNum }}家</span>
                </div>
                <div>
                  <label class="zone-label">最新活动：</label
                  ><span>{{ item.newActivityName }}</span>
                </div>
              </div>
              <div v-if="item.isJoinLeague == 0">
                <el-button
                  type="primary"
                  size="small"
                  @click.stop="joinAlliance(item)"
                  >加入联盟</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <FootBar />
  </div>
</template>
<script>
import { allianceList } from "@/api/allianceZone";
import Pagination from "@/components/Pagination/index";
import { mapGetters } from "vuex";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      totalItem: 0,
      lists: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    this.getList();
  },
  methods: {
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    async getList() {
      this.loading = true;
      const { list, totalItem } = await this.$axios
        .get(allianceList, {
          ...this.page,
        })
        .finally(() => {
          this.loading = false;
        });
      this.lists = list;
      this.totalItem = totalItem;
    },
    joinAlliance(item) {
      if (!this.token) {
        this.$store.dispatch("login");
        return;
      }
      this.$router.push({
        name: "joinAlliance",
        query: { allianceId: item.leagueId },
      });
    },
    zoneClick(item) {
      this.$router.push({
        name: "allianceDetail",
        query: { id: item.leagueId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.zone {
  padding-top: 30px;
  &-inner {
    width: $max-width;
    min-height: 500px;
  }
  &-path {
    margin-bottom: 30px;
  }
  &-block {
    min-height: 300px;
  }
  &-item {
    margin-bottom: 40px;
  }
  &-cover {
    width: 400px;
    height: 250px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-brief {
    padding-left: 30px;
  }
  &-title {
    margin-bottom: 10px;
  }
  &-intro {
    color: $font-color-gray;
    text-indent: 27px;
    max-width: 780px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
  }
  &-member {
    margin-bottom: 5px;
  }
  &-label {
    color: $theme-color;
  }
}
</style>
