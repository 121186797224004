<template>
  <div class="strict">
    <div class="strict-inner">
      <BlockHeader title="严选商城" />
      <div>
        <div
          v-for="(cate, index) in parentCates"
          :key="cate.serviceCategoryId"
          class="flex flex-row strict-line"
        >
          <Cate :data="cate" :index="index" />
          <div class="flex-1 strict-con">
            <Labs :cateId="cate.serviceCategoryId" />
            <Devices :cateId="cate.serviceCategoryId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryKind } from "@/api/mall";
import Cate from "./Cate.vue";
import Labs from "./Labs.vue";
import Devices from "./Devices.vue";
import BlockHeader from "../BlockHeader.vue";
export default {
  name: "modular_server_mall",
  components: {
    Cate,
    Labs,
    Devices,
    BlockHeader,
  },
  data() {
    return {
      parentCates: [],
      page: {
        pageIndex: 1,
        pageSize: 5,
      },
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    async getCategory() {
      // 获取顶级分类
      const pData = await this.$axios.get(queryKind, {
        superCategoryId: 0,
      });
      this.parentCates = pData;
    },
    moreClick(cate) {
      this.$jump.push({
        name: "mallStrict",
        query: { cateId: cate.serviceCategoryId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "@/style/common";
.strict {
  min-height: 400px;
  padding-bottom: 30px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    padding-top: 30px;
  }
  &-line {
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-con {
    background: white;
  }
}
</style>
