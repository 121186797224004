<template>
  <div class="typeWrapper flex flex-center">
    <div class="typeWrapper-inner">
      <el-tabs
        v-model="barActiveCateId"
        @tab-click="barActiveChange"
        class="tabs"
      >
        <el-tab-pane
          :label="item.serviceCategoryName"
          :name="item.serviceCategoryId + ''"
          v-for="(item, index) in parentCates"
          :key="index"
        >
        </el-tab-pane>
      </el-tabs>
      <div class="type-bread">
        <span class="type-title">筛选条件</span>
        <el-tag
          size="mini"
          closable
          class="type-tag pointer"
          v-for="(item, index) in selectTypes"
          :key="index"
          @click.stop="delTag(index)"
          @close.stop="delTag(index)"
        >
          <label class="type-label">{{ item.title }}：</label>
          <span class="type-name">{{ item.name }}</span>
        </el-tag>
      </div>
      <div class="collapse">
        <TypeSelectCheck
          :info="categorys"
          :delId="categorysDelId"
          title="类别"
          @selectItemChange="categorysSelectChange"
        />
        <TypeSelectCheck
          :info="brands"
          :delId="brandsDelId"
          v-show="brands.length"
          title="品牌"
          @selectItemChange="brandsSelectChange"
        />
        <TypeSelectChoose
          :info="address"
          :delId="addressDelId"
          @selectItemChange="citySelectChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { queryBrand, queryKind, queryAddress } from "@/api/mall";
import TypeSelectCheck from "./TypeSelectCheck";
import TypeSelectChoose from "./TypeSelectChoose";
export default {
  components: {
    TypeSelectCheck,
    TypeSelectChoose,
  },
  props: {
    parentCates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeNames: "1",
      barActiveCateId: null,
      selectTypes: [],
      categorys: [],
      // parentCates: [], // 顶级分类
      categorysDelId: null,
      brands: [],
      brandsDelId: null,
      address: [],
      originAddress: [],
      addressDelId: null,
    };
  },
  async created() {
    const {
      query: { cateId },
    } = this.$route;
    // 默认顶级分类
    this.barActiveCateId = `${cateId || this.parentCates[0].serviceCategoryId}`;
    await this.getCategory();
    this.getBrands();
    this.getAddress();
  },
  methods: {
    // 获取父类下的子类别
    async getCategory() {
      const data = await this.$axios.get(queryKind, {
        superCategoryId: this.barActiveCateId,
      });
      this.categorys = (data || []).map((item) => ({
        id: item.serviceCategoryId,
        name: item.serviceCategoryName,
      }));
    },
    // 获取品牌
    async getBrands() {
      const data = await this.$axios.get(queryBrand, {
        superCategoryId: this.barActiveCateId,
      });
      this.brands = (data || []).map((item, index) => ({
        id: index,
        name: item,
      }));
    },
    // 获取地址
    async getAddress() {
      if (this.originAddress.length) {
        this.address = [...this.originAddress];
        return;
      }
      const data = await this.$axios.get(queryAddress);
      const result = (data || []).map((item, index) => {
        const { province, city } = item;
        return {
          province,
          code: `p${index}`,
          city: city.map((c, cIndex) => {
            return {
              name: c,
              code: `p${index}c${cIndex}`,
            };
          }),
        };
      });
      this.address = [...result];
      this.originAddress = [...result];
    },
    // 类目改变
    categorysSelectChange(val) {
      const item = this.filterSelect(val);
      if (item) this.selectTypes.push({ ...item, title: "类别" });
      // 更新品牌
    },
    // 品牌改变
    brandsSelectChange(val) {
      const item = this.filterSelect(val);
      if (item) this.selectTypes.push({ ...item, title: "品牌" });
    },
    // 地区改变
    citySelectChange(val) {
      const item = this.filterSelect(val);
      if (item) this.selectTypes.push({ ...item, title: "地区" });
    },
    filterSelect(val) {
      const names = this.selectTypes.map(({ name }) => name);
      const addItem = val.find(({ name }) => !names.includes(name));
      if (addItem) {
        this.toSearch();
        return addItem;
      }
      const newNames = val.map(({ name }) => name);
      const delIndex = this.selectTypes.findIndex(
        ({ name }) => !newNames.includes(name)
      );
      this.selectTypes.splice(delIndex, 1);
      this.toSearch();
    },
    // 删除所选的item
    delTag(delIndex) {
      const delItem = this.selectTypes[delIndex];
      if (delItem.title === "类别") {
        this.categorysDelId = delItem.id;
      } else if (delItem.title === "品牌") {
        this.brandsDelId = delItem.id;
      } else if (delItem.title === "地区") {
        this.addressDelId = delItem.code;
      }
      this.selectTypes.splice(delIndex, 1);
      this.toSearch();
    },
    // 查询数据
    toSearch() {
      this.$emit("toSearch", {
        types: this.selectTypes,
        parentType: this.barActiveCateId,
      });
    },
    // 分类的切换
    barActiveChange() {
      // 情况所选类目
      this.selectTypes = [];
      this.categorys = [];
      this.brands = [];
      this.address = [];
      // 更新类别和品牌
      this.getCategory();
      this.getBrands();
      this.getAddress();
      // 查询数据
      this.toSearch();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.typeWrapper {
  padding-top: 20px;
  &-inner {
    position: relative;
    width: $max-width;
  }
}
.tabs {
  /deep/ .el-tabs__header {
    margin-bottom: 0px;
  }
}
.type {
  &-bread {
    margin-top: 8px;
    min-height: 30px;
    line-height: 30px;
  }
  &-title {
    margin-right: 13px;
  }
  &-tag {
    margin-right: 10px;
    margin-bottom: 10px;

    height: 29px;
    line-height: 29px;
    padding: 0 9px;
    border: none;
    background: #f5f5f5;
    border-radius: 0;
    /deep/ .el-tag__close {
      font-size: 1.28rem;
      &:hover {
        color: #3073f4;
        background: #f5f5f5;
      }
    }
  }
  &-label {
    color: #6d6d6d;
  }
  &-name {
    margin-right: 5px;
  }
}
.collapse {
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}
</style>
