<template>
  <div class="child flex flex-row flex-wrap">
    <div
      v-for="(item, index) in cates"
      :key="index"
      class="child-item ellipsis pointer"
      :class="{
        isLast: [cates.length, cates.length - 1, cates.length - 2].includes(
          index + 1
        ),
        isLineLast: (index + 1) % 3 === 0,
      }"
      @click="childClick(item)"
    >
      {{ item.serviceCategoryName }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cate: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      catesLen: 0,
    };
  },
  computed: {
    cates() {
      const max = 12;
      const lineLen = 3;
      const spliceData = this.cate.slice(0, Math.min(this.cate.length, max));
      const spliceDataLength = spliceData.length;
      if (spliceDataLength < lineLen) {
        for (let i = 0; i < lineLen - spliceDataLength; i++) {
          spliceData.push({ serviceCategoryName: "" });
        }
      } else if (spliceDataLength % lineLen > 0) {
        for (let i = 0; i < spliceDataLength % lineLen; i++) {
          spliceData.push({ serviceCategoryName: "" });
        }
      }
      return spliceData;
    },
  },
  methods: {
    childClick(data) {
      this.$jump.push({
        name: "mallStrict",
        query: { cateId: data.serviceCategoryPid },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common";
@import "@/style/variable";
.child {
  &-item {
    width: 75px;
    margin-bottom: 15px;
    font-size: 18px;
    color: #303030;
    margin-right: 34px;
    &:hover {
      color: $theme-color;
    }
    &.isLast {
      margin-bottom: 0 !important;
    }
    &.isLineLast {
      margin-right: 0 !important;
    }
  }
}
</style>
