<template>
  <div class="cate">
    <div class="cate-inner flex flex-row">
      <div
        class="flex-1 flex flex-column"
        v-for="(item, index) in data"
        :key="index"
      >
        <div class="cate-pName">{{ item.serviceCategoryName }}</div>
        <CateChild
          :cate="item.child"
          class="cate-item flex-1"
          :class="{ [`cate${index + 1}`]: true }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CateChild from "./CateChild.vue";
export default {
  components: {
    CateChild,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.cate {
  background: white;
  padding-bottom: 52px;
  &-inner {
    width: $max-width;
    min-height: 150px;
    margin: 0 auto;
  }
  &-pName {
    padding: 30px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #303030;
  }
  &-item {
    padding-left: 50px;
  }
}
.cate2 {
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
}
</style>
