<template>
  <div class="meet">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="正在召开的会议" name="first"> </el-tab-pane>
      <el-tab-pane label="即将开始的会议" name="second"> </el-tab-pane>
      <el-tab-pane label="已结束的会议" name="third"> </el-tab-pane>
    </el-tabs>
    <div>
      <el-form :inline="true" :model="form" class="qForm" :size="queryFormSize">
        <el-form-item label="会议号">
          <el-input
            v-model="form.meetingCode"
            placeholder="请输入会议号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryBtnClick">查询</el-button>
          <el-button
            type="primary"
            plain
            v-if="activeName === 'first'"
            @click="createMeetingBtn"
            >创建会议</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <MeetingTable :data="tableData" v-loading="tableLoading" />
    <CreateMeedingDialog v-model="createVisible" />
  </div>
</template>
<script>
import MeetingTable from "./components/MeetingTable.vue";
import { QUERY_FORM_SIZE } from "@/constant/size";
import CreateMeedingDialog from "./components/CreateMeeting.vue";
import { queryMeetting } from "@/api/meetting";
export default {
  components: {
    MeetingTable,
    CreateMeedingDialog,
  },
  data() {
    return {
      activeName: "first",
      tableData: [],
      queryFormSize: QUERY_FORM_SIZE,
      form: {
        meetingCode: null,
      },
      createVisible: false,
      tableLoading: false,
      tabMap: {
        first: "1",
        second: "2",
        third: "0",
      },
    };
  },
  created() {
    this.queryMeeting();
  },
  methods: {
    createMeetingBtn() {
      this.createVisible = true;
    },
    handleClick() {
      this.queryMeeting();
    },
    queryBtnClick() {
      this.queryMeeting();
    },
    async queryMeeting() {
      this.tableLoading = true;
      const params = {
        ...this.form,
        status: this.tabMap[this.activeName],
      };
      const res = await this.$axios.post(queryMeetting, params).finally(() => {
        this.tableLoading = false;
      });
      this.tableData = res;
    },
  },
};
</script>
<style lang="scss" scoped>
.meet {
  padding: 10px;
}
.qForm {
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-date-editor {
    width: 300px;
  }
}
</style>
