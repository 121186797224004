<template>
  <div class="abi">
    <div class="abi-time">实验室检测能力</div>
    <div>
      <div
        class="flex flex-row abi-item"
        v-for="(item, index) in ability"
        :key="index"
      >
        <div class="abi-cate">{{ item.laboratoryAbilityTypeName }}</div>
        <div class="abi-bar flex-1">
          <div :style="{ width: `${item.radio}%` }"></div>
        </div>
        <div class="abi-num">{{ item.childrenCount }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { abilitySort } from "@/api/home";
export default {
  data() {
    return {
      ability: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$axios.get(abilitySort);
      const vals = res.map(({ childrenCount }) => childrenCount);
      const maxVals = Math.max(...vals);
      res.forEach((item) => {
        item.radio = ((item.childrenCount / maxVals) * 100).toFixed(0);
      });
      this.ability = res;
    },
  },
};
</script>
<style lang="scss" scoped>
.abi {
  &-time {
    font-size: 1.14rem;
    color: #4483ff;
    text-align: center;
    margin-bottom: 20px;
  }
  &-item {
    height: 15px;
    line-height: 15px;
    margin-bottom: 15px;
  }
  &-cate {
    color: #525d9e;
    margin-right: 16px;
    width: 110px;
    text-align: right;
  }
  &-bar {
    background: white;
    margin-top: 2px;
    height: 12px;
    > div {
      height: 100%;
      background: linear-gradient(-47deg, #0ed3f8, #0072fd);
    }
  }
  &-num {
    margin-left: 8px;
    color: #838383;
    width: 50px;
  }
}
#abi-chart {
  height: 450px;
  width: 100%;
}
</style>
