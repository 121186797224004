<template>
  <div class="ads flex flex-row">
    <div class="flex-1">
      <el-carousel
        :interval="40000"
        arrow="always"
        height="290px"
        class="swipper"
      >
        <el-carousel-item v-for="(item, index) in banners" :key="index">
          <div class="ads-banner pointer">
            <img :src="item.bannerPath" @click="bannerClick(item)" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="ads-ad pointer" @click="adClick()"></div>
  </div>
</template>
<script>
import { homeBanner } from "@/api/home";
export default {
  data() {
    return {
      banners: [],
    };
  },
  created() {
    this.getBanners();
  },
  methods: {
    adClick() {
      window.open("http://xitu.cssn.net.cn/baotou/front/index.jsp");
    },
    async getBanners() {
      const res = await this.$axios.get(homeBanner, { bannerType: "3" });
      this.banners = res;
      console.log(res);
    },
    bannerClick({ bannerLink }) {
      if (bannerLink) {
        window.open(bannerLink);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ads {
  margin-bottom: 16px;
  &-ad {
    width: 224px;
    height: 290px;
    background: url("https://store-img.cre-tep.com/frontDeskProject/images/home/mallAd.jpg");
    background-size: 100% 100%;
    margin-left: 22px;
  }
  &-banner {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
