<template>
  <div class="intro">
    <div class="intro-inner flex flex-row">
      <div class="intro-logo">
        <img :src="detail.productmallBusinessLogo" />
      </div>
      <div class="flex-1">
        <div class="intro-name">{{ detail.productmallBusinessName }}</div>
        <div class="flex flex-row">
          <div class="intro-line intro-tel">
            <label>联&nbsp;&nbsp;系&nbsp;&nbsp;人</label>
            <span>{{ detail.productmallBusinessContactsName }}</span>
          </div>
          <div class="intro-line">
            <label>电&nbsp;&nbsp;&nbsp;&nbsp;话</label>
            <span>{{ detail.productmallBusinessContactsPhone }}</span>
          </div>
        </div>
        <div class="intro-line flex flex-row">
          <div>
            <label>简&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;介</label>
          </div>
          <div>
            <span>{{ detail.productmallBusinessBrief }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getShopInfo } from "@/api/mall";
export default {
  data() {
    return {
      shopId: null,
      detail: {},
    };
  },
  created() {
    const { id } = this.$route.query;
    this.shopId = id;
    this.queryShopInfo();
  },
  methods: {
    async queryShopInfo() {
      const res = await this.$axios.get(getShopInfo, {
        BusinessId: this.shopId,
      });
      this.detail = res;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.intro {
  height: 213px;
  background: #198eff;
  color: #ffffff;
  background: url("https://store-img.cre-tep.com/frontDeskProject/images/mallSuperShop/mallBg.jpg");
  background-size: 100% 100%;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    padding-top: 36px;
  }
  &-logo {
    width: 117px;
    height: 117px;
    background: #ffffff;
    margin-right: 18px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 14px;
    position: relative;
    top: -5px;
  }
  &-line {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    label {
      margin-right: 28px;
    }
  }
  &-tel {
    margin-right: 78px;
  }
}
</style>
