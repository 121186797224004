<template>
  <div class="type">
    <div class="type-inner">
      <div class="flex flex-row flex-wrap">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="type-item pointer"
          :class="{ active: activeIndex === index }"
          @click="typeClick(item, index)"
        >
          {{ item.laboratoryAbilityTypeName }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    typeClick(item, index) {
      this.activeIndex = index;
      this.$emit("typeClick", item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.type {
  &-inner {
    width: $max-width;
    margin: 0 auto;
    background: #ffffff;
    padding: 8px 10px 3px 10px;
    min-height: 30px;
    margin-bottom: 17px;
    box-sizing: border-box;
  }
  &-item {
    padding: 3px 7px;
    margin-right: 20px;
    margin-bottom: 5px;
    &:hover,
    &.active {
      background: #e3ecff;
      color: #4482ff;
    }
  }
}
</style>
