<template>
  <div class="experts">
    <div class="experts-inner">
      <BlockHeader title="专家库" />
      <div class="flex flex-row types">
        <el-tabs v-model="activeName" class="flex-1" @tab-click="tabClick">
          <el-tab-pane
            :label="cate.fieldCategoryName"
            :name="`${cate.fieldCategoryId}`"
            v-for="(cate, index) in cates"
            :key="index"
          ></el-tab-pane>
        </el-tabs>
        <div class="types-all pointer" @click="toList">全部领域</div>
      </div>
      <div class="user" v-loading="listLoading">
        <template v-if="lists.length > 4">
          <swiper
            :options="swiperOption"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide v-for="(item, index) in lists" :key="index">
              <Expert :data="item" :eduMap="eduMap" />
            </swiper-slide>
          </swiper>
        </template>
        <template v-else>
          <Expert
            v-for="(item, index) in lists"
            :key="index"
            :data="item"
            :eduMap="eduMap"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { EDU_GRADE } from "@/constant/dict";
import { expertCate, expertList } from "@/api/expert.js";
import Expert from "@/components/Expert/index";
import BlockHeader from "../BlockHeader.vue";
export default {
  name: "modular_expert",
  components: {
    Expert,
    Swiper,
    SwiperSlide,
    BlockHeader,
  },
  data() {
    return {
      activeName: "",
      cates: [],
      lists: [],
      page: {
        pageIndex: 1,
        pageSize: 20,
      },
      listLoading: false,
      eduMap: {},
      swiperOption: {
        slidesPerView: 4,
        autoplay: {
          delay: 2000,
        },
        loop: true,
      },
    };
  },
  created() {
    this.getDict();
    this.getType();
  },
  methods: {
    onSwiper() {},
    onSlideChange() {},
    async getDict() {
      const res = await this.$store.dispatch("getDictData", EDU_GRADE);
      const eduMap = {};
      res.forEach((item) => {
        eduMap[item.dictValue] = item.dictLabel;
      });
      this.eduMap = eduMap;
    },
    async getType() {
      const data = await this.$axios.post(expertCate, {});
      this.cates = data;
      const defaultId = data[0].fieldCategoryId;
      this.activeName = `${defaultId}`;
      this.getList();
    },
    async getList() {
      this.listLoading = true;
      this.lists.length = 0;
      const { list } = await this.$axios
        .post(expertList, {
          ...this.page,
          fieldCategoryId: this.activeName,
        })
        .finally(() => {
          this.listLoading = false;
        });
      const result = list || [];
      result.forEach((item) => {
        const fields = [];
        item.fieldExes.forEach((field) => {
          fields.push(field.fieldCategoryName);
        });
        Object.assign(item, { fields });
      });
      this.lists = result;
    },
    tabClick() {
      this.getList();
    },
    toDetail(data) {
      this.$jump.push({ name: "expertsDetail", query: { id: data.expertId } });
    },
    toList() {
      this.$jump.push({ name: "expertsList" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "@/style/common";
.experts {
  padding-top: 35px;
  padding-bottom: 20px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
}
.types {
  margin-bottom: 40px;
  /deep/ .el-tabs__item {
    font-size: 18px;
    height: 55px;
    line-height: 55px;
  }
  &-all {
    border-bottom: 2px solid #e4e7ed;
    height: 53px;
    line-height: 55px;
    font-size: 18px;
    &:hover {
      color: #3073f4;
    }
  }
}
.user {
  min-height: 422px;
}
</style>
