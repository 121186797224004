<template>
  <div class="set flex flex-column">
    <div class="set-head" :style="{ background: `url(${bg})` }">
      {{ title }}
    </div>
    <div class="flex-1 set-scroll" ref="scroll">
      <vue-seamless-scroll :data="lists" class="items">
        <div class="items-inner">
          <div
            v-for="(item, index) in lists"
            :key="index"
            class="flex flex-1 flex-between items-line"
          >
            <div class="items-title ellipsis">{{ item.title }}</div>
            <div>{{ item.date }}</div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
    <div class="set-btn pointer" @click="$emit('btnClick')">{{ btnText }}></div>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "",
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    lists: {
      type: Array,
      default: () => [],
    },
    bg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.set {
  width: 280px;
  height: 328px;
  background: #ffffff;
  margin-left: 15px;
  &-head {
    width: 100%;
    height: 73px;
    line-height: 73px;
    background-size: 100% 100%;
    text-align: center;
    font-size: 18px;
    color: white;
  }
  &-btn {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #5b8ff9;
  }
  &-scroll {
    overflow: hidden;
  }
}
.items {
  height: 215px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  &-inner {
    padding: 0 18px;
  }
  &-line {
    padding: 8px 0px;
    border-bottom: 1px solid #eaeef1;
  }
  &-title {
    max-width: 160px;
  }
}
</style>
