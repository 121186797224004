<template>
  <div class="shop">
    <div class="shop-inner">
      <div class="shop-title">优 质 店 铺</div>
      <div class="flex flex-row flex-wrap">
        <div
          class="item flex flex-row"
          v-for="(item, index) in shops"
          :key="index"
          :class="{ even: (index + 1) % 2 == 0 }"
        >
          <div class="item-left">
            <img
              class="bg"
              :src="
                require(`../../../assets/mall/mallHome/labBg${index + 1}.png`)
              "
            />
            <div class="lab">
              <div class="lab-logo">
                <img :src="item.productmallBusinessLogo" />
              </div>
              <div class="lab-name ellipsis">
                {{ item.productmallBusinessName }}
              </div>
              <div class="lab-goodNum">{{ item.waresCount }}件商品</div>
              <div class="lab-more">
                <span class="pointer" @click="toShopDetail(item)"
                  >点击查看</span
                >
              </div>
            </div>
          </div>
          <div class="flex-1 goods flex flex-row flex-wrap">
            <div
              v-for="(child, cIndex) in item.topBusinessWares"
              :key="`c${cIndex}`"
              class="goods-item pointer"
              :class="{ isFirst: cIndex == 0 }"
              @click="goodClick(child)"
            >
              <div class="goods-cover">
                <img
                  :src="child.productmallWaresImage.split(';')[0]"
                  v-if="child.productmallWaresImage"
                />
                <img v-else :src="$oss('common/default_device.png')" />
              </div>
              <div class="goods-name ellipsis">
                {{ child.productmallWaresName }}
              </div>
              <div class="goods-price">
                <span v-if="!child.productmallWareMinprice">面议</span>
                <spna>￥{{ child.productmallWareMinprice }}</spna>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getQualityShop } from "@/api/mall";
export default {
  data() {
    return {
      shops: [],
    };
  },
  created() {
    this.getShop();
  },
  methods: {
    async getShop() {
      const res = await this.$axios.get(getQualityShop);
      this.shops = res;
    },
    async goodClick(data) {
      this.$jump.push({
        name: "goodsDetail",
        query: { id: data.productmallWaresId },
      });
    },
    toShopDetail(data) {
      this.$jump.push({
        name: "mallSuperShop",
        query: { id: data.productmallBusinessId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.shop {
  margin-bottom: 33px;
  &-inner {
    width: $max-width;
  }
  &-title {
    font-size: 27px;
    font-weight: 300;
    color: #0d0c26;
    text-align: center;
    margin-bottom: 32px;
  }
}
.item {
  width: 597px;
  margin-bottom: 24px;
  &.even {
    margin-left: 26px;
  }
  &-left {
    width: 221px;
    height: 207px;
    position: relative;
    .bg,
    .lab {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    .lab {
      z-index: 1;
    }
  }
}
.lab {
  text-align: center;
  &-logo {
    padding: 11px 0;
    > img {
      background: #ffffff;
      width: 76px;
      height: 76px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &-name {
    font-size: 17px;
    color: #ffffff;
    max-width: 150px;
    margin-bottom: 25px;
  }
  &-goodNum {
    font-size: 15px;
    color: #ffffff;
    margin-bottom: 5px;
  }
  &-more {
    span {
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      padding: 1px 11px;
      background: white;
    }
  }
}
.goods {
  padding-left: 23px;
  padding-top: 29px;
  background: white;
  &-item {
    &.isFirst {
      margin-right: 34px;
    }
  }
  &-cover {
    width: 148px;
    height: 100px;
    margin-bottom: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-name {
    font-size: 13px;
    color: #666666;
    max-width: 120px;
    margin-bottom: 5px;
  }
  &-price {
    font-size: 17px;
    font-weight: bold;
    color: #333333;
    line-height: 17px;
  }
}
</style>
