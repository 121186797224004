<template>
  <div class="pro">
    <div class="pro-inner">
      <div class="pro-head">检测项目</div>
      <div class="flex flex-row flex-wrap pro-con">
        <div
          class="item pointer"
          :class="{ isRight: (index + 1) % 4 == 0 }"
          v-for="(item, index) in products"
          :key="index"
          @click="abilityClick(item)"
        >
          <img class="item-img" :src="item.abilityImage" />
          <div class="item-desc">
            <div class="item-name ellipsis">{{ item.abilityName }}</div>
            <div class="item-brief">{{ item.laboratoryAbilityTypeName }}</div>
          </div>
        </div>
      </div>
      <!-- 分页-->
      <Pagination
        :total="totalItem"
        :page="page"
        @pageIndexChange="handlePageChange"
      />
    </div>
  </div>
</template>
<script>
import { labCheckProjects } from "@/api/lab";
import Pagination from "@/components/Pagination/index";
export default {
  name: "hotService",
  components: {
    Pagination,
  },
  data() {
    return {
      products: [],
      loading: true,
      page: {
        pageIndex: 1,
        pageSize: 12,
      },
      totalItem: 0,
    };
  },
  created() {
    this.toSearch();
  },
  methods: {
    toDetailPage(item) {
      this.$jump.push({
        name: "checkProjectDetail",
        query: { id: item.laboratoryAbilityId },
      });
    },
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.toSearch();
    },
    async toSearch() {
      this.loading = true;
      const { list, totalItem } = await this.$axios
        .post(labCheckProjects, {
          laboratoryId: this.$route.query.id,
          ...this.page,
        })
        .finally(() => {
          this.loading = false;
        });

      this.products = list;
      this.totalItem = totalItem;
    },
    abilityClick(data) {
      this.$router.push({
        name: "checkProjectDetail",
        query: { id: data.laboratoryAbilityId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.pro {
  background: $home-bg;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  &-head {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  &-con {
    min-height: 100px;
  }
}
.item {
  width: 278px;
  margin-right: 29px;
  margin-bottom: 30px;
  &.isRight {
    margin-right: 0;
  }
  &-img {
    width: 100%;
    height: 222px;
    object-fit: cover;
  }
  &-desc {
    padding: 16px;
    background: white;
  }
  &-name {
    font-weight: bold;
    color: #333333;
    margin-bottom: 4px;
    font-size: 16px;
  }
  &-brief {
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
