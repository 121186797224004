<template>
  <div class="member">
    <el-tabs v-model="activeName">
      <el-tab-pane label="联盟成员" name="first">
        <div class="member-con" v-loading="loading">
          <div
            v-for="(item, index) in members"
            :key="index"
            class="flex flex-row member-item"
          >
            <div class="member-logo"><img :src="item.laboratoryLogo" /></div>
            <div class="flex-1 member-detail">
              <div class="member-name">
                <el-tag
                  size="mini"
                  :type="index ? '' : 'danger'"
                  class="member-role"
                  >{{ roleMap[item.applyDuties] }}</el-tag
                >
                <label>{{ item.memberName }}</label>
              </div>
              <div class="member-brief">{{ item.laboratoryBrief }}</div>
            </div>
          </div>
        </div>
        <!-- 分页-->
        <Pagination
          :total="totalItem"
          :page="page"
          @pageIndexChange="handlePageChange"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ALLIANCE_ROLE } from "@/constant/dict";
import { allianceMembers } from "@/api/allianceZone";
import Pagination from "@/components/Pagination/index";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      activeName: "first",
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 5,
      },
      members: [],
      roleMap: {},
      loading: false,
    };
  },
  async created() {
    this.getDict();
    this.getMembers();
  },
  methods: {
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getMembers(0);
    },
    async getMembers() {
      this.loading = true;
      const { totalItem, list } = await this.$axios
        .get(allianceMembers, { leagueId: this.$route.query.id, ...this.page })
        .finally(() => {
          this.loading = false;
        });
      this.totalItem = totalItem;
      this.members = list;
    },
    async getDict() {
      const res = await this.$store.dispatch("getDictData", ALLIANCE_ROLE);
      const dMap = {};
      res.forEach((item) => {
        dMap[item.dictValue] = item.dictLabel;
      });
      this.roleMap = dMap;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.member {
  margin-bottom: 20px;
  /deep/ .el-tabs__header .el-tabs__item {
    font-size: 1.28rem !important;
    font-weight: bold;
  }
  &-logo {
    width: 200px;
    height: 90px;
    border: 1px solid $border-color;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-con {
    padding-top: 10px;
    min-height: 200px;
  }
  &-item {
    margin-bottom: 25px;
  }
  &-detail {
    padding-left: 20px;
  }
  &-name {
    margin-bottom: 10px;
    font-size: 0.85rem;
    font-weight: unset;
    > label {
      font-size: 1.14rem;
      font-weight: bold;
    }
  }
  &-role {
    margin-right: 5px;
  }
  &-brief {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
  }
}
</style>
