<template>
  <div>
    <HeadBar />
    <HeadNav />
    <ShopIntro />
    <div class="shop">
      <div class="shop-type">
        <div class="shop-typeInner">
          <GoodsType @toSearch="toSearch" />
        </div>
      </div>
      <div class="shop-search">
        <div class="shop-searchInner">
          <SearchBar
            :num="totalItem"
            v-model="searchKey"
            @btnClick="resultSearchClick"
          />
        </div>
      </div>
      <div class="shop-lists" v-loading="listLoading">
        <div class="flex flex-wrap">
          <GoodItem
            v-for="(item, index) in results"
            :key="index"
            :good="item"
            :index="index"
          />
        </div>
      </div>
    </div>

    <FootBar class="footerBar" />
  </div>
</template>
<script>
import SearchBar from "@/components/SearchBar/index";
import GoodsType from "@/components/GoodTypesSelect/index";
import ShopIntro from "./components/Intro.vue";
import GoodItem from "@/components/GoodItem/index";
import { fineMallGoodList } from "@/api/mall";
export default {
  components: {
    ShopIntro,
    GoodsType,
    SearchBar,
    GoodItem,
  },
  data() {
    return {
      shopId: null,
      listLoading: false,
      searchKey: "",
      totalItem: 0,
      shop: {},
      results: [],
      page: {
        pageIndex: 1,
        pageSize: 20,
      },
    };
  },
  created() {
    const { id } = this.$route.query;
    this.shopId = id;
  },
  methods: {
    toSearch(data) {
      this.typeQueryParams = data;
      this.getGoods();
    },
    resultSearchClick() {
      this.page.pageIndex = 1;
      this.getGoods();
    },
    // 列表查询
    async getGoods() {
      const params = {
        ...this.page,
        productmallBusinessId: this.shopId,
      };
      const { activeThridMenuId, activeSecondMenuId, activeFirstMenuId } =
        this.typeQueryParams;
      if (activeThridMenuId) {
        params.serviceCategoryThridId = activeThridMenuId;
      } else if (activeSecondMenuId) {
        params.serviceCategorySecondId = activeSecondMenuId;
      } else if (activeFirstMenuId) {
        params.serviceCategoryFirstId = activeFirstMenuId;
      }
      if (this.searchKey) {
        params.productmallWaresName = this.searchKey;
      }
      this.listLoading = true;
      const { list, totalItem } = await this.$axios
        .post(fineMallGoodList, params)
        .finally(() => {
          this.listLoading = false;
        });
      this.totalItem = totalItem;
      this.results = list || [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.shop {
  background: $home-bg;
  min-height: 500px;
  &-type {
    background: white;
  }
  &-typeInner {
    width: $max-width;
    margin: 0 auto;
    padding-top: 10px;
  }
  &-search {
    background: #f7faff;
    border-left: 1px solid #eeeff1;
    border-right: 1px solid #eeeff1;
    border-bottom: 1px solid #eeeff1;
    padding: 7px 53px;
    margin-bottom: 42px;
  }
  &-searchInner {
    width: $max-width;
    margin: 0 auto;
  }
  &-lists {
    width: $max-width;
    margin: 0 auto;
  }
}
.footerBar {
  padding-top: 0 !important;
}
</style>
