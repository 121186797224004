<template>
  <div class="lab">
    <div class="lab-inner">
      <BlockHeader title="热门实验室" />
      <div class="flex flex-row flex-wrap">
        <el-card
          shadow="hover"
          v-for="(item, index) in labs"
          :key="index"
          class="lab-item pointer"
          @click.native="toLabDetail(item)"
        >
          <div class="lab-logo"><img :src="item.laboratoryLogo" /></div>
          <div class="ellipsis lab-name">{{ item.laboratoryName }}</div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import { hotLab } from "@/api/home";
import BlockHeader from "../BlockHeader.vue";
export default {
  name: "modular_laboratory",
  components: {
    BlockHeader,
  },
  data() {
    return {
      labs: [],
      page: {
        pageIndex: 1,
        pageSize: 15,
      },
    };
  },
  created() {
    this.getLabs();
  },
  methods: {
    toLabDetail({ laboratoryId }) {
      this.$jump.push({ name: "labDetail", query: { id: laboratoryId } });
    },
    async getLabs() {
      const { list } = await this.$axios.get(hotLab, this.page);
      this.labs = list;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "../../common.scss";
.lab {
  padding-bottom: 25px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    padding-top: 20px;
  }
  &-item {
    width: 190px;
    margin: 0px 10px 10px 0;
  }
  &-logo {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-name {
    text-align: center;
    width: 100%;
  }
}
</style>
