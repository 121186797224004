<template>
  <el-drawer
    title="申请评估"
    :visible.sync="showDrawer"
    size="700px"
    class="drawer"
  >
    <div class="lists" v-loading="pageLoading">
      <div class="lists-line flex flex-row">
        <div class="lists-label">申请人名称：</div>
        <div class="flex-1">{{ form.userName }}</div>
      </div>
      <div class="lists-line flex flex-row">
        <div class="lists-label">申请评估描述：</div>
        <div class="flex-1 lists-content">{{ form.reviewApplyContent }}</div>
      </div>
      <div class="lists-line flex flex-row">
        <div class="lists-label">申请评估附件：</div>
        <div class="flex-1">
          <div class="lists-files" v-if="form.filePaths.length">
            <div
              v-for="(item, index) in form.filePaths"
              :key="index"
              class="lists-file"
              @click="fileClick(item)"
            >
              {{ item.fileName }}
            </div>
          </div>
          <div v-else>无</div>
        </div>
      </div>
      <div class="lists-line flex flex-row">
        <div class="lists-label">申请时间：</div>
        <div class="flex-1">{{ form.createTime | transformTime }}</div>
      </div>
      <div class="lists-line flex flex-row">
        <div class="lists-label">专家名称：</div>
        <div>{{ form.expertName }}</div>
      </div>
      <div class="lists-line flex flex-row">
        <div class="lists-label">答复记录：</div>
        <div
          class="flex-1 lists-records"
          v-if="form.recordList.length"
          v-loading="blockLoading"
        >
          <div
            v-for="(item, index) in form.recordList"
            :key="index"
            class="flex flex-row record-item"
            :class="{
              isService: item.reviewRecordEnter == '1',
              isCustomer: item.reviewRecordEnter == '0',
            }"
          >
            <div class="flex flex-row">
              <!--1：客服-->
              <div v-if="item.reviewRecordEnter === '1'" class="record-avator">
                <img src="@/assets/chartroom/serviceImg.png" />
              </div>
              <div class="content">
                <div class="content-head">
                  <span class="content-senderName">{{
                    item.reviewRecordEnter === "1" ? "专家" : "我"
                  }}</span>
                  <span class="content-time">
                    {{ item.createTime | transformTime }}</span
                  >
                </div>
                <div class="content-text">{{ item.reviewRecordContent }}</div>
              </div>
              <!--2：咨询者-->
              <div v-if="item.reviewRecordEnter === '0'" class="record-avator">
                <img src="@/assets/chartroom/customerImg.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1" v-else>无</div>
      </div>
      <div
        class="lists-line flex flex-row lists-submit"
        v-if="form.reviewApplyStatus === statusOpen"
      >
        <div class="lists-label">
          {{
            userBaseInfo.userRealName === form.expertName
              ? "专家答复"
              : "申请评估"
          }}：
        </div>
        <div class="flex-1">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="submitContent"
          >
          </el-input>
          <div class="lists-submitBtn">
            <el-button
              type="primary"
              plain
              size="small"
              :loading="btnLoading"
              @click="submitBtnClick"
              >提交</el-button
            >
          </div>
        </div>
      </div>
      <div v-else class="lists-line flex flex-row">
        <div class="lists-label">评估状态：</div>
        <div class="flex-1">评审关闭</div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { getEstimateDetail, insertEstimateRecord } from "@/api/expert";
import { parseTime } from "@/utils/index";
import { mapGetters } from "vuex";
import { EST_APPLY_STATUS_OPEN } from "@/constant/dict";

export default {
  components: {},
  filters: {
    transformTime(val) {
      return parseTime(val);
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    isExpert: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      btnLoading: false,
      pageLoading: false,
      blockLoading: false,
      submitContent: "",
      statusOpen: EST_APPLY_STATUS_OPEN, // 评审中
      form: {
        userName: "",
        reviewApplyContent: "",
        filePaths: [],
        recordList: [],
        createTime: null,
        expertName: "",
        recovery: "",
      },
    };
  },
  computed: {
    showDrawer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ...mapGetters(["userBaseInfo"]),
  },
  created() {
    this.pageLoading = true;
    this.getDetail();
  },
  methods: {
    fileClick(data) {
      window.open(data.filePath);
    },
    async getDetail() {
      const { reviewApplyId } = this.data;
      const res = await this.$axios
        .get(getEstimateDetail, {
          reviewApplyId,
        })
        .finally(() => {
          this.pageLoading = false;
          this.blockLoading = false;
        });
      this.form = res;
    },
    async submitBtnClick() {
      const { reviewApplyId } = this.data;
      this.btnLoading = true;
      await this.$axios
        .post(insertEstimateRecord, {
          reviewRecordContent: this.submitContent,
          reviewApplyId,
          reviewRecordEnter: this.isExpert ? "1" : "0", // 评审内容记录发送者类别（0-评审申请用户发送内容；1-专家发送内容
        })
        .finally(() => {
          this.btnLoading = false;
        });
      this.$message.success("提交成功");
      this.blockLoading = true;
      this.getDetail();
      this.submitContent = "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.lists {
  padding: 0 30px;
  &-label {
    width: 100px;
    font-weight: bold;
  }
  &-line {
    margin-bottom: 20px;
  }
  &-files,
  &-content,
  &-records {
    border: 1px solid $border-color;
    border-radius: 5px;
    padding: 10px;
  }
  &-file {
    color: #409eff;
    line-height: 30px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &-submit {
    border-top: 1px dashed $border-color;
    padding-top: 20px;
  }
  &-submitBtn {
    margin-top: 10px;
  }
}
.record {
  &-item {
    margin-bottom: 10px;
    &.isService {
      justify-content: flex-start;
      .record-avator {
        margin-right: 10px;
        margin-left: 5px;
      }
    }
    &.isCustomer {
      justify-content: flex-end;
      .record-avator {
        margin-left: 10px;
        margin-right: 5px;
      }
    }
  }
  &-avator {
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.content {
  &-text {
    background: $home-bg;
    border-radius: 5px;
    padding: 8px 10px;
    position: relative;
  }
  &-head {
    font-size: 0.92rem;
    color: $font-color-gray;
    margin-bottom: 2px;
    padding-left: 5px;
  }
  &-senderName {
    margin-right: 5px;
  }
  &-sys {
    width: 100%;
    text-align: center;
    > span {
      background: $home-bg;
      padding: 5px 15px;
      display: inline-block;
      border-radius: 15px;
      color: #747474;
    }
  }
  &-time {
    font-size: 12px;
  }
}
</style>
