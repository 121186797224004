<template>
  <div class="flex flex-row flex-wrap">
    <GoodItem
      v-for="(item, index) in data"
      :key="index"
      :good="item"
      :index="index"
    />
  </div>
</template>
<script>
import GoodItem from "@/components/GoodItem/index";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    GoodItem,
  },
};
</script>
