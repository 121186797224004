<template>
  <div class="bar">
    <div class="bar-inner flex flex-row flex-between">
      <div>
        <span class="bar-home pointer" @click="toHome">首页</span>
      </div>
      <div>
        <span class="bar-gray">当前所在的位置：用户中心</span>
        <span class="bar-gray bar-name"
          >登录用户：{{ userBaseInfo && userBaseInfo.userRealName }}</span
        >
        <span class="bar-out pointer" @click="logout">安全退出</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  methods: {
    toHome() {
      this.$router.push({ name: "home" });
    },
    async logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.bar {
  height: 60px;
  line-height: 60px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-home {
    font-size: 16px;
    font-weight: bold;
    color: #4482ff;
  }
  &-gray {
    color: #888888;
  }
  &-name {
    margin: 0 26px;
    padding: 0 26px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 4px;
      height: 13px;
      width: 1px;
      background: #dbdbdb;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 4px;
      height: 13px;
      width: 1px;
      background: #dbdbdb;
    }
  }
  &-out {
    color: #4482ff;
  }
}
</style>
