<template>
  <div>
    <MyTable :columns="columns" :data="data" border>
      <div slot="startTime" slot-scope="{ row }">
        {{ (row.startTime * 1000) | transformTime }}
      </div>
      <div slot="status" slot-scope="{ row }">
        <span :class="{ [`status${row.status}`]: true }">{{
          statusMap[row.status]
        }}</span>
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-button
          type="success"
          plain
          size="mini"
          v-if="row.status === '1'"
          @click="joinMeetting(row)"
          >立即进入</el-button
        >
      </div>
    </MyTable>
  </div>
</template>
<script>
import MyTable from "@/components/Table/index";
import { parseTime } from "@/utils/index";
export default {
  components: {
    MyTable,
  },
  filters: {
    transformTime(val) {
      return parseTime(val, "{y}-{m}-{d} {h}:{i}");
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      columns: [
        { type: "index", label: "#" },
        { label: "开始时间", slotName: "startTime", width: 160 },
        { label: "会议主题", prop: "subject" },
        { label: "会议号", prop: "meetingCode", width: 120 },
        // { label: "创建者", prop: "creater", width: 90 },
        { label: "会议状态", slotName: "status", width: 90 },
        { label: "操作", slotName: "action", width: 120 },
      ],
      statusMap: {
        0: "已结束",
        1: "进行中",
        2: "即将开始",
      },
    };
  },
  methods: {
    joinMeetting(data) {
      window.open(data.joinUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
.status0 {
  color: #909399;
}
.status1 {
  color: #67c23a;
}
.status2 {
  color: #409eff;
}
</style>
