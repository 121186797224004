<template>
  <div class="leave">
    <div class="leave-top">
      <div class="leave-title">用户留言</div>
    </div>
    <div class="leave-con">
      <el-input
        type="textarea"
        :rows="3"
        placeholder="请输入内容"
        resize="none"
        v-model="message"
      >
      </el-input>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    message: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.leave {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  margin-bottom: 20px;
  &-top {
    padding: 8px 15px;
    border-bottom: 1px solid $border-color;
  }
  &-title {
    font-size: 1.14rem;
  }
  &-con {
    padding: 15px;
  }
}
</style>
