<template>
  <div class="mail">
    <div class="mail-top">
      <div class="mail-title">样品寄送地址信息</div>
    </div>
    <div class="mail-con">
      <div class="mail-list">
        <label>地址：</label>
        <span> {{ data.laboratoryLocationProvince }}</span>
        <span> {{ data.laboratoryLocationCity }}</span>
        <span> {{ data.laboratoryLocationCounty }}</span>
        <span> {{ data.laboratoryAddress }}</span>
      </div>
      <div class="mail-list flex">
        <div class="mail-person">
          <label>联系人：</label>
          <span> {{ data.checkmallBusinessContactsName }}</span>
        </div>
        <div>
          <label>电话：</label>
          <span> {{ data.checkmallBusinessContactsPhone }}</span>
        </div>
      </div>
      <div class="mail-list">
        <label>服务方：</label>
        <span> {{ data.checkmallBusinessName }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {},
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.mail {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  margin-bottom: 20px;
  &-top {
    padding: 8px 15px;
    border-bottom: 1px solid $border-color;
  }
  &-title {
    font-size: 1.14rem;
  }
  &-con {
    padding: 15px;
  }
  &-list {
    margin-bottom: 10px;
  }
  &-person {
    margin-right: 50px;
  }
}
</style>
