<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="pay">
      <div class="pay-inner">
        <div class="pay-head flex flex-row flex-between">
          <div class="pay-headTip">
            <span>请及时支付，以便订单尽快处理!</span>
            <span class="pay-no">订单号: D211208918167162269470720</span>
          </div>
          <div class="pay-price">
            <label>应付金额:</label>
            <span class="pay-money">￥678.90</span>
          </div>
        </div>
        <div class="con flex flex-row">
          <div class="con-code">
            <div class="con-qr">
              <img src="@/assets/pay/code.jpg" />
            </div>
            <div class="con-sao">
              <img src="@/assets/pay/sao.png" />
            </div>
          </div>
          <div class="con-phone">
            <img src="@/assets/pay/phone.png" />
          </div>
        </div>
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.pay {
  background: $home-bg;
  padding-bottom: 100px;
  padding-top: 40px;
  &-inner {
    width: 980px;
    margin: 0 auto;
  }
  &-head {
    margin-bottom: 30px;
  }
  &-headTip {
    color: #55595c;
    font-size: 18px;
  }
  &-no {
    margin-left: 15px;
  }
  &-price {
    color: #55595c;
    font-size: 18px;
    font-weight: bold;
  }
  &-money {
    margin-left: 10px;
    color: #f95e5e;
  }
}
.con {
  background: white;
  padding-bottom: 50px;
  &-code {
    margin-left: 140px;
    margin-top: 100px;
  }
  &-qr {
    width: 250px;
    height: 250px;
    padding: 15px;
    border: 1px solid #e4e5e8;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-sao {
    text-align: center;
    img {
      width: 238px;
    }
  }
  &-phone {
    width: 346px;
    height: 470px;
    margin-left: 100px;
    margin-top: 50px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
}
.footer {
  padding-top: 0;
}
</style>
