<template>
  <div class="cate">
    <div class="cate-inner">
      <div class="cate-parent flex flex-row flex-wrap" v-loading="loading">
        <div
          v-for="(item, index) in allCates"
          :key="index"
          class="cate-pName pointer"
          :class="{ active: parentActiveIndex === index, isFirst: !index }"
          @click="firstCateClick(index, item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="cate-child flex flex-row flex-wrap">
        <div
          v-for="(item, index) in currentChildCates"
          :key="index"
          class="cate-pName pointer"
          :class="{ active: childActiveIndex === index, isFirst: !index }"
          @click="childCateClick(index, item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { trainCate } from "@/api/onlineTrain";
export default {
  data() {
    return {
      defaultItem: { id: 0, name: "全部" },
      parentActiveIndex: 0,
      allCates: [],
      currentChildCates: [],
      childActiveIndex: 0,
      loading: false,
    };
  },
  async created() {
    await this.getAllCate();
    this.firstCateClick(0, this.defaultItem);
  },
  methods: {
    async getAllCate() {
      this.loading = true;
      const data = await this.$axios.get(trainCate).finally(() => {
        this.loading = false;
      });
      this.allCates = [this.defaultItem, ...data];
    },
    firstCateClick(index, item) {
      this.parentActiveIndex = index;
      if (item.name === "全部") {
        const child = [];
        this.allCates.forEach((item) => {
          item.childTree && child.push(...item.childTree);
        });
        this.currentChildCates = [{ id: 0, name: "全部" }, ...child];
      } else {
        this.currentChildCates = [
          { id: 0, name: "全部" },
          ...(this.allCates[index].childTree || []),
        ];
      }

      this.childActiveIndex = 0;
      this.$emit("cateChange", { ...item, parent: true });
    },
    childCateClick(index, item) {
      this.childActiveIndex = index;
      if (index) {
        this.$emit("cateChange", { ...item, parent: false });
      } else {
        this.$emit("cateChange", {
          ...this.allCates[this.parentActiveIndex],
          parent: true,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.cate {
  background: #ffffff;
  padding-top: 19px;
  padding-bottom: 19px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-parent {
    margin-bottom: 20px;
  }
  &-pAll {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-right: 10px;
  }
  &-pName {
    margin-left: 60px;
    font-size: 16px;
    margin-bottom: 10px;
    &.isFirst {
      margin-left: 0px;
    }
    &.active {
      color: #4482ff;
      // font-weight: bold;
    }
  }
  &-cAll {
    width: 40px;
  }
}
</style>
