<template>
  <div class="base">
    <div>
      <label>委托人员：</label><span>{{ row.orderLinkman }}</span>
    </div>
    <div>
      <label>委托单位：</label><span>{{ row.orderCompany }}</span>
    </div>
    <div>
      <label>服务名称：</label><span>{{ row.checkmallWaresName }}</span>
    </div>
    <div>
      <label>委托金额：</label
      ><span class="base-price">￥{{ row.orderPrice }}元</span>
    </div>
    <div>
      <label>下单时间：</label><span>{{ row.createTime }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
.base {
  padding: 10px;
  > div {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
    > label {
      color: #909399;
    }
  }
  &-price {
    color: #f56c6c;
  }
}
</style>
