<template>
  <el-dialog
    title="修改订单价格"
    :visible.sync="dialogVisible"
    width="600px"
    class="dialog"
  >
    <div>
      <el-form ref="form" label-width="120px">
        <el-form-item label="订单原价："> 400元 </el-form-item>
        <el-form-item label="修改价格：">
          <el-input v-model="type"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      // dialogVisible: false,
      type: "",
    };
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  /deep/ .el-dialog__body {
    padding: 0px 20px;
  }
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
}
</style>
