<template>
  <div class="dev">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      class="dev-form"
    >
      <div class="flex flex-row">
        <el-form-item label="设备名称" prop="equipmentName" class="flex-1">
          <el-input
            v-model="form.equipmentName"
            placeholder="请输入设备名称"
            :maxlength="100"
          />
        </el-form-item>
        <el-form-item label="设备型号" class="flex-1">
          <el-input
            v-model="form.equipmentModel"
            placeholder="请输入设备型号"
            :maxlength="120"
          />
        </el-form-item>
      </div>
      <div class="flex flex-row">
        <el-form-item label="设备品牌" class="flex-1">
          <el-input
            v-model="form.equipmentBrand"
            placeholder="请输入设备品牌"
            :maxlength="120"
          />
        </el-form-item>
        <el-form-item label="设备编码" class="flex-1">
          <el-input
            v-model="form.equipmentCode"
            placeholder="请输入设备编码"
            :maxlength="120"
          />
        </el-form-item>
      </div>
      <el-form-item label="设备图片" prop="equipmentImage">
        <ImageUpload
          :imgs="form.equipmentImage"
          @onSuccess="equipmentImageSuccess"
        />
      </el-form-item>
      <el-form-item label="主要参数">
        <el-input
          v-model="form.equipmentParameter"
          type="textarea"
          placeholder="请输入内容"
          :rows="3"
          :maxlength="300"
        />
      </el-form-item>
      <el-form-item label="">
        <el-button
          type="primary"
          plain
          size="small"
          @click="saveBtn"
          :loading="loading"
          v-if="isEdit || !curId"
          >保存</el-button
        >
        <div class="dev-tip">温馨提示：审核通过后自动上架</div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import ImageUpload from "@/components/ImgUpload";
import { saveDevice, deviceDetail } from "@/api/userCenter";
export default {
  props: {
    curId: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImageUpload,
  },
  data() {
    return {
      form: {
        equipmentName: "",
        equipmentModel: "",
        equipmentBrand: "",
        equipmentCode: "",
        equipmentImage: "",
        equipmentParameter: "",
      },
      rules: {
        equipmentName: [
          {
            required: true,
            message: "请输入设备名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      loading: false,
    };
  },
  created() {
    if (this.curId) {
      this.getDetail();
    }
  },
  methods: {
    async getDetail() {
      const res = await this.$axios.get(deviceDetail, {
        equipmentId: +this.curId,
      });
      Object.assign(this.form, res);
    },
    equipmentImageSuccess(fileUrls) {
      Object.assign(this.form, { equipmentImage: fileUrls });
    },
    async saveBtn() {
      await this.$refs.form.validate();
      this.loading = true;
      await this.$axios.post(saveDevice, { ...this.form }).finally(() => {
        this.loading = false;
      });
      this.$message.success("保存成功");
      this.$emit("saveSuccess");
    },
  },
};
</script>
<style lang="scss" scoped>
.dev {
  &-form {
    padding-right: 20px;
  }
  &-tip {
    color: #f56c6c;
  }
}
</style>
