<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="news">
      <div class="news-inner">
        <div class="news-path flex flex-row">
          <div class="news-pathTitle">我的位置：</div>
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb"
          >
            <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'newsList' }"
              >新闻</el-breadcrumb-item
            >
            <el-breadcrumb-item>正文</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="detail" v-loading="pageLoading">
          <div class="detail-title">{{ detail.articleTitle }}</div>
          <div class="detail-intro">
            <label>
              <span>发布时间：</span><span>{{ detail.publishTime }}</span>
            </label>
            <label v-if="detail.articleSource">
              <span>来源：</span><span>{{ detail.articleSource }}</span>
            </label>
          </div>
          <div class="detail-brief" v-if="detail.articleBrief">
            <span>[简介]</span>
            {{ detail.articleBrief }}
          </div>
          <EditorView :data="detail.articleContent" />
        </div>
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import { newsDetail } from "@/api/article";
export default {
  data() {
    return {
      detail: {},
      pageLoading: false,
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.pageLoading = true;
      const res = await this.$axios
        .get(newsDetail, {
          articleId: this.$route.query.id,
        })
        .finally(() => {
          this.pageLoading = false;
        });
      this.detail = res;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.news {
  background: #f5f5f5;
  min-height: 550px;
  padding-bottom: 100px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    // margin-bottom: 25px;
  }
  &-path {
    height: 50px;
    line-height: 50px;
    color: #666666;
  }
}
.breadcrumb {
  line-height: 50px;
}
.detail {
  background: white;
  padding: 0 30px 30px 30px;
  &-title {
    padding: 48px 0 24px 0;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    border-bottom: 1px solid #dfe5ec;
  }
  &-intro {
    text-align: center;
    padding: 18px 0 20px 0;
    font-size: 16px;
    color: #999999;
  }
  &-brief {
    font-size: 18px;
    background: #f7f8f9;
    padding: 25px 24px;
    line-height: 30px;
    margin-bottom: 22px;
    span {
      color: #ff5400;
    }
  }
}
</style>
