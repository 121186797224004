export const DEMAND = "demand_status"; // 需求状态
export const PAY_STATUS = "order_payment_status"; // 订单支付状态
export const PAY_TYPE = "order_pay_type"; // 订单支付类型
export const SERVICE_UNIT_TYPE = "server_unit_type"; // 服务计价单位
export const SERVICE_DISCOUNT_TYPE = "discount_status"; // 服务折扣
export const FREE_MAIL = "is_free_mail"; //  商品是否包邮
export const SERVICE_STATUS = "mall_wares_status"; // 服务状态

export const GOODS_ORDER_STATUS = "productmall_order_status"; // 优选商城订单状态
export const GOODS_DISCOUNT = "discount_status"; // 优选商城折扣
export const GOODS_SHELF_STATUS = "mall_wares_status"; // 商品状态
export const GOODS_WEIGTH_STATUS = "platform_weight_sequence"; // 商品推荐权重
export const SUB_CONTRACT_TYPE = "subcontract_type"; // 是否接收分包
export const CHECK_REPORT_DELIVERY_TYPE = "check_report_delivery_type"; // 检测报告交付方式
export const SAMPLE_MAIL_TYPE = "sample_mail_type"; // 样品送样方式
export const ALLIANCE_ROLE = "apply_duties"; // 联盟专区成员角色
export const COMPANY_NATURE = "member_company_nature"; // 企业性质
// 实验室检测能力
export const ABILITY_STATUS = "ability_status"; // 实验室检测能力状态
export const ABILITY_STATUS_DOWN = "0"; // 实验室检测能力下架
export const ABILITY_STATUS_UP = "1"; // 实验室检测能力上架
export const ABILITY_STATUS_CHECK = "2"; // 实验室检测能力待审核
export const TRAIN_ORDER_STATUS = "course_order_status"; // 培训课程订单状态
export const LAB_USER_STATUS = "laboratory_relation_status"; // 实验室关联用户状态
export const LAB_USER_STATUS_PASS = "1"; // 实验室关联用户状态-审核通过
export const ENT_USER_STATUS = "enterprise_relation_status"; // 企业关联用户状态
export const ENT_USER_STATUS_PASS = "1"; // 企业关联用户状态-审核通过
// 商城状态
export const MALL_STATUS = {
  0: "关闭",
  1: "启用",
  2: "审核中",
  3: "审核不通过",
};
export const MALL_STATUS_FAILD = "3"; //"审核不通过"
export const MALL_STATUS_CHECKING = "2"; //"审核中"
export const ARTICLE_TYPE = "article_type"; // 文章类型
export const ARTICLE_TYPE_XWZX = "1";
export const ARTICLE_TYPE_ZHXX = "2";
export const ARTICLE_TYPE_ZCFG = "3";
export const ARTICLE_TYPE_XWTG = "13";
export const EXPERT_STATUS = "expert_status"; // 专家状态
export const EXPERT_STATUS_DISABLE = "0"; // 禁用
export const EXPERT_STATUS_CHECKING = "2"; // 审核中
export const EDU_GRADE = "expert_education"; // 学历
export const EDU_DEGREE = "expert_academic_degree"; // 学位
export const EST_APPLY_STATUS = "review_apply_status"; // 申请评估的内容状态
export const EST_APPLY_STATUS_CLOSE = "0"; // 专家评审关闭
export const EST_APPLY_STATUS_OPEN = "1"; // 专家评审开启
export const USER_SEX = "sys_user_sex"; // 性别
export const DEVICE_STATUS = "equipment_status"; // 设备状态
export const DEVICE_STATUS_DOWN = "0"; // 设备已下架
export const DEVICE_STATUS_UP = "1"; // 设备已上架
export const DEVICE_STATUS_CHECK = "2"; // 设备待审核
export const COUPON_STATUS_UNUSED = "0"; // 优惠券-未使用
export const COUPON_STATUS_USED = "1"; // 优惠券-已使用
export const COUPON_STATUS_EXPIRED = "2"; // 优惠券-已过期

// 用户中心：消息、通知
export const NOTICE_STATUS = "rare_notice_is_read";

export const JOURNAL_TYPE = "goods_category"; // 优质产品，期刊
