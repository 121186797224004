<template>
  <div class="tabs">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="联盟介绍" name="first">
        <EditorView :data="data.leagueDesc" />
      </el-tab-pane>
      <el-tab-pane label="组织架构" name="second">
        <EditorView :data="data.leagueOrganImage" />
      </el-tab-pane>
      <el-tab-pane label="联盟章程" name="third">
        <EditorView :data="data.leagueRules" />
      </el-tab-pane>
      <el-tab-pane label="联盟协议" name="five">
        <EditorView :data="data.leagueProtocol" />
      </el-tab-pane>
      <el-tab-pane label="联系方式" name="fourth">
        <div class="tabs-contact">
          <label>地址:</label><span>{{ data.leagueAddress }}</span>
        </div>
        <div class="tabs-contact">
          <label>电话:</label><span>{{ data.leagueTelephone }}</span>
        </div>
        <div class="tabs-contact">
          <label>Email:</label><span>{{ data.leagueEmail }}</span>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeName: "first",
    };
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  margin-bottom: 20px;
  /deep/ .el-tabs__header {
    margin-bottom: 0px;
  }
  /deep/ .el-tabs__content {
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
    padding: 15px;
    min-height: 400px;
  }
  &-contact {
    margin-bottom: 10px;
    > label {
      margin-right: 5px;
      font-weight: bold;
    }
    &:last-child {
      margin-bottom: 10;
    }
  }
}
</style>
