<template>
  <el-dialog
    title="关闭评审"
    :visible.sync="dialogVisible"
    width="700px"
    class="dialog"
  >
    <el-form label-width="100px">
      <el-form-item label="申请用户姓名:">
        {{ data.userName }}
      </el-form-item>
      <el-form-item label="申请时间:">
        <div>{{ data.createTime | transformTime }}</div>
      </el-form-item>
      <el-form-item label="关闭说明:">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="operaContent"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      <el-button
        type="primary"
        @click="confirmBtnClick"
        size="small"
        :loading="btnLoading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { parseTime } from "@/utils/index";
import { closeEstimate } from "@/api/expert";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  filters: {
    transformTime(val) {
      return parseTime(val);
    },
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      operaContent: "", // 关闭说明
      btnLoading: false,
    };
  },
  methods: {
    async confirmBtnClick() {
      this.btnLoading = true;
      const { reviewApplyId } = this.data;
      await this.$axios
        .post(closeEstimate, {
          operaContent: this.operaContent,
          reviewApplyId,
        })
        .finally(() => {
          this.btnLoading = false;
        });
      this.$message.success("提交成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  /deep/ .el-dialog__body {
    padding: 10px 20px 0 20px;
  }
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
}
</style>
