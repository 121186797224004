<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <OrderProgress :step="2" />
    <div class="address flex flex-center">
      <div class="address-inner">
        <ReceiveAddressManege ref="address" />
      </div>
    </div>
    <GoodsList
      :data="goods"
      @createOrder="createOrder"
      :loading="listLoading"
      :createBtnLoading="createBtnLoading"
    />
    <FootBar />
  </div>
</template>
<script>
import OrderProgress from "@/components/OrderProgress";
import ReceiveAddressManege from "@/components/AddressMange/index";
import GoodsList from "./components/GoodsList";
import { chooseedGoodList, createGoodOrder } from "@/api/mall";
import { SET_SHOOSEGOODS } from "@/store/types";
import { mapGetters } from "vuex";
import Referrer from "@/mixins/referrer";
import { buryPoint } from "@/api/common";
export default {
  components: {
    OrderProgress,
    GoodsList,
    ReceiveAddressManege,
  },
  data() {
    return {
      goods: {},
      listLoading: false,
      createBtnLoading: false,
    };
  },
  computed: {
    ...mapGetters(["token", "userBaseInfo"]),
  },
  mixins: [Referrer],
  created() {
    this.getGoodList();
  },
  methods: {
    async getGoodList() {
      const data = sessionStorage.getItem(SET_SHOOSEGOODS);
      if (data) {
        this.listLoading = true;
        const goods = await this.$axios
          .post(chooseedGoodList, JSON.parse(data))
          .finally(() => {
            this.listLoading = false;
          });
        goods.confirmWares.forEach((item) => {
          Object.assign(item, { orderLeavingMessage: "" });
        });
        this.goods = goods;
      }
    },
    // 点击立即付款按钮
    async createOrder() {
      if (!this.token) return;
      this.createBtnLoading = true;
      const { confirmWares } = this.goods;
      const goods = [];
      const userAddressId = this.$refs.address.selectAddressId;
      confirmWares.forEach((item) => {
        const {
          orderLeavingMessage,
          productmallBusinessId,
          shopCountPrice,
          waresRanks,
        } = item;
        goods.push({
          orderLeavingMessage,
          orderPrice: shopCountPrice,
          orderRanks: waresRanks,
          productmallBusinessId,
        });
      });
      const params = {
        productmallOrderList: goods,
        userAddressId,
      };
      await this.$axios.post(createGoodOrder, params).finally(() => {
        this.createBtnLoading = false;
      });
      // 埋点
      this.toBuryPoint();
      this.$router.push({ name: "orderPay" });
    },
    toBuryPoint() {
      this.$axios.post(buryPoint, {
        eventId: "W-F-9-E1",
        eventName: "确认(生产)订单",
        module: "商品商城",
        currentUrl: this.$route.fullPath,
        currentUrlAlias: this.$route.name,
        referrerUrl: this.routeFrom.fullPath,
        referrerUrlAlias: this.routeFrom.name,
        userId: this.userBaseInfo.userId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.address {
  &-inner {
    width: $max-width;
    > div {
      width: 100%;
    }
  }
}
</style>
