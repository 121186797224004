// 联盟专区-首页

export const allianceHomeList = `/api/unoauth/league/prefectures`; // 首页联盟专区列表

// 联盟专区-列表,详情
export const allianceList = `/api/oauth/league/getLeagueListPage`; // 联盟列表
export const allianceDetail = `/api/oauth/league/getLeagueDetail`; // 联盟详情
export const allianceActsList = `/api/oauth/leagueActivity/getLeagueActivityListPage`; // 联盟活动列表
export const allianceActDetail = `/api/oauth/leagueActivity/getLeagueActivityDetail`; // 联盟活动详情
export const allianceMembers = `/api/oauth/leagueMember/getLeagueMemberListPage`; // 联盟成员
export const allianceMaterial = `/api/oauth/leagueResource/getLeagueResourceListPage`; // 联盟专区
export const joinAlliance = `/api/oauth/leagueMember/insertLeagueMember`; // 加入联盟
