<template>
  <div class="ban">
    <el-carousel
      :interval="40000"
      arrow="always"
      height="439px"
      class="swipper"
    >
      <el-carousel-item v-for="(item, index) in banners" :key="index">
        <div class="ban-banner">
          <img :src="item.bannerPath" @click="bannerClick(item)" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { homeBanner } from "@/api/home";
export default {
  data() {
    return {
      banners: [],
    };
  },
  created() {
    this.getBanners();
  },
  methods: {
    async getBanners() {
      const res = await this.$axios.get(homeBanner, { bannerType: "2" });
      this.banners = res;
    },
    bannerClick({ bannerLink }) {
      if (bannerLink) {
        window.open(bannerLink);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.ban {
  &-banner {
    width: 100%;
    height: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
