<template>
  <!--订单状态-->
  <el-select v-model="status" placeholder="请选择分类" clearable>
    <el-option
      :label="item.name"
      :value="item.id"
      v-for="(item, index) in orderStatusOption"
      :key="index"
    ></el-option>
  </el-select>
</template>
<script>
import { DEED_ORDER_STATUS } from "@/constant";
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    status: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      orderStatusOption: DEED_ORDER_STATUS,
    };
  },
};
</script>
