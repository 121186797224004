<template>
  <div class="cate">
    <div class="cate-type flex flex-row">
      <div class="cate-label">类别</div>
      <div class="flex-1">
        <div class="cate-item cate-parent flex flex-row flex-wrap pointer">
          <div
            v-for="(item, index) in parentItem"
            :key="index"
            :class="{
              active:
                item.laboratoryAbilityTypeId ===
                currentParent.laboratoryAbilityTypeId,
            }"
            @click="pItemClick(item)"
          >
            {{ item.laboratoryAbilityTypeName }}
          </div>
        </div>
        <div class="cate-item cate-child flex flex-row flex-wrap pointer">
          <div
            v-for="(item, index) in childItem[
              currentParent.laboratoryAbilityTypeId
            ]"
            :key="index"
            :class="{
              active:
                item.laboratoryAbilityTypeId ===
                currentChild.laboratoryAbilityTypeId,
            }"
            @click="cItemClick(item)"
          >
            {{ item.laboratoryAbilityTypeName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { abilityType } from "@/api/hall";
export default {
  data() {
    return {
      parentItem: [],
      childItem: {},
      currentParent: {},
      currentChild: {},
    };
  },
  created() {
    this.getType();
  },
  methods: {
    async getType(pid = 0) {
      const data = await this.$axios.get(abilityType, { pid });
      if (pid) {
        // 子类
        this.$set(this.childItem, pid, data);
      } else {
        // 首次打开页面
        this.parentItem = data;
        const { pId } = this.$route.query;
        if (pId) {
          const pIndex = data.findIndex(
            (item) => item.laboratoryAbilityTypeId === +pId
          );
          this.pItemClick(this.parentItem[pIndex]);
        } else {
          this.pItemClick(this.parentItem[0]);
        }
      }
    },
    // 父类点击
    pItemClick(item) {
      this.currentParent = item;
      this.currentChild = {};
      const id = item.laboratoryAbilityTypeId;
      if (!this.childItem[id]) this.getType(id);
      this.getList();
    },
    // 子类点击
    cItemClick(item) {
      this.currentChild = item;
      this.getList();
    },
    // 获取列表数据
    async getList() {
      this.$emit("typeClick", {
        firstTypeId: this.currentParent.laboratoryAbilityTypeId,
        secondTypeId: this.currentChild.laboratoryAbilityTypeId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.cate {
  padding-top: 14px;
  &-type {
    border: 1px solid #eeeff1;
    width: 1218px;
    margin: 0 auto;
    background: white;
    padding-top: 15px;
  }
  &-label {
    width: 100px;
    text-align: center;
    color: #888888;
    padding-top: 2px;
  }
  &-item {
    border-bottom: 1px solid #eeeff1;
    &:last-child {
      border-bottom: none;
    }
    div {
      padding: 2px 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      // font-size: 12px;
      &:hover,
      &.active {
        background: #e3ecff;
        color: #4482ff;
      }
    }
  }
  &-parent {
    min-height: 40px;
  }
  &-child {
    padding-top: 10px;
    min-height: 33px;
  }
}
</style>
