<template>
  <div class="bill">
    <BillForm />
    <div class="bill-btn">
      <el-button type="primary" plain>提交</el-button>
    </div>
  </div>
</template>
<script>
import BillForm from "./ReserveBillForm.vue";
export default {
  components: {
    BillForm,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.bill {
  width: 650px;
  &-btn {
    padding-left: 150px;
  }
}
.address {
  width: 400px;
}
.editAddress {
  margin-left: 10px;
  cursor: pointer;
  color: #3073f4;
}
</style>
