<template>
  <div class="query">
    <el-form
      :inline="true"
      :model="form"
      class="qForm flex-1"
      :class="{ contentExpend: expend }"
      :size="queryFormSize"
    >
      <el-form-item label="商品名称">
        <el-input
          v-model="form.checkmallWaresName"
          placeholder="请输入商品名称"
          class="demandTitle"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
          v-model="form.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="load"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item label="订单状态">
        <OrderStatus v-model="form.orderCheckStatus" class="demandStatus" />
      </el-form-item>
      <el-form-item label="支付方式">
        <OrderPayType v-model="form.orderPayType" class="demandStatus" />
      </el-form-item>
      <el-form-item label="支付状态">
        <PayStatus v-model="form.orderPayStatus" class="demandStatus" />
      </el-form-item>
    </el-form>
    <!-- <div class="expend-arrow pointer" @click="expendClick">
      <span :class="{ down: expend }"></span>
    </div> -->
  </div>
</template>
<script>
import OrderStatus from "@/components/ManageQueryOptions/DeedOrderStatus";
import OrderPayType from "@/components/ManageQueryOptions/PayType";
import PayStatus from "@/components/ManageQueryOptions/PayStatus";
import { QUERY_FORM_SIZE } from "@/constant/size";
export default {
  props: {
    load: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OrderStatus,
    OrderPayType,
    PayStatus,
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      expend: true,
      form: {
        orderCode: "", // 编号
        orderPayType: null, // 支付类型
        orderCheckStatus: null, // 订单检测状态
        orderPayStatus: null, // 支付状态
        time: [],
      },
    };
  },
  created() {},
  methods: {
    expendClick() {
      this.expend = !this.expend;
    },
    onSubmit() {
      this.$emit("toQuery", this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
.demandTitle {
  width: 300px;
}
.demandStatus {
  width: 120px;
}
.qForm {
  height: 45px;
  overflow: hidden;
  &.contentExpend {
    height: auto;
  }
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-date-editor {
    width: 400px;
  }
}
.expend-arrow {
  width: 50px;
  text-align: right;
  padding-right: 12px;
  padding-top: 10px;

  > span {
    &::after {
      content: "";
      display: inline-block;
      position: relative;
      width: 6px;
      height: 6px;
      left: 6px;
      top: -2px;
      margin-right: 4px;
      border-bottom: 1px solid #666;
      border-right: 1px solid #666;
      transform: rotateZ(-45deg);
      transition: transform 0.5s;
    }
  }
  > .down {
    &::after {
      transform: rotateZ(45deg);
      transition: transform 0.3s;
    }
  }
}
</style>
