<template>
  <div class="sation" id="conversation" ref="chatContainer">
    <div
      v-for="(msg, index) in msgs"
      :key="index"
      class="flex flex-row msg-item"
      :class="{
        isService: msg.identity == 1,
        isCustomer: msg.identity == 2,
      }"
    >
      <div v-if="msg.messageType === MSG_SYS" class="content-sys">
        <span>{{ msg.message }}</span>
      </div>
      <div class="flex flex-row" v-else>
        <!--1：客服-->
        <div v-if="msg.identity == 1" class="msg-avator">
          <img src="@/assets/chartroom/serviceImg.png" />
        </div>
        <div class="content" v-if="msg.messageType == MSG_TEXT">
          <div class="content-head">
            <span class="content-senderName">{{ msg.senderName }}</span>
            <span>{{ msg.cerateTime | transformTime }}</span>
          </div>
          <div v-html="msg.message" class="content-text"></div>
        </div>
        <!--2：咨询者-->
        <div v-if="msg.identity == 2" class="msg-avator">
          <img src="@/assets/chartroom/customerImg.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MSG_SYS, MSG_TEXT } from "@/constant/chart.js";
import { parseTime } from "@/utils/index";
export default {
  props: {
    msgs: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    transformTime(val) {
      return parseTime(val, "{y}-{m}-{d} {h}:{i}");
    },
  },
  watch: {
    async "msgs.length"() {
      await this.$nextTick();
      this.$refs.chatContainer.scrollTop =
        this.$refs.chatContainer.scrollHeight;
    },
  },
  data() {
    return {
      MSG_TEXT,
      MSG_SYS,
    };
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.sation {
  height: 100%;
  overflow: auto;
  padding: 10px 5px;
}
.msg {
  &-item {
    margin-bottom: 10px;
    &.isService {
      justify-content: flex-start;
      .msg-avator {
        margin-right: 10px;
        margin-left: 5px;
      }
    }
    &.isCustomer {
      justify-content: flex-end;
      .msg-avator {
        margin-left: 10px;
        margin-right: 5px;
      }
    }
  }
  &-avator {
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.content {
  &-text {
    background: $home-bg;
    border-radius: 5px;
    padding: 8px 10px;
    position: relative;
  }
  &-head {
    font-size: 0.92rem;
    color: $font-color-gray;
    margin-bottom: 2px;
    padding-left: 5px;
  }
  &-senderName {
    margin-right: 5px;
  }
  &-sys {
    width: 100%;
    text-align: center;
    > span {
      background: $home-bg;
      padding: 5px 15px;
      display: inline-block;
      border-radius: 15px;
      color: #747474;
    }
  }
}
</style>
