<template>
  <div class="ban">
    <div class="ban-inner flex flex-right">
      <div class="course" v-if="courses.length">
        <div class="course-head flex flex-row flex-between">
          <div>
            <span class="course-label">我的课程</span>
            <span class="course-num"
              >课程&nbsp;&nbsp;{{ courses.length }} 门</span
            >
            <!-- <span class="course-get">收藏&nbsp;&nbsp;6 门</span> -->
          </div>
          <div class="course-my pointer">我的课表></div>
        </div>
        <div class="course-con flex flex-row">
          <div class="course-cover">
            <img :src="$oss('common/default_device.png')" />
          </div>
          <div class="flex-1">
            <div
              v-for="(item, index) in courses"
              :key="index"
              class="flex flex-row flex-between course-list pointer"
            >
              <div class="course-name ellipsis">
                {{ index + 1 }}.{{ item.courseName }}
              </div>
              <div>{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { myTrainClass } from "@/api/userCenter";
export default {
  data() {
    return {
      courses: [
        // { courseName: "珠峰架构前端技术公开课", createTime: "2012-12" },
        // { courseName: "珠峰架构前端技术公开课", createTime: "2012-12" },
        // { courseName: "珠峰架构前端技术公开课", createTime: "2012-12" },
        // { courseName: "珠峰架构前端技术公开课", createTime: "2012-12" },
      ],
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    if (this.token) {
      this.getList();
    }
  },
  methods: {
    async getList() {
      const { list, totalItem } = await this.$axios
        .post(myTrainClass, {
          orderStatus: "2", // 订单状态：1待支付，2成功支付，3支付失败，4已关闭，5已退款，6订单解绑
          pageIndex: 1,
          pageSize: 4,
        })
        .finally(() => {
          this.tableLoading = false;
        });
      this.courses = list || [];
      this.totalItem = totalItem;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.ban {
  width: 100%;
  background: url("https://store-img.cre-tep.com/frontDeskProject/images/onlineTrain/bannerBg.jpg");
  background-size: 100% 100%;
  height: 280px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    padding-top: 14px;
  }
}
.course {
  width: 568px;
  background: #ffffff;
  border-radius: 3px;
  &-head {
    height: 39px;
    line-height: 39px;
    background: #f6f6f6;
    border-radius: 3px;
    padding: 0 15px;
    font-size: 12px;
    color: #858585;
  }
  &-label {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  &-num,
  &-get {
    margin-left: 23px;
  }
  &-con {
    padding: 16px;
    font-size: 12px;
    color: #858585;
    min-height: 100px;
  }
  &-cover {
    width: 162px;
    height: 93px;
    margin-right: 13px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  &-list {
    font-size: 12px;
    margin-bottom: 9px;
    //  color: #4482ff;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-name {
    max-width: 280px;
    &:hover {
      color: #4482ff;
    }
  }
}
</style>
