<template>
  <div class="labo">
    <div class="labo-logo">
      <img
        :src="data.checkmallBusinessLogo"
        v-if="data.checkmallBusinessLogo"
        class="pointer"
        @click="toLab"
      />
    </div>

    <div class="labo-line">
      <i class="el-icon-office-building"></i>
      <span>{{ data.checkmallBusinessName }}</span>
    </div>
    <div class="labo-line">
      <i class="el-icon-service"></i>
      <span>{{ data.checkmallBusinessContactsPhone }}</span>
    </div>
    <div class="labo-line">
      <i class="el-icon-user"></i>
      <span>{{ data.checkmallBusinessContactsName }}</span>
    </div>

    <div class="labo-line">
      <i class="el-icon-location-information"></i>
      <span>{{ data.laboratoryLocationProvince }}</span>
      <span>{{ data.laboratoryLocationCity }}</span>
      <span>{{ data.laboratoryLocationCounty }}</span>
      <span>{{ data.laboratoryAddress }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "laboratory",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ellipsis: true,
    };
  },
  // computed: {
  //   checkInfo() {
  //     const { laboratoryCheckInfo } = this.data;
  //     const len = laboratoryCheckInfo.length;
  //     const max = 7;
  //     if (len > max && this.ellipsis) {
  //       return `${laboratoryCheckInfo.substring(0, max)}...`;
  //     }
  //     return laboratoryCheckInfo;
  //   },
  // },
  methods: {
    toLab() {
      this.$jump.push({
        name: "labDetail",
        query: { id: this.data.laboratoryId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.labo {
  width: 290px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  margin-bottom: 15px;
  padding-top: 5px;
  &-logo {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    text-align: center;
    // background: #efeded;
    > img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
      object-fit: contain;
    }
  }
  &-line {
    padding: 0 15px;
    margin-bottom: 10px;
    i {
      font-size: 1.14rem;
      margin-right: 5px;
    }
  }
  &-icon {
    width: 16px;
    margin-right: 10px;
  }
}
.expend {
  color: $theme-color;
}
</style>
