<template>
  <div class="reg">
    <el-form
      label-width="80px"
      :model="form"
      :size="queryFormSize"
      :rules="rules"
      ref="ruleForm"
      v-loading="pageLoading"
    >
      <div class="flex flex-row reg-line reg-read">
        <div class="flex-1">
          <el-form-item label="名称">
            {{ userBaseInfo.userRealName }}
          </el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="性别">
            {{ sexMap[+userBaseInfo.userSex] }}
          </el-form-item>
        </div>
      </div>
      <div class="flex flex-row reg-line">
        <div class="flex-1">
          <el-form-item label="头像" class="line-height-s">
            <Upload :imgs="form.expertImage" @onSuccess="expertImageSuccess" />
          </el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="联系电话">{{
            userBaseInfo.userPhone
          }}</el-form-item>
          <el-form-item label="电子邮箱">{{
            userBaseInfo.userEmail
          }}</el-form-item>
        </div>
      </div>
      <div class="flex flex-row reg-line">
        <div class="flex-1">
          <el-form-item label="出生年月">
            <el-date-picker
              v-model="userBaseInfo.userBirthday"
              type="date"
              placeholder="选择日期"
              disabled
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="籍贯" prop="location">
            <el-cascader
              v-model="form.location"
              :options="cityOptions"
              filterable
              clearable
              placeholder="请选择省、市"
              ref="cityCascader"
            ></el-cascader>
          </el-form-item>
        </div>
      </div>
      <div class="flex flex-row reg-line">
        <div class="flex-1">
          <el-form-item label="民族" prop="expertNation"
            ><el-input
              v-model="form.expertNation"
              placeholder="请输入内容"
            ></el-input
          ></el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="政治面貌" prop="politicalOutlook">
            <el-select v-model="form.politicalOutlook" placeholder="请选择">
              <el-option
                :label="item.dictLabel"
                :value="item.dictValue"
                v-for="(item, index) in dicts.politicalOutlookDDs"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="flex flex-row reg-line">
        <div class="flex-1">
          <el-form-item label="毕业院校" prop="expertUniversity"
            ><el-input
              v-model="form.expertUniversity"
              placeholder="请输入内容"
            ></el-input
          ></el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="毕业时间" prop="expertGraduationTime">
            <el-date-picker
              v-model="form.expertGraduationTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
      <div class="flex flex-row reg-line">
        <div class="flex-1">
          <el-form-item label="学历" prop="expertEducation">
            <el-select v-model="form.expertEducation" placeholder="请选择">
              <el-option
                :label="item.dictLabel"
                :value="item.dictValue"
                v-for="(item, index) in dicts.expertEducationDDs"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="学位" prop="expertAcademicDegree">
            <el-select v-model="form.expertAcademicDegree" placeholder="请选择">
              <el-option
                :label="item.dictLabel"
                :value="item.dictValue"
                v-for="(item, index) in dicts.expertAcademicDegreeDDs"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="flex flex-row reg-line">
        <div class="flex-1">
          <el-form-item label="职位">
            <el-input
              v-model="form.expertPosition"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="职称"
            ><el-input
              v-model="form.expertPositionTitle"
              placeholder="请输入内容"
            ></el-input
          ></el-form-item>
        </div>
      </div>

      <div class="flex flex-row reg-line">
        <div class="flex-1">
          <el-form-item label="传真"
            ><el-input
              v-model="form.expertFax"
              placeholder="请输入内容"
            ></el-input
          ></el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="从事专业" prop="expertProfessional"
            ><el-input
              v-model="form.expertProfessional"
              placeholder="请输入内容"
            ></el-input
          ></el-form-item>
        </div>
      </div>
      <div class="flex flex-row reg-line">
        <div class="flex-1">
          <el-form-item label="工作单位"
            ><el-input
              v-model="form.expertWorkUnit"
              placeholder="请输入内容"
            ></el-input
          ></el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="身份证号" prop="expertIdNumber"
            ><el-input
              v-model="form.expertIdNumber"
              placeholder="请输入内容"
            ></el-input
          ></el-form-item>
        </div>
      </div>
      <div class="flex flex-row reg-line">
        <div class="flex-1">
          <el-form-item label="通信地址"
            ><el-input
              v-model="form.expertMailingAddress"
              placeholder="请输入内容"
            ></el-input
          ></el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="邮编" prop="expertPostcode"
            ><el-input
              v-model="form.expertPostcode"
              placeholder="请输入内容"
            ></el-input
          ></el-form-item>
        </div>
      </div>
      <div class="reg-line">
        <el-form-item label="专业领域">
          <div class="reg-tree">
            <el-tree
              v-model="form.expertFields"
              :data="fieldsTree"
              show-checkbox
              :props="treeProps"
              node-key="fieldCategoryId"
              ref="fieldsTree"
              highlight-current
            >
            </el-tree>
          </div>
        </el-form-item>
      </div>
      <div class="reg-line">
        <el-form-item label="专家介绍" prop="expertBrief">
          <el-input
            v-model="form.expertBrief"
            placeholder="请输入内容"
            type="textarea"
            :rows="10"
          ></el-input>
        </el-form-item>
      </div>
      <div class="reg-line" v-if="form.expertId">
        <el-form-item label="审核状态">
          {{ applyMap[+form.expertStatus] }}
        </el-form-item>
      </div>
      <div class="reg-line">
        <el-form-item label="">
          <el-button
            type="primary"
            plain
            class="reg-submit"
            :loading="btnLoading"
            @click="submitApplyBtnClick"
            :disabled="
              [EXPERT_STATUS_DISABLE, EXPERT_STATUS_CHECKING].includes(
                form.expertStatus
              )
            "
            >提交</el-button
          >
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  expertDict,
  expertCate,
  expertApplyInfo,
  applyExpert,
} from "@/api/expert";
import { QUERY_FORM_SIZE } from "@/constant/size";
import {
  EXPERT_STATUS,
  EXPERT_STATUS_DISABLE,
  EXPERT_STATUS_CHECKING,
} from "@/constant/dict";
import Upload from "@/components/ImgUpload/index";
import { provinceAndCityData } from "element-china-area-data";
import RULE from "./rule";
export default {
  components: {
    Upload,
  },
  data() {
    return {
      EXPERT_STATUS_DISABLE,
      EXPERT_STATUS_CHECKING,
      queryFormSize: QUERY_FORM_SIZE,
      btnLoading: false,
      pageLoading: false,
      rules: RULE,
      form: {
        location: [], // 籍贯
        expertNation: "",
        politicalOutlook: null, // 政治面貌
        expertUniversity: null, // 毕业院校
        expertGraduationTime: null, // 毕业时间
        expertEducation: null, // 学历
        expertAcademicDegree: null, //学位
        expertPosition: null, // 职位
        expertPositionTitle: null, // 职称
        expertFax: null, // 传真
        expertProfessional: null, // 从事专业
        expertWorkUnit: null, // 工作单位
        expertIdNumber: null, // 身份证号
        expertMailingAddress: null, // 通信地址
        expertPostcode: null, // 邮编
        expertFields: [], // 专业领域
        expertBrief: null, // 专家介绍
      },
      dicts: {
        expertAcademicDegreeDDs: [],
        expertEducationDDs: [],
        expertSexDDs: [],
        expertStatusDDs: [],
        politicalOutlookDDs: [],
      },
      fieldsTree: [],
      treeProps: {
        label: "fieldCategoryName",
      },
      sexMap: {},
      applyMap: {}, // 审核状态
      cityOptions: provinceAndCityData,
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  created() {
    this.getDict();
    this.getFields();
    this.getApplyInfo();
  },
  methods: {
    async getApplyInfo() {
      this.pageLoading = true;
      const res = await this.$axios.get(expertApplyInfo).finally(() => {
        this.pageLoading = false;
      });
      if (res) {
        const {
          fieldExes,
          expertNativePlaceCityCode,
          expertNativePlaceProvinceCode,
          ...rest
        } = res;
        const arrField = fieldExes.map((item) => item.fieldCategoryId);
        this.$refs.fieldsTree.setCheckedKeys(arrField);
        this.form = {
          ...rest,
          location: [expertNativePlaceProvinceCode, expertNativePlaceCityCode],
        };
      }
    },
    async getDict() {
      const res = await this.$axios.get(expertDict);
      this.dicts = res;
      const { expertSexDDs } = res;
      const sexMap = {};
      expertSexDDs.forEach((item) => {
        sexMap[item.dictValue] = item.dictLabel;
      });
      this.sexMap = sexMap;
      const applyStatus = await this.$store.dispatch(
        "getDictData",
        EXPERT_STATUS
      );
      const applyMap = {};
      applyStatus.forEach((item) => {
        applyMap[item.dictValue] = item.dictLabel;
      });
      this.applyMap = applyMap;
    },
    async getFields() {
      const data = await this.$axios.post(expertCate, {});
      this.fieldsTree = data;
    },
    expertImageSuccess(fileUrls) {
      Object.assign(this.form, { expertImage: fileUrls });
    },
    async submitApplyBtnClick() {
      await this.$refs.ruleForm.validate();
      const { location, ...rest } = this.form;
      // 籍贯
      const [addressProvince, addressCity] =
        this.$refs.cityCascader.getCheckedNodes()[0].pathLabels;
      // 专业领域
      const allCheckNodes = this.$refs.fieldsTree.getCheckedNodes(false, true);
      if (!allCheckNodes.length) {
        this.$message.warning("请选择专业领域");
        return;
      }
      const nodeIds = allCheckNodes.map((item) => item.fieldCategoryId);
      const params = {
        ...rest,
        expertNativePlaceProvinceCode: location[0],
        expertNativePlaceProvince: addressProvince,
        expertNativePlaceCityCode: location[1],
        expertNativePlaceCity: addressCity,
        expertFields: nodeIds,
      };
      this.btnLoading = true;
      await this.$axios.post(applyExpert, params).finally(() => {
        this.btnLoading = false;
      });
      this.$message.success("提交成功，请等待审核");
      this.getApplyInfo();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.reg {
  padding-top: 20px;
  &-line {
    > div {
      padding: 0 20px;
    }
  }
  &-tree {
    border: 1px solid $border-color;
  }
  &-read {
    /deep/ .el-form-item {
      margin-bottom: 5px;
    }
  }
  &-submit {
    width: 100px;
  }
}
</style>
