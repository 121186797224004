<template>
  <div class="wrapper flex">
    <div class="title">地区</div>
    <div class="flex-1 content" :class="{ contentExpend: expend }">
      <div class="flex flex-wrap">
        <div
          v-for="(item, index) in info"
          :key="index"
          class="content-item pointer"
        >
          <el-popover placement="bottom" width="auto" trigger="hover">
            <el-checkbox-group
              v-model="selectItems[index]"
              @change="checkboxChange"
            >
              <el-checkbox
                v-for="(city, cIndex) in item.city"
                :label="city"
                :key="cIndex"
              >
                {{ city.name }}
              </el-checkbox>
            </el-checkbox-group>
            <span slot="reference">{{ item.province }}</span>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="expend-arrow pointer" @click="expendClick">
      <span :class="{ down: expend }"></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Array,
      default: () => [],
    },
    delId: {
      type: String,
      default: null,
    },
  },
  data() {
    const itemMap = {};
    const maxProvinceNum = 34;
    for (let i = 0; i < maxProvinceNum; i++) {
      itemMap[i] = [];
    }
    return {
      selectItems: itemMap,
      expend: false,
    };
  },
  watch: {
    delId(val) {
      for (const key in this.selectItems) {
        const cur = this.selectItems[key];
        if (cur.length) {
          const index = cur.findIndex(({ code }) => val === code);
          if (index > -1) {
            cur.splice(index, 1);
            break;
          }
        }
      }
    },
  },
  methods: {
    expendClick() {
      this.expend = !this.expend;
    },
    checkboxChange() {
      const selectItems = [];
      for (const key in this.selectItems) {
        const cur = this.selectItems[key];
        if (cur.length) {
          selectItems.push(...cur);
        }
      }
      this.$emit("selectItemChange", selectItems);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  min-height: 36px;
  border-top: 1px solid #ebeef5;
}
.title {
  width: 95px;
  background: #f3f6f8;
  padding-left: 20px;
  padding-top: 8px;
  border-right: 1px solid #ebeef5;
}
.content {
  padding: 10px 15px;
  height: 28px;
  overflow: hidden;
  &-item {
    min-width: 80px;
    margin-bottom: 10px;
  }
  &.contentExpend {
    height: auto;
  }
}
.expend-arrow {
  width: 50px;
  text-align: right;
  padding-right: 12px;
  padding-top: 10px;

  > span {
    &::after {
      content: "";
      display: inline-block;
      position: relative;
      width: 6px;
      height: 6px;
      left: 6px;
      top: -2px;
      margin-right: 4px;
      border-bottom: 1px solid #666;
      border-right: 1px solid #666;
      transform: rotateZ(-45deg);
      transition: transform 0.5s;
    }
  }
  > .down {
    &::after {
      transform: rotateZ(45deg);
      transition: transform 0.3s;
    }
  }
}
</style>
