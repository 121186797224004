<template>
  <div class="item flex flex-row pointer" @click="toDetail(data)">
    <div v-if="data.articleImage" class="item-img">
      <img :src="data.articleImage" />
    </div>
    <div class="flex-1" :class="{ noImg: !data.articleImage }">
      <div class="item-title">{{ data.articleTitle }}</div>
      <div class="item-brief">{{ data.articleBrief }}</div>
      <div class="item-time">
        {{ data.publishTime | transformTime }}
      </div>
    </div>
  </div>
</template>
<script>
import { parseTime } from "@/utils/index";
export default {
  filters: {
    transformTime(val) {
      return parseTime(val);
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    toDetail(data) {
      this.$router.push({ name: "newsDetail", query: { id: data.articleId } });
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  padding: 9px;
  background: white;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &-img {
    width: 220px;
    height: 134px;
    margin-right: 33px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-title {
    font-size: 16px;
    font-weight: bold;
    color: #2c2c2c;
    padding: 14px 20px 10px 0;
  }
  &-brief {
    font-size: 14px;
    color: #777777;
    margin-bottom: 16px;
  }
  &-time {
    font-size: 14px;
    color: #777777;
  }
}
.noImg {
  padding-left: 15px;
  padding-bottom: 10px;
}
</style>
