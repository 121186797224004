<template>
  <div class="home">
    <HeadBar ref="headBar" /><!--顶部bar-->
    <HeadNav /><!-- 顶部导航-->
    <Banner />
    <!-- <NewsSettled />
    <Course /> -->
    <DynamicAd />
    <div class="home-modules">
      <div v-for="(com, index) in components" :key="index">
        <component :is="com.name" :data="com.data" />
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "./components/Banners/index";
import NewsSettled from "./components/NewsSettled/index.vue"; // 新闻、入驻
import MallStrict from "./components/MallStrict/index";
import MallFine from "./components/MallFine/index";
import Alliance from "./components/AllianceZone/index"; // 联盟专区
import MapData from "./components/MapData/index"; // 联盟专区
import HotLab from "./components/HotLab/index.vue"; // 热门实验室
import Links from "./components/Links/index"; // 友情链接
import AbilityHall from "./components/Ability/index.vue"; // 能力大厅
import Experts from "./components/Experts"; // 能力大厅
import DynamicAd from "./components/DynamicAd/index.vue"; // 广告
import Course from "./components/Course/index.vue"; // 课程和优质产品
import Ad from "./components/Ad.vue";
import { homeModulePos } from "@/api/home";
export default {
  name: "Home",
  components: {
    DynamicAd,
    Banner,
    NewsSettled,
    MallStrict,
    MallFine,
    Alliance,
    HotLab,
    Links,
    AbilityHall,
    MapData,
    Experts,
    Ad,
    Course,
  },
  data() {
    return {
      comMap: {
        modular_dustry_services: NewsSettled,
        modular_training_resources: Course,
        modular_ability: AbilityHall,
        modular_expert: Experts,
        modular_server_mall: MallStrict,
        modular_product_mall: MallFine,
        modular_data_show: MapData,
        modular_laboratory: HotLab,
        modular_league: Alliance,
        modular_links: Links,
        modular_ad: Ad,
      },
      components: [],
    };
  },
  created() {
    this.getModulePos();
  },
  methods: {
    async getModulePos() {
      const CM = "CM"; // 内容模块， AD：广告模块
      const res = await this.$axios.get(homeModulePos);
      res.forEach(
        ({
          homelayoutModularType,
          rarePlatformModular,
          rarePlatformAdvertisement,
        }) => {
          if (homelayoutModularType === CM) {
            const { modularFlag } = rarePlatformModular;
            this.components.push({ name: this.comMap[modularFlag] });
          } else {
            this.components.push({
              name: this.comMap.modular_ad,
              data: rarePlatformAdvertisement,
            });
          }
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.home {
  &-modules {
    min-height: 500px;
    background: $home-bg;
  }
}
.footer {
  padding-top: 0;
}
</style>
