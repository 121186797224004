<template>
  <div class="hall">
    <HeadBar /><!--顶部bar-->
    <HeadNav /><!-- 顶部导航-->
    <div class="hall-inner">
      <div class="hall-center">
        <CateSelect @typeClick="typeClick" />
        <div class="hall-search">
          <SearchBar
            :num="products.length"
            v-model="searchKey"
            @btnClick="searchBarClick"
          />
        </div>
        <div v-loading="listLoading" class="hall-pros">
          <CheckProject
            v-for="(product, index) in products"
            :key="index"
            :info="product"
          />
        </div>
      </div>
      <!-- 分页-->
      <Pagination
        :total="totalItem"
        :page="page"
        @pageIndexChange="handlePageChange"
      />
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import { abilityList } from "@/api/hall";
import Pagination from "@/components/Pagination/index";
import SearchBar from "@/components/SearchBar/index";
import CateSelect from "./components/CateSelect.vue";
import CheckProject from "./components/CheckProject";
export default {
  components: {
    Pagination,
    CateSelect,
    CheckProject,
    SearchBar,
  },
  data() {
    return {
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      products: [],
      queryTypeIds: {},
      listLoading: true,
      searchKey: "",
    };
  },
  methods: {
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.queryList();
    },
    // 类别点击
    typeClick(ids) {
      this.queryTypeIds = ids;
      this.queryList();
    },
    // 检索bar点击查询
    searchBarClick() {
      this.queryList();
    },
    async queryList() {
      this.listLoading = true;
      this.products = [];
      const params = {
        ...this.page,
        searchKey: this.searchKey,
        abilityType: 0, //0：首页用户，1：实验室
      };
      const { firstTypeId, secondTypeId } = this.queryTypeIds;
      if (secondTypeId) {
        Object.assign(params, { secondTypeId });
      } else Object.assign(params, { firstTypeId });
      const { list, totalItem } = await this.$axios.post(abilityList, params);
      this.totalItem = totalItem;
      this.products = list || [];
      this.listLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.hall {
  &-inner {
    background: $home-bg;
    padding-bottom: 20px;
  }
  &-center {
    min-height: 400px;
    width: $max-width;
    margin: 0 auto;
  }
  &-search {
    background: #f7faff;
    border-left: 1px solid #eeeff1;
    border-right: 1px solid #eeeff1;
    border-bottom: 1px solid #eeeff1;
    padding: 7px 53px;
  }
  &-pros {
    min-height: 200px;
    margin-bottom: 20px;
  }
}
.footer {
  padding-top: 0 !important;
}
</style>
