<template>
  <div class="tabs">
    <el-tabs v-model="tabsValue" type="card" editable @edit="handleTabsEdit">
      <el-tab-pane
        :key="tab"
        v-for="(tab, tabIndex) in tabsName"
        :label="`样品组${String.fromCharCode(tabIndex + 65)}`"
        :name="tab"
      >
        <div class="content">
          <el-form
            :inline="true"
            :model="forms[tabIndex]"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
          >
            <div class="content-line">
              <el-form-item
                label="样品名称"
                class="require name"
                prop="sampleName"
              >
                <el-input
                  v-model="forms[tabIndex].sampleName"
                  class="len-big"
                ></el-input>
              </el-form-item>
              <el-form-item label="数量" class="require num">
                <el-input
                  v-model="forms[tabIndex].sampleQuantity"
                  class="len-mini"
                  @input="sampleNumChange"
                ></el-input>
              </el-form-item>
            </div>
            <div class="content-line">
              <!-- <el-form-item label="型号规格">
                <el-input v-model="forms[tabIndex].model"></el-input>
              </el-form-item> -->
              <el-form-item label="标号/批号">
                <el-input v-model="forms[tabIndex].sampleLabel"></el-input>
              </el-form-item>
              <el-form-item label="样品主要成分">
                <el-input v-model="forms[tabIndex].sampleBasis"></el-input>
              </el-form-item>
            </div>
            <div class="content-line">
              <el-form-item label="检测目的">
                <el-input
                  type="textarea"
                  v-model="forms[tabIndex].orderCheckPurpose"
                  rows="3"
                ></el-input>
              </el-form-item>
              <el-form-item label="检测的依据/技术要求">
                <el-input
                  type="textarea"
                  v-model="forms[tabIndex].checkBasicTechnology"
                  rows="3"
                ></el-input>
              </el-form-item>
            </div>
            <div class="content-line">
              <el-form-item label="样品寄送方式" class="require name long">
                <el-radio-group v-model="forms[tabIndex].sampleMailType">
                  <el-radio
                    v-for="(item, index) in mailType"
                    :key="index"
                    :label="item.dictValue"
                    >{{ item.dictLabel }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>
          </el-form>
          <div class="check flex flex-row">
            <span class="check-title">检测项目:</span>
            <label
              v-for="(item, index) in info.serviceProject"
              :key="index"
              class="pointer"
              :class="{
                active:
                  checkItemIds[tabIndex] &&
                  Object.keys(checkItemIds[tabIndex]).includes(
                    item.checkmallFirstRankId + ''
                  ),
              }"
              @click="checkItemClick(item.checkmallFirstRankId, tabIndex)"
            >
              {{ item.checkmallFirstRankName }}
            </label>
          </div>
          <!-- 表格全部渲染出来，通过v-show来展示选中的表格-->
          <CheckTables
            :key="tab"
            :info="info.serviceProject"
            :unitMap="unitMap"
            :check-ids="Object.keys(checkItemIds[tabIndex] || {})"
            @itemChildChange="handleItemChildChange($event, tabIndex)"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CheckTables from "./CheckTables";
import { SAMPLE_MAIL_TYPE, SERVICE_UNIT_TYPE } from "@/constant/dict";
const FormModel = {
  sampleName: "",
  sampleQuantity: 1,
  sampleLabel: "",
  sampleBasis: "",
  orderCheckPurpose: "",
  checkBasicTechnology: "",
  sampleMailType: null,
};
export default {
  name: "orderTabs",
  components: {
    CheckTables,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const _addIndex = "0";
    return {
      tabsName: [_addIndex], // 所有的tabs
      tabsValue: _addIndex, // 选中的tab
      forms: [{ ...FormModel }],
      addIndex: +_addIndex, // 全局新增tab索引
      // 选中的检测项目
      checkItemIds: {},
      mailType: [],
      unitMap: {},
      rules: {
        sampleName: [
          { required: true, message: "请输入样品名称", trigger: "blur" },
        ],
      },
    };
  },
  async mounted() {
    // // 筛选出所有的检测项目表格数据，通过v-show来展示选中的表格
    // this.info.checkmallFirstRankList.forEach(({ checkmallSecondRankList }) => {
    //   this.checkTableDatas.push(checkmallSecondRankList);
    // });
    // console.log(this.checkTableDatas);
    this.checkItemClick(this.info.serviceProject[0].checkmallFirstRankId, 0);
    await this.getDict();
    // 设置默认值
    this.setDefaultValue();
  },
  methods: {
    setDefaultValue() {
      const defaultMail = this.mailType[0].dictValue;
      FormModel.sampleMailType = defaultMail;
      this.forms[0].sampleMailType = defaultMail;
    },
    async getDict() {
      const data = await this.$store.dispatch("getDictData", SAMPLE_MAIL_TYPE);
      this.mailType = data;
      const units = await this.$store.dispatch(
        "getDictData",
        SERVICE_UNIT_TYPE
      );
      const unitMap = {};
      units.forEach((item) => {
        unitMap[item.dictValue] = item.dictLabel;
      });
      this.unitMap = unitMap;
    },
    // tab的点击事件
    async handleTabsEdit(targetName, action) {
      const len = this.tabsName.length;
      if (action === "remove" && len <= 1) {
        return;
      }
      if (action === "add") {
        this.addIndex++;
        this.tabsName.push(`${this.addIndex}`);
        this.forms.push({ ...FormModel });
        await this.$nextTick();
        this.tabsValue = this.tabsName[len];
      } else if (action === "remove") {
        try {
          await this.$confirm("确定删除吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          const delIndex = this.tabsName.findIndex(
            (name) => targetName === name
          );
          this.tabsName.splice(delIndex, 1);
          this.forms.splice(delIndex, 1);
          if (this.tabsValue === targetName) {
            await this.$nextTick();
            this.tabsValue = this.tabsName[0];
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    // 检测项目的点击
    checkItemClick(id, tabIndex) {
      const currentTabs = this.checkItemIds[tabIndex];
      if (currentTabs && currentTabs[id]) {
        this.$delete(this.checkItemIds[tabIndex], id);
      } else {
        if (currentTabs) {
          this.$set(this.checkItemIds[tabIndex], id, []);
        } else {
          this.$set(this.checkItemIds, tabIndex, {});
          this.$set(this.checkItemIds[tabIndex], id, []);
        }
      }
      this.$emit("checkItemSelect");
    },
    // 样品数量改变时
    sampleNumChange() {
      this.$emit("sampleCountChange");
    },
    // 父组件调用，获取接口入参数据
    getParamsData() {
      return { forms: this.forms, checkItem: this.checkItemIds };
    },
    handleItemChildChange(data, tabIndex) {
      const { child, itemId } = data;
      this.$set(this.checkItemIds[tabIndex], itemId, child);
      this.$emit("checkItemSelect");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.tabs {
  padding: 15px;
  /deep/ .el-tabs__header {
    margin-bottom: 0;
  }
}
.content {
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  padding-top: 15px;
  &-line {
    padding: 0 15px;
    /deep/ .el-input {
      width: 400px;
    }
    /deep/ .el-textarea {
      width: 400px;
    }
    /deep/ textarea {
      resize: none;
    }
  }
}
.len-mini {
  width: 100px !important;
}
.require {
  /deep/ .el-form-item__label {
    position: relative;
    &::before {
      content: "*";
      font-size: 1.14rem;
      position: absolute;
      right: 0;
      top: 3px;
      color: #f56c6c;
    }
  }
  &.name {
    /deep/ .el-form-item__label {
      &::before {
        right: 72px;
      }
    }
  }
  &.num {
    /deep/ .el-form-item__label {
      &::before {
        right: 45px;
      }
    }
  }
  &.name.long {
    /deep/ .el-form-item__label {
      &::before {
        right: 100px;
      }
    }
  }
}
.check {
  margin-bottom: 15px;
  &-title {
    width: 110px;
    text-align: right;
    margin-right: 10px;
  }
  label {
    padding: 6px 15px;
    color: #909399;
    background: #f4f4f5;
    border-color: #d3d4d6;
    margin-right: 10px;
    border-radius: $border-radius;
    &.active {
      color: #fff;
      background-color: $theme-color;
      border-color: $theme-color;
    }
  }
}
</style>
