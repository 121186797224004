<template>
  <div class="rem">
    <div class="rem-title">热门实验室</div>
    <div v-for="(item, index) in lists" :key="index" class="rem-item">
      <div class="rem-img pointer" @click="toLabDetail(item)">
        <img :src="item.laboratoryLogo" v-if="item.laboratoryLogo" />
      </div>
      <div class="rem-name">{{ item.laboratoryName }}</div>
    </div>
  </div>
</template>
<script>
import { recommendLabList } from "@/api/lab";
export default {
  data() {
    return {
      lists: [],
      listLoading: false,
      page: {
        pageIndex: 1,
        pageSize: 6,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.listLoading = true;
      const res = await this.$axios
        .post(recommendLabList, this.page)
        .finally(() => {
          this.listLoading = false;
        });
      this.lists = res;
    },
    toLabDetail({ laboratoryId }) {
      this.$jump.push({ name: "labDetail", query: { id: laboratoryId } });
    },
  },
};
</script>
<style lang="scss" scoped>
.rem {
  width: 100%;
  &-item {
    background: white;
    margin-bottom: 15px;
    padding-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-title {
    width: 100%;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    background: linear-gradient(to right, #79b9ff 0%, #4790ff 100%);
    margin-bottom: 10px;
  }
  &-img {
    width: 100%;
    height: 70px;
    text-align: center;
    img {
      width: 60%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-name {
    text-align: center;
  }
}
</style>
