<template>
  <div>
    <HeadBar
      :searchKey.sync="searchKey"
      @wholeSearch="toSearch"
    /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="whole">
      <div class="whole-inner" v-loading="loading">
        <div class="head">
          <span
            v-for="(item, index) in modules"
            :key="index"
            :class="{ active: moduleActiveIndex === index }"
            class="head-moduleName pointer"
            @click="moduleClick(index)"
            >{{ item }}</span
          >
        </div>
        <div class="module" v-for="(mod, index) in searchResult" :key="index">
          <div class="module-head flex flex-row">
            <div class="module-headTitle">{{ mod.title }}</div>
            <div class="flex-1 module-headLine"></div>
          </div>
          <div class="module-con">
            <component :is="componentsMap[mod.module]" :data="mod.data" />
          </div>
          <div class="module-more" v-if="mod.data && mod.data.length">
            <span class="pointer" @click="moreClick(mod.module)"
              >查看更多></span
            >
          </div>
        </div>
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import News from "./components/News";
import Services from "./components/Services/index";
import Goods from "./components/Goods.vue";
import Experts from "./components/Experts.vue";
import Courses from "./components/Courses.vue";
import Labs from "./components/Labs.vue";
import Demands from "./components/Demand.vue";
import { fullTextSearch } from "@/api/common";
export default {
  components: {
    News,
    Services,
    Experts,
    Courses,
  },
  data() {
    return {
      searchKey: "",
      loading: false,
      modules: [],
      componentsMap: {
        News: News,
        Checkmall: Services,
        Productmall: Goods,
        Expert: Experts,
        Course: Courses,
        Laboratory: Labs,
        Demand: Demands,
      },
      moduleActiveIndex: 0,
      originResult: [],
      searchResult: [],
      listPageMap: {
        News: "newsList",
        Checkmall: "mallStrict",
        Productmall: "mallSuper",
        Expert: "expertsList",
        Course: "onlineTrain",
        Laboratory: "labList",
        Demand: "demandHall",
      },
    };
  },
  created() {
    const { key } = this.$route.query;
    if (key) {
      this.searchKey = decodeURIComponent(key);
      this.toSearch();
    }
  },
  methods: {
    async toSearch() {
      this.loading = true;
      const res = await this.$axios
        .get(fullTextSearch, {
          key: this.searchKey,
        })
        .finally(() => {
          this.loading = false;
        });
      res.sort(function (a, b) {
        return b.data.length - a.data.length;
      });
      this.originResult = res;
      this.searchResult = this.originResult;
      const names = res.map((item) => item.title);
      this.modules = ["全部", ...names];
    },
    moduleClick(index) {
      this.moduleActiveIndex = index;
      const moduleName = this.modules[index];
      const res = this.originResult.find((item) => item.title === moduleName);
      if (res) {
        this.searchResult = [res];
      } else {
        this.searchResult = this.originResult;
      }
    },
    moreClick(name) {
      this.$jump.push({ name: this.listPageMap[name] });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.whole {
  background: $home-bg;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    min-height: 500px;
  }
}
.head {
  height: 87px;
  line-height: 87px;
  &-moduleName {
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    margin-right: 60px;
    &:hover,
    &.active {
      color: #4482ff;
    }
  }
}
.footer {
  padding-top: 0;
}
.module {
  &-head {
    margin-bottom: 16px;
  }
  &-con {
    min-height: 100px;
  }
  &-more {
    font-size: 16px;
    color: #4482ff;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 50px;
  }
  &-headTitle {
    padding-left: 15px;
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 4px;
      width: 4px;
      background: #4482ff;
      height: 18px;
      border-radius: 5px;
    }
  }
  &-headLine {
    margin-top: 12px;
    height: 1px;
    background: #dce1ef;
    margin-left: 15px;
  }
}
</style>
