<template>
  <div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="申请入驻服务商城" name="first">
        <StrictForm
          v-if="activeName === 'first'"
          @setMallInfo="setStrictMall"
        />
        <SetKefu
          :status="strictMallInfo.status"
          :businessId="strictMallInfo.businessId"
          businessType="1"
          v-if="strictMallInfo.businessId"
        />
      </el-tab-pane>
      <el-tab-pane label="申请入驻商品商城" name="second">
        <SuperForm
          :labId="labInfo.laboratoryId"
          v-if="activeName === 'second' && labInfo.laboratoryId"
          @setMallInfo="setSuperMall"
        />
        <SetKefu
          :status="superMallInfo.status"
          :businessId="superMallInfo.businessId"
          businessType="2"
          v-if="superMallInfo.businessId"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import StrictForm from "./StrictForm.vue";
import SuperForm from "./SuperForm.vue";
import { getUserLabInfo } from "@/api/userCenter";
import SetKefu from "@/components/KefuDialog/index.vue";
export default {
  components: {
    StrictForm,
    SuperForm,
    SetKefu,
  },
  data() {
    return {
      activeName: "first",
      labInfo: {},
      strictMallInfo: {},
      superMallInfo: {},
    };
  },
  async created() {
    await this.getUserLabDetail();
  },
  methods: {
    // 获取实验室信息
    async getUserLabDetail() {
      const data = await this.$axios.get(getUserLabInfo);
      this.labInfo = data;
    },
    setStrictMall(data) {
      this.strictMallInfo = data;
    },
    setSuperMall(data) {
      this.superMallInfo = data;
    },
  },
};
</script>
<style lang="scss" scoped></style>
