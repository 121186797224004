<template>
  <div class="spec">
    <el-tabs v-model="activeName">
      <el-tab-pane label="联盟专家" name="first">
        <div class="flex flex-row flex-wrap spec-con">
          <div
            v-for="(item, index) in persons"
            :key="index"
            :style="{
              background: `url(${item.img}) no-repeat`,
              'background-size': ' 100% 100%',
            }"
            class="spec-item flex flex-column flex-right"
          >
            <div class="spec-mask">
              <div class="spec-name">{{ item.name }}</div>
              <div class="spec-label">{{ item.label }}</div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      persons: [
        {
          id: 2,
          name: "梁叔全",
          img: "https://store.casic-t.com/image/casic_t/1521346997398_12211429044530026.png",
          label: "材料物理与化学",
        },
        {
          id: 1,
          name: "李志娟",
          img: "https://store.casic-t.com/image/casic_t/1521346646250_12211428297050025.png",
          label: "新材料检测",
        },
        {
          id: 3,
          name: "徐国富",
          img: "https://store.casic-t.com/image/casic_t/1521347058820_12211422498470024.png",
          label: "材料物理与化学",
        },
        {
          id: 4,
          name: "朱爱平",
          img: "https://store.casic-t.com/image/casic_t/1521347100190_12211429532140027.png",
          label: "材料产业政策研究",
        },
        {
          id: 5,
          name: "杨林",
          img: "https://store.casic-t.com/image/casic_t/1521347131127_12211430339500028.png",
          label: "矿物分析检测",
        },
        {
          id: 6,
          name: "郑勇",
          img: "https://store.casic-t.com/image/casic_t/1521347165945_12211431125870030.png",
          label: "金属材料检验检测",
        },
        {
          id: 7,
          name: "黄翀",
          img: "https://store.casic-t.com/image/casic_t/1521347247497_12211431416050031.png",
          label: "新材料研发",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.spec {
  margin-bottom: 20px;
  /deep/ .el-tabs__header .el-tabs__item {
    font-size: 1.28rem !important;
    font-weight: bold;
  }
  &-con {
    padding-top: 10px;
    padding-left: 10px;
  }
  &-item {
    width: 210px; // w/h =0.82
    height: 256px;
    background-size: 100% 100%;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  &-mask {
    background: rgba(46, 52, 67, 0.8);
    border-radius: 0 0 10px 10px;
    text-align: center;
    color: white;
    padding: 10px 0;
  }
  &-name {
    margin-bottom: 5px;
  }
  &-label {
    font-size: 0.85rem;
  }
}
</style>
