<template>
  <div class="lab-wrapper">
    <div class="head flex flex-row flex-between">
      <div class="head-name">实验室图片展示</div>
      <div class="head-btn flex flex-row" v-show="data.length > 3">
        <div class="head-prev" slot="button-prev">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="head-next" slot="button-next">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <swiper
      :options="swiperOption"
      ref="swiper"
      @slideChange="onSlideChange"
      v-if="data.length > 3"
    >
      <swiper-slide v-for="(item, index) in data" :key="index">
        <div class="labImg">
          <img :src="item" />
        </div>
      </swiper-slide>
    </swiper>
    <div v-else class="flex flex-row lab-noSwiper">
      <div v-for="(item, index) in data" :key="index" class="labImg">
        <img :src="item" />
      </div>
    </div>
    <!-- <div v-else class="flex flex-row lab-noSwiper">
      <div v-for="(item, index) in data" :key="index" class="labImg">
        <img :src="item" />
      </div>
    </div> -->
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        autoplay: false,
        loop: true,
        navigation: {
          prevEl: ".swiper-prev",
          nextEl: ".swiper-next",
        },
      },
    };
  },
  methods: {
    preClick() {
      this.$refs.swiper.$swiper.slideTo(2);
    },
    onSlideChange(data) {
      console.log(data);
    },
  },
};
</script>
<style lang="scss" scoped>
// @import "@/style/variable";
.lab {
  &-wrapper {
    padding-bottom: 40px;
  }
  &-noSwiper {
    .labImg {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.head {
  padding: 20px 0;
  &-name {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    position: relative;
    padding-left: 15px;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 6px;
      height: 18px;
      background: #4482ff;
    }
  }
  &-btn {
    position: relative;
    div {
      width: 28px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      background: #eef3ff;
      cursor: pointer;
      i {
        font-size: 16px;
        color: #4482ff;
      }
    }
  }
  &-prev {
    margin-right: 8px;
  }
}

.labImg {
  width: 384px;
  height: 240px;
  background: #f7f7f7;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
