<template>
  <el-dialog
    title="设备信息"
    :visible.sync="dialogVisible"
    width="800px"
    class="dia"
  >
    <div class="flex flex-row device">
      <div class="device-cover">
        <img :src="data.equipmentImage" />
      </div>
      <div class="flex-1">
        <div class="device-name">{{ data.equipmentName }}</div>
        <div class="flex flex-row device-attrs">
          <div class="device-attr">
            <label>设备型号</label>
            <span>{{ data.equipmentModel }}</span>
          </div>

          <div class="device-attr last">
            <label>设备编码</label>
            <span>{{ data.equipmentCode }}</span>
          </div>
        </div>
        <div class="device-attr device-attrs">
          <label>设备品牌</label>
          <span>{{ data.equipmentBrand }}</span>
        </div>
        <div class="para flex flex-row">
          <div class="para-label">主要参数</div>
          <div class="flex-1">{{ data.equipmentParameter }}</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.dia {
  /deep/ .el-dialog__body {
    padding-top: 10px;
  }
}
.device {
  &-cover {
    width: 278px;
    height: 222px;
    background: #f7f7f7;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: middle;
    }
  }
  &-name {
    font-size: 18px;
    margin-bottom: 5px;
  }
  &-attrs {
    margin-bottom: 5px;
  }
  &-attr {
    margin-right: 15px;
    &.last {
      margin-right: 0;
    }
    label {
      margin-right: 8px;
      color: #999999;
    }
  }
}
.para {
  &-label {
    margin-right: 8px;
    color: #999999;
    width: 56px;
  }
}
</style>
