<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="news">
      <div class="news-inner flex flex-row">
        <div class="type" v-loading="typeLoading">
          <div
            class="type-item pointer"
            v-for="(item, index) in newsTypes"
            :key="index"
            :class="{ active: typeActiveIndex === index }"
            @click="typeClick(index)"
          >
            {{ item.dictLabel }}
          </div>
        </div>
        <div class="flex-1" v-loading="newsLoading">
          <NewsItem
            v-for="(item, index) in newsList"
            :key="index"
            :data="item"
          />
        </div>
      </div>
      <!-- 分页-->
      <Pagination
        :total="totalItem"
        :page="page"
        @pageIndexChange="handlePageChange"
      />
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import { ARTICLE_TYPE } from "@/constant/dict";
import { queryNewsList } from "@/api/article.js";
import Pagination from "@/components/Pagination/index";
import NewsItem from "@/components/NewsItem/index";
export default {
  components: {
    Pagination,
    NewsItem,
  },
  data() {
    return {
      newsTypes: [],
      newsList: [],
      typeActiveIndex: 0,
      newsLoading: false,
      typeLoading: false,
      page: {
        pageIndex: 1,
        pageSize: 6,
      },
      totalItem: 0,
    };
  },
  created() {
    this.getDict();
  },
  methods: {
    async getDict() {
      this.typeLoading = true;
      const data = await this.$store
        .dispatch("getDictData", ARTICLE_TYPE)
        .finally(() => {
          this.typeLoading = false;
        });
      // 文章类型（1-新闻资讯;2-展会信息;3-政策法规;4-规格介绍;5-帮助文档;6-APP新闻资讯;7-检测助手;
      // 8-业务助手;9-检测标准;10-检测方法;11-资质要求;12-平台快讯 13-新闻推稿）
      this.newsTypes = data;
      const { type } = this.$route.query;
      if (type) {
        const index = data.findIndex((item) => item.dictValue === type);
        if (index > -1) {
          this.typeActiveIndex = index;
        }
      }
      this.getNewsList();
    },
    typeClick(index) {
      this.typeActiveIndex = index;
      this.page.pageIndex = 1;
      this.getNewsList();
    },
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getNewsList();
    },
    async getNewsList() {
      this.newsLoading = true;
      this.newsList.length = 0;
      const { list, totalItem } = await this.$axios
        .post(queryNewsList, {
          articleType: this.newsTypes[this.typeActiveIndex].dictValue,
          ...this.page,
        })
        .finally(() => {
          this.newsLoading = false;
        });
      this.newsList = list;
      this.totalItem = totalItem;
    },
    toDetail(data) {
      this.$router.push({ name: "newsDetail", query: { id: data.articleId } });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.news {
  background: #f5f5f5;
  min-height: 550px;
  padding-top: 20px;
  padding-bottom: 30px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    margin-bottom: 25px;
  }
}
.type {
  width: 177px;
  min-height: 200px;
  background: white;
  text-align: center;
  margin-right: 25px;
  &-item {
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    color: #666666;
    &.active,
    &:hover {
      background: #4482ff;
      color: white;
    }
  }
}
.footer {
  padding-top: 0 !important;
}
.item {
  padding: 9px;
  background: white;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &-img {
    width: 220px;
    height: 134px;
    margin-right: 33px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-title {
    font-size: 16px;
    font-weight: bold;
    color: #2c2c2c;
    padding: 14px 20px 10px 0;
  }
  &-brief {
    font-size: 14px;
    color: #777777;
    margin-bottom: 16px;
  }
  &-time {
    font-size: 14px;
    color: #777777;
  }
}
.noImg {
  padding-left: 15px;
  padding-bottom: 10px;
}
</style>
